import React, {useState} from 'react';
import MDSpinner from 'react-md-spinner';
import {Collection} from '../../models/Collection';
import {spinnerColor} from '../../utils/StyleUtils';
import {LibraryFilterType} from '../../models/Common';
import {ModalType} from '../../models/Modals';
import {IsFullViewCollection} from '../../reducers/collections';
import {closestCenter, DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, horizontalListSortingStrategy, rectSortingStrategy, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import SortableCollectionView from './SortableCollectionView';
import SortableCollectionSimpleView from './SortableCollectionSimpleView';

type CollectionsList = {
    collections: Collection[],
    isLoadingCollections: boolean,
    templatesByCollectionsIds: any,
    updateCollectionsOrderIndexes: any,
    selectedFilterType: LibraryFilterType,
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
    isFullViewCollections: IsFullViewCollection,
    setIsFullViewCollection: (collectionId: string, isFullView: boolean) => void
}

export const CollectionsList = ({collections, isLoadingCollections, selectedFilterType, showModal, isFullViewCollections,
    setIsFullViewCollection, templatesByCollectionsIds, updateCollectionsOrderIndexes}: CollectionsList) => {

    const [isFullViewSimpleCollections, setIsFullViewSimpleCollections] = useState(false);
    const sensors = useSensors(
      useSensor(MouseSensor, {
          activationConstraint: {
              distance: 8,
          },
      }),
      useSensor(TouchSensor, {
          activationConstraint: {
              distance: 8,
          },
      })
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldCategory = findCollection(active.id, collections);
            const newCategory = findCollection(over!.id, collections);
            const newLocalCategories = arrayMove(collections, oldCategory.index, newCategory.index);

            updateCollectionsOrderIndexes(
              newLocalCategories.map((category, index) => {
                  return {
                      entityId: category.id,
                      orderIndex: index + 1,
                  };
              }),
            );
        }
    }

    const findCollection = (id: string | number, collections: Collection[]) => {
        const collection = collections.filter((c) => c.id === id)[0];
        return {
            collection,
            index: collections.indexOf(collection),
        };
    };
    const renderSimpleCollections = () => {
        return <div className={'reels-items-container-none-height'}>
            <div className={'category-header'}>
                <div className={'category-header-name'}>
                    {`Collections (${collections.length})`}
                </div>
                <div className={'reels-hide-arrow' + (isFullViewSimpleCollections ? '-active' : '')}
                     style={{marginLeft: '24px'}}
                     onClick={() => {setIsFullViewSimpleCollections(!isFullViewSimpleCollections)}}
                />
            </div>
            <div className={isFullViewSimpleCollections ? 'template-list-in-grid' : 'template-list-in-scroll'} style={{gap: '20px'}}>
                <SortableContext items={collections} strategy={isFullViewSimpleCollections ? rectSortingStrategy : horizontalListSortingStrategy}>
                {
                    collections.map((collection, index) => {
                        const templates = templatesByCollectionsIds[collection.id] ? templatesByCollectionsIds[collection.id] : [];
                        return <SortableCollectionSimpleView
                          collection={collection}
                          key={collection.id}
                          templatesCount={templates.length}
                        />
                    })
                }
                </SortableContext>
            </div>
        </div>
    }

    if (isLoadingCollections) {
        return (
          <div className='spinner-container' style={{marginTop: '212px'}}>
              <MDSpinner size={100} singleColor={spinnerColor}/>
          </div>
        );
    }

    const renderCollections = () => {
        return <SortableContext items={collections} strategy={verticalListSortingStrategy}>
            {
                collections.map((collection, index) => {
                    const templates = templatesByCollectionsIds[collection.id] ? templatesByCollectionsIds[collection.id] : [];
                    return <SortableCollectionView
                      style={{marginTop: (index > 0 ? '15px' : undefined)}}
                      key={collection.id}
                      collection={collection}
                      showModal={showModal}
                      templates={templates}
                      selectedFilterType={selectedFilterType}
                      setIsFullViewCollection={setIsFullViewCollection}
                      isFullView={isFullViewCollections[collection.id]}
                    />
                })
            }
        </SortableContext>
    }

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
          {
              selectedFilterType === LibraryFilterType.ALL_REELS ? renderSimpleCollections() : renderCollections()
          }
      </DndContext>
    );
};
