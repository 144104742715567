export const removeDuplicates = (arr: any[], key: string) => {
  const flag: any = {};
  const unique: any[] = [];

  arr.forEach((elem: any) => {
    if (!flag[elem[key]]) {
      flag[elem[key]] = true;
      unique.push(elem);
    }
  });
  return unique;
};

export function compareString(propName: string) {
  return (a: any, b: any) => {
    if ( a[propName] < b[propName] ) {
      return -1;
    }
    if ( a[propName] > b[propName] ) {
      return 1;
    }
    return 0;
  };
}

export function range(size: number, startAt = 0): number[] {
  return Array.from(Array(size).keys()).map((i) => i + startAt);
}

export const getEvery = (arr: any[], each: number, propName: string): any[] => {
  let eachSum = 0;
  const resultArr: any[] = [];
  arr.forEach((element) => {
    eachSum++;
    if (eachSum === each) {
      eachSum = 0;
      if (propName) {
        resultArr.push(element[propName]);
      } else {
        resultArr.push(element);
      }
    }
  });

  return resultArr;
};

export const arrayToObject = (array: any[], value: any): any => {
  return array.reduce(function(acc, cur, i) {
    acc[cur] = value;
    return acc;
  }, {});
};
