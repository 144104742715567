import uuid from 'uuid';
import {CollectionsActionType} from "../actions/collections";
import {Collection} from "../models/Collection";
import {TemplatesActionType} from "../actions/templates";
import {Template} from "../models/Template";
import {TemplateToCollection} from "../models/TemplateToCollection";
import {Category} from '../models/Category';

export type IsFullViewCollection = {[id: string]: boolean}

const getInitialState = () => {
    return {
        stateId: uuid.v4(),
        collections: [],
        isLoadingCollections: false,
        selectedCollection: {id: -1},
        timeStampSaveCollections: 0,
        timeStampAddCollection: 0,
        isFullViewCollections: {}
    };
};
export default function collections(state = getInitialState(), action: any) {
    switch (action.type) {
        case CollectionsActionType.SAVE_COLLECTIONS:
            const timeStampSaveCollections = action.requestTimeEpoch;
            const stateIdRequestedSaveCollections = action.stateId;
            if (
                timeStampSaveCollections > state.timeStampSaveCollections &&
                stateIdRequestedSaveCollections === state.stateId
            ) {
                return Object.assign({}, state, {
                    collections: action.collections,
                    isLoadingCollections: false,
                    timeStampSaveCollections: timeStampSaveCollections,
                });
            }
            return state;


        case CollectionsActionType.SELECT_COLLECTION:
            return Object.assign({}, state, {
                selectedCollection: action.collection,
            });

        case CollectionsActionType.SET_IS_FULL_VIEW_COLLECTION:
            return Object.assign({}, state, {
                isFullViewCollections: {...state.isFullViewCollections, ...{[action.collectionId]: action.isFullView}},
            });

        case CollectionsActionType.IS_LOADING_COLLECTIONS:
            return Object.assign({}, state, {
                isLoadingCollections: action.isLoading,
            });

        case CollectionsActionType.UPDATE_COLLECTION:
            return Object.assign({}, state, {
                collections: state.collections.map((collection: Collection) => {
                    if (collection.id === action.collection.id) {
                        return action.collection;
                    }
                    return collection;
                })
                  .sort((a: Collection, b: Collection) => (a.order - b.order)),
            });

        case TemplatesActionType.UPDATE_TEMPLATE_IN_COLLECTION:
            return Object.assign({}, state, {
                collections: state.collections.map((collection: Collection) => {
                    const template: Template = action.template;
                    const isContainCollection = template.templateToCollections.find((templateToCollection) => {
                        return templateToCollection.collectionId === collection.id;
                    });
                    if (isContainCollection) {
                        const isCollectionContainTemplate = collection.templatesToCollection.find((templateToCollection) => {
                            return templateToCollection.templateId === action.template.id;
                        });
                        if (!isCollectionContainTemplate) {
                            const newCollection: Collection = {...collection};
                            newCollection.templatesToCollection.push({
                                collectionId: collection.id,
                                templateId: action.template.id,
                                order: isContainCollection.order,
                                templateToCollectionId: isContainCollection.templateToCollectionId,
                            });
                            return newCollection;
                        }
                    } else {
                        const isCollectionContainTemplate = collection.templatesToCollection.find((templateToCollection) => {
                            return templateToCollection.templateId === action.template.id;
                        });
                        if (isCollectionContainTemplate) {
                            const newCollection = {...collection};
                            newCollection.templatesToCollection = newCollection.templatesToCollection.filter((templateToCollection) => {
                                return templateToCollection.templateId !== action.template.id;
                            });
                            return newCollection;
                        }
                    }

                    return collection;
                }),
            });

        case CollectionsActionType.DELETE_COLLECTION:
            return Object.assign({}, state, {
                collections: state.collections.filter((collection: Collection) => {
                    return collection.id !== action.collectionId;
                }),
            });

        case TemplatesActionType.ADD_TEMPLATE || TemplatesActionType.UPDATE_TEMPLATE:
            const copyCollections = [...state.collections];
            const templateForAdd: Template = action.template;
            copyCollections.forEach((collection: Collection) => {
                const isAddToCollection = templateForAdd.templateToCollections.find((templateToCollection) => templateToCollection.collectionId === collection.id);
                let isExistInCollection = false;
                if(!isAddToCollection) {
                    collection.templatesToCollection = collection.templatesToCollection
                        .filter(templateToCollection => {
                            if (!isExistInCollection && templateToCollection.templateId === templateForAdd.id) {
                                isExistInCollection = true;
                            }
                            return templateToCollection.templateId !== templateForAdd.id;
                        })
                }
                if(isAddToCollection && !isExistInCollection) {
                    const newTemplateToCollection: TemplateToCollection | undefined = templateForAdd.templateToCollections
                        .find(templateToCollection => templateToCollection.collectionId === collection.id);
                    if(!newTemplateToCollection) {
                        throw new Error('TemplateToCollection not exists');
                    }
                    collection.templatesToCollection.push(newTemplateToCollection);
                    collection.templatesToCollection = collection.templatesToCollection.sort((a, b) => a.order - b.order);
                }
            })
            return Object.assign({}, state, {
                collections: copyCollections,
            });

        case CollectionsActionType.ADD_COLLECTION:
            return Object.assign({}, state, {
                collections: [...state.collections, ...[action.collection]].sort((a: Collection, b: Collection) => a.order - b.order),
            });

        case TemplatesActionType.REMOVE_TEMPLATE_FROM_COLLECTIONS:
            return Object.assign({}, state, {
                collections: state.collections.map((collection: Collection) => {
                    const newCollection = {...collection};
                    newCollection.templatesToCollection = newCollection.templatesToCollection.filter((templateToCollection) => {
                        return templateToCollection.templateId !== action.templateId;
                    });

                    return newCollection;
                }),
            });

        case CollectionsActionType.UPDATE_ORDERS_INDEXES_COLLECTIONS:
            const collectionsWithNewOrderIndexes = state.collections.map((collection: Collection) => {
                const orderIndex = action.orderIndexes.find((orderIndex: any) => (orderIndex.entityId === collection.id));

                if (orderIndex) {
                    const newCollection = {...collection};
                    newCollection.order = orderIndex.orderIndex;
                    return newCollection;
                }

                return collection;
            }).sort((a, b) => (a.order - b.order));

            return Object.assign({}, state, {
                collections: collectionsWithNewOrderIndexes,
            });

        case TemplatesActionType.UPDATE_RELATION_TEMPLATE_TO_COLLECTION:
            const collectionsUpdatedRelation = state.collections
                .map((collection: Collection) => {
                    const newCollection = {...collection};
                    if (collection.id === action.newRelation.toEntityId) {
                        newCollection.templatesToCollection.push({
                            templateToCollectionId: action.newRelation.templateToCollectionId,
                            templateId: action.newRelation.fromEntityId,
                            collectionId: collection.id,
                            order: action.newRelation.orderIndex,
                        });
                    }

                    if (collection.id === action.fromCollectionId) {
                        newCollection.templatesToCollection = newCollection.templatesToCollection.filter((templateToCollection) => {
                            return templateToCollection.templateId !== action.newRelation.fromEntityId;
                        });
                    }

                    return newCollection;
                });

            return Object.assign({}, state, {
                collections: collectionsUpdatedRelation,
            });

        default: return state;
    }
}
