import {createRequest} from '../utils/HttpRequestUtills';
import {GeneralActionType} from "./general";
import {Collection} from "../models/Collection";
import {reservedCategoriesIds} from '../models/Common';

export enum CollectionsActionType {
    SAVE_COLLECTIONS = 'SAVE_COLLECTIONS',
    IS_LOADING_COLLECTIONS = 'IS_LOADING_COLLECTIONS',
    SELECT_COLLECTION = 'SELECT_COLLECTION',
    ADD_COLLECTION = 'ADD_COLLECTION',
    DELETE_COLLECTION = 'DELETE_COLLECTION',
    UPDATE_COLLECTION ='UPDATE_COLLECTION',
    UPDATE_ORDERS_INDEXES_COLLECTIONS = 'UPDATE_ORDERS_INDEXES_COLLECTIONS',
    SET_EDIT_COLLECTION = 'SET_EDIT_COLLECTION',
    SET_DELETE_COLLECTION = 'SET_DELETE_COLLECTION',
    SET_IS_FULL_VIEW_COLLECTION = 'SET_IS_FULL_VIEW_COLLECTION',
}

export const deleteCollection = (collectionId: string) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({type: CollectionsActionType.DELETE_COLLECTION, collectionId});

    const onload = () => {};

    const xhr = createRequest(dispatch, 'DELETE', '/reels/collection/' + collectionId, onload);

    xhr.send();
};

export const addCollection = (collection: Collection) => (dispatch: any, getState: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: CollectionsActionType.ADD_COLLECTION,
        collection
    });
    const onload = () => {
        const orderIndexes  = getState().collections.collections.map((collectionFromState: Collection) => {
            let orderIndex = collectionFromState.order + 1;
            if(collectionFromState.id === collection.id) {
                orderIndex = 1;
            }
            return {
                entityId: collectionFromState.id,
                orderIndex,
            }
        })
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({
            type: CollectionsActionType.UPDATE_ORDERS_INDEXES_COLLECTIONS,
            orderIndexes,
        });

        const onload = () => {};
        const xhr = createRequest(dispatch, 'PATCH', '/reels/collections/order-indexes', onload);

        xhr.send(JSON.stringify({orderIndexes}));
    };
    const xhr = createRequest(dispatch, 'POST', '/reels/collection', onload);

    xhr.send(JSON.stringify(collection));
};

export const updateCollection = (collection: Collection) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({type: CollectionsActionType.UPDATE_COLLECTION, collection});

    const onload = () => {};

    const xhr = createRequest(dispatch, 'PATCH', '/reels/collection', onload);

    xhr.send(JSON.stringify(collection));
};

export const updateCollectionsOrderIndexes = (orderIndexes: any) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: CollectionsActionType.UPDATE_ORDERS_INDEXES_COLLECTIONS,
        orderIndexes,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/collections/order-indexes', onload);

    xhr.send(JSON.stringify({orderIndexes}));
};

export const setEditCollection = (collection?: Collection | null) => (dispatch: any) => {
    dispatch({
        type: CollectionsActionType.SET_EDIT_COLLECTION,
        collection,
    });
};

export const setDeleteCollection = (collection?: Collection | null) => (dispatch: any) => {
    dispatch({
        type: CollectionsActionType.SET_DELETE_COLLECTION,
        collection,
    });
};

export const setIsFullViewCollection = (collectionId: string, isFullView: boolean) => (dispatch: any) => {
    dispatch({
        type: CollectionsActionType.SET_IS_FULL_VIEW_COLLECTION,
        isFullView,
        collectionId
    });
};
