import {createRequest} from '../utils/HttpRequestUtills';
import {batch} from 'react-redux';
import {GeneralActionType} from "./general";
import {Tag} from "../models/Tag";

export enum TagsActionTypes {
    UPDATE_TAG = 'UPDATE_TAG',
    REMOVE_TAG_FROM_TEMPLATES = 'REMOVE_TAG_FROM_TEMPLATES',
    DELETE_TAG = 'DELETE_TAG',
    SAVE_TAGS = 'SAVE_TAGS',
    ADD_TAG = 'ADD_TAG',
    IS_LOADING_TAGS = 'IS_LOADING_TAGS',
    SET_DELETE_TAG = 'SET_DELETE_TAG',
    SET_EDIT_TAG = 'SET_EDIT_TAG',
    MOVE_TAGS = 'MOVE_TAGS',
    ORDER_TAGS = 'ORDER_TAGS',
    UPDATE_TEMPLATE_TAGS = 'UPDATE_TEMPLATE_TAGS'
}

export const TAGS_ORDER_TYPES = {
    IS_VISIBLE: 'IS_VISIBLE',
    IS_IMAGE: 'IS_IMAGE',
    NAME: 'NAME',
    COUNT_TEMPLATES: 'COUNT_TEMPLATES',
};

export const addTag = (tag: Tag) => (dispatch: any, getState: any) => {
    dispatch({
        type: GeneralActionType.IS_UPDATING_CONTENT,
        isUpdating: true,
    });

    dispatch({
        type: TagsActionTypes.ADD_TAG,
        tag,
        templates: getState().templates.templates,
    });

    const onload = () => {
        dispatch({
            type: GeneralActionType.IS_UPDATING_CONTENT,
            isUpdating: false,
        });
    };
    const xhr = createRequest(dispatch, 'POST', '/reels/tag', onload);

    xhr.send(JSON.stringify(tag));
};

export const updateTag = (tag: Tag) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.IS_UPDATING_CONTENT,
        isUpdating: true,
    });
    dispatch({type: TagsActionTypes.UPDATE_TAG, tag: {...tag}});

    const onload = () => {
        dispatch({
            type: GeneralActionType.IS_UPDATING_CONTENT,
            isUpdating: false,
        });
    };
    const xhr = createRequest(dispatch, 'PATCH', '/reels/tag', onload);

    xhr.send(JSON.stringify(tag));
};

export const deleteTag = (tagId: string) => (dispatch: any) => {
    batch(() => {
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({type: TagsActionTypes.REMOVE_TAG_FROM_TEMPLATES, tagId});
        dispatch({type: TagsActionTypes.DELETE_TAG, tagId});
    });
    const onload = () => {};

    const xhr = createRequest(dispatch, 'DELETE', '/reels/tag/' + tagId, onload);

    xhr.send();
};

export const setDeleteTag = (tag?: Tag | null) => (dispatch: any) => {
    dispatch({
        type: TagsActionTypes.SET_DELETE_TAG,
        tag,
    });
};

export const setEditTag = (tag?: Tag | null) => (dispatch: any) => {
    dispatch({
        type: TagsActionTypes.SET_EDIT_TAG,
        tag,
    });
};

export const moveTag = (
    orderIndexes: number[],
) => (dispatch: any) => {
    batch(() => {
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({
            type: TagsActionTypes.MOVE_TAGS,
            orderIndexes,
        });
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/tags/order-indexes', onload);

    xhr.send(JSON.stringify({orderIndexes}));
};

export const orderTags = (orderParams: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: TagsActionTypes.ORDER_TAGS,
        orderParams,
        templates: getState().templates.templates,
    });
};

