import React, {useState} from 'react';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Collection} from "../../models/Collection";
import {DetailsModeType, LibraryFilterType} from "../../models/Common";
import {addCollection, updateCollection} from "../../actions/collections";
import {Checkbox} from "../general/Checkbox";
import uuid from "uuid";
import {EditVideo} from '../general/EditVideo';
import momentZ from 'moment-timezone';
import {uploadFileToS3Post} from '../../utils/FilesUtils';
import {configs} from '../../resources/configs';
import {compressVideo} from '../../actions/general';
import {Version} from '../../models/Version';
import {changeLocalization, getLocalizedValue, locales} from '../../utils/LocalizationUtils';

interface StateProps {
  collections: Collection[],
  isCompressingVideo: boolean,
  nextVersion: Version,
}

interface DispatchProps {
  addCollection: (collection: Collection) => void,
  updateCollection: (collection: Collection) => void,
  compressVideo: (videoUrl: string) => void,
}

interface OwnProps {
  mode: DetailsModeType,
  collection: Collection,
  cancel: () => void,
  deleteCollection?: (collection: Collection) => void
  isLoadingCollections?: boolean,
  templatesByCollectionsIds?: any,
  selectedFilterType?: LibraryFilterType,
  isBlockDelete?: boolean
}

type Props = StateProps & DispatchProps & OwnProps

const CollectionDetails = ({mode, collection, cancel, deleteCollection, nextVersion,
  addCollection, updateCollection, isBlockDelete = false, collections, compressVideo, isCompressingVideo
}: Props) => {
  const [id, setId] = useState(mode === DetailsModeType.ADD ? uuid.v4() : collection.id);
  const [name, setName] = useState(collection.name);
  const [previewVideoUrl, setPreviewVideoUrl] = useState<string>(collection.previewVideoUrl);
  const [localization, setLocalization] = useState(collection.localization ? collection.localization : {});
  const [order, setOrder] = useState(mode === DetailsModeType.ADD ? 1 : collection.order);
  const [isActive, setIsActive] = useState(collection.isActive);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  const handleFileDrop = (file: any, fileName: string, setIsLoading: (isLoading: boolean) => void, setUrl: (path: string) => void) => {
    if (file) {
      setIsLoading(true);
      uploadFileToS3Post(file, fileName, 'RES_REELS', `collections/${id}`, (path: string) => {
        setUrl(configs.resUrl + path);
        setIsLoading(false);
      });
    }
  };
  const handleSaveGroup = () => {
    const collectionForSave = {...collection};
    collectionForSave.id = id;
    collectionForSave.name = name;
    collectionForSave.localization = localization;
    collectionForSave.isActive = isActive;
    collectionForSave.previewVideoUrl = previewVideoUrl;
    collectionForSave.order = order;

    mode === DetailsModeType.EDIT ? updateCollection(collectionForSave) : addCollection(collectionForSave);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'} style={{flexDirection: 'row'}}>
      <div className={'reels-head-text'}>
        {mode === DetailsModeType.EDIT ? 'Edit Collection' : 'Add Collection'}
      </div>

      <Checkbox
          key={'visibility-checkbox'}
          switch={setIsActive}
          isActive={isActive}
          text={'VISIBILITY'}
          isCircle={true}
      />
    </div>;
  };

  const renderName = (lang: string) => {
    return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}} key={lang + 'input'}>
      <div className={'reels-second-text'}>
        {lang}
      </div>
      <Input style={{marginTop: '7px', width: '200px'}}
             handleChange={(value: string) => {
               changeLocalization(value, 'name', localization, setName, setLocalization, lang);
             }}
             value={getLocalizedValue(localization, 'name', lang)}
      />
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'reels-second-text'}>
          Video Preview
        </div>
        <EditVideo
            videoUrl={previewVideoUrl}
            style={{marginTop: '3px', width: '231px', height: '150px'}}
            widthVideo={'231px'}
            heightVideo={'150px'}
            onDrop={(file) => {handleFileDrop(file, `original_${nextVersion.id}_${momentZ().tz('UTC').format('x')}_preview_video.mp4`, setIsLoadingVideo, (videoUrl) => {
              compressVideo(videoUrl);
              setPreviewVideoUrl(videoUrl.replace('original_', ''));
            })}}
            isLoading={isLoadingVideo || isCompressingVideo}
            clear={() => {
              setPreviewVideoUrl('');
            }}
        />
      </div>
      <div style={{display: 'flex', marginTop: '20px', flexDirection: 'column', width: '100%'}}>
        <div className={'reels-second-text'}>
                    Name
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={(value: string) => {
                 changeLocalization(value, 'name', localization, setName, setLocalization);
          }}
          value={getLocalizedValue(localization, 'name')}
        />
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
          {locales.map((locale) => {
            if (locale === 'en') {
              return null;
            }
            return renderName(locale);
          })}
        </div>
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>
      <button className={mode === DetailsModeType.EDIT || isBlockDelete ? 'reels-delete-btn' : 'reels-second-btn'}
        style={{marginRight: (mode === DetailsModeType.EDIT || isBlockDelete ? 'auto' : undefined)}}
        onClick={() => {
          if (mode === DetailsModeType.EDIT || isBlockDelete) {
            deleteCollection ? deleteCollection(collection) : () => {};
          }
          cancel();
        }}
      >
        {mode === DetailsModeType.EDIT || isBlockDelete ? 'Delete' : 'Cancel'}
      </button>
      <button className={'save-btn'} style={{marginLeft: '24px'}}
        onClick={handleSaveGroup}
        disabled={name === '' || previewVideoUrl === ''}
      >
        {mode === DetailsModeType.EDIT ? 'Save' : 'Add'}
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state: any) => ({
  collections: state.collections.collections,
  isCompressingVideo: state.appState.isCompressingVideo,
  nextVersion: state.appState.nextVersion,
});

const mapDispatchToProps = (dispatch: any) => ({
  addCollection: bindActionCreators(addCollection, dispatch),
  updateCollection: bindActionCreators(updateCollection, dispatch),
  compressVideo: bindActionCreators(compressVideo, dispatch),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(CollectionDetails);
