/* eslint-env browser */
import {compose} from 'redux';
import partial from 'lodash/fp/partial';
import partialRight from 'lodash/fp/partialRight';
import {connecting, open, closed, message, error} from './actions';
import {createWebsocket} from './websocket';
import {getTimestampUTCms} from '../../utils/TimeUtils';
import {WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, WEBSOCKET_SEND} from '../../models/Websockets';

const createMiddleware = () => {
  // Hold a reference to the WebSocket instance in use.
  const websockets = {};

  /**
   * A function to create the WebSocket object and attach the standard callbacks
   */
  const initialize = ({dispatch}, config) => {
    config.urls.forEach((url) => {
      if (websockets[url.serviceType] && websockets[url.serviceType].readyState === 1 ) {
        console.log(url.serviceType + ' IS EXISTS');
        return;
      }
      // Instantiate the websocket.
      websockets[url.serviceType] = createWebsocket({url: url.url});

      // Function will dispatch actions returned from action creators.
      const dispatchAction = partial(compose, [dispatch]);
      // Setup handlers to be called like this:
      // dispatch(open(event));
      websockets[url.serviceType].onopen = dispatchAction((event) => {
        return open(event, url.serviceType);
      });
      websockets[url.serviceType].onclose = dispatchAction((event) => {
        return closed(event, url.serviceType);
      });
      websockets[url.serviceType].onmessage = dispatchAction(message);
      websockets[url.serviceType].onerror = dispatchAction(error);

      // An optimistic callback assignment for WebSocket objects that support this
      const onConnecting = dispatchAction((event, websocket) => {
        return connecting(event, websocket, url.serviceType);
      });

      // Add the websocket as the 2nd argument (after the event).
      websockets[url.serviceType].onconnecting = partialRight(onConnecting, [websockets[url.serviceType]]);
    });
  };

  /**
   * Close the WebSocket connection and cleanup
   */
  const close = () => {
    if (Object.keys(websockets).length !== 0) {
      Object.keys(websockets).forEach((serviceType) => {
        const websocket = websockets[serviceType];
        console.warn(`Closing WebSocket connection to ${websocket.url} ...`);
        websocket.close();
        websockets[serviceType] = undefined;
      });
    }
  };

  const send = (action, websocket) => {
    if (!websocket) {
      return;
    }
    console.log(action.payload.action.serviceType + ' --SEND-- ' + websocket.readyState);
    if (websocket.readyState === 0) {
      setTimeout(() => {
        send(action, websocket);
      }, 1000);
    } else {
      if (!action.payload['requestTimeEpoch']) {
        action.payload['requestTimeEpoch'] = getTimestampUTCms();
      }
      websocket.send(JSON.stringify({...action.payload, ...{action: action.payload.action.name}}));
    };
  };

  /**
   * The primary Redux middleware function.
   * Each of the actions handled are user-dispatched.
   */
  return (store) => (next) => (action) => {
    if (action !== undefined && action.type !== undefined) {
      switch (action.type) {
        // User request to connect
        case WEBSOCKET_CONNECT:
          initialize(store, action.payload);
          break;

          // User request to disconnect
        case WEBSOCKET_DISCONNECT:
          close();
          break;

          // User request to send a message
        case WEBSOCKET_SEND:
          const websocket = websockets[action.payload.action.serviceType];
          send(action, websocket);
          break;

        default:
          break;
      }
      return next(action);
    }
  };
};

export default createMiddleware();
