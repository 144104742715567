import React, { Component } from 'react'
import debounce from 'throttle-debounce'

interface Props {
    isActive: boolean;
    switch: (isActive: boolean) => void;
    style?: any;
    isDisabled?: boolean;
    text: string;
    isCircle?: boolean
}

interface State {
    isActive: boolean;
}

export class Checkbox extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {isActive: this.props.isActive}
    }

    switch = debounce.debounce(200,false, () => this.props.switch(this.state.isActive))

  componentWillUpdate (nextProps: Props, nextState: State, nextContext: any) {
    if(this.props.isActive !== nextProps.isActive) {
      this.setState({isActive: nextProps.isActive})
    }
  }

    handleChangeSwitch = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.switch());
    }

    render () {
        return (
            <div className={(this.props.isCircle ? 'checkbox-circle' : 'checkbox')} style={this.props.style}>
                <input type="checkbox"
                       disabled={this.props.isDisabled}
                       defaultChecked={this.state.isActive}
                       checked={this.state.isActive}
                       onChange={this.handleChangeSwitch}
                />
                <label style={{color: this.state.isActive ? "#212529" : "#7F8489"}}>
                    {this.props.text}
                </label>
            </div>
        )
    }
}
