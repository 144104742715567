import {batch} from 'react-redux';
import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {GeneralActionType} from "./general";
import {Template} from "../models/Template";
import {OrderIndexes} from "../models/OrderIndexes";
import {TagsActionTypes} from "./tags";
import {configs} from "../resources/configs";
import {WEBSOCKET_SEND, WebsocketAction} from "../models/Websockets";
import momentZ from "moment-timezone";
import {TemplatesListType} from '../models/Common';

export enum TemplatesActionType {
    SAVE_TEMPLATES = 'SAVE_TEMPLATES',
    IS_LOADING_TEMPLATES = 'IS_LOADING_TEMPLATES',
    DELETE_TEMPLATE = 'DELETE_TEMPLATE',
    UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
    SELECT_TEMPLATE = 'SELECT_TEMPLATE',
    REMOVE_TEMPLATE_FROM_CATEGORY = 'REMOVE_TEMPLATE_FROM_CATEGORY',
    MOVE_TEMPLATES_IN_CATEGORY = 'MOVE_TEMPLATES_IN_CATEGORY',
    REPLACE_TEMPLATES_IN_CATEGORIES = 'REPLACE_TEMPLATES_IN_CATEGORIES',
    ADD_TEMPLATE_TO_CATEGORY = 'ADD_TEMPLATE_TO_CATEGORY',
    ADD_TEMPLATE_TO_COLLECTION = 'ADD_TEMPLATE_TO_COLLECTION',
    MOVE_TEMPLATES_IN_COLLECTION = 'MOVE_TEMPLATES_IN_COLLECTION',
    REPLACE_TEMPLATES_IN_COLLECTIONS = 'REPLACE_TEMPLATES_IN_COLLECTIONS',
    ADD_TEMPLATE = 'ADD_TEMPLATE',
    REMOVE_CATEGORY_FROM_TEMPLATES = 'REMOVE_CATEGORY_FROM_TEMPLATES',
    UPDATE_TEMPLATE_IN_CATEGORY = 'UPDATE_TEMPLATE_IN_CATEGORY',
    UPDATE_RELATION_TEMPLATE_TO_CATEGORY = 'UPDATE_RELATION_TEMPLATE_TO_CATEGORY',
    REMOVE_TEMPLATE_FROM_CATEGORIES = 'REMOVE_TEMPLATE_FROM_CATEGORIES',
    UPDATE_RELATION_TEMPLATE_TO_COLLECTION = 'UPDATE_RELATION_TEMPLATE_TO_COLLECTION',
    UPDATE_TEMPLATE_IN_COLLECTION = 'UPDATE_TEMPLATE_IN_COLLECTION',
    REMOVE_TEMPLATE_FROM_COLLECTIONS = 'REMOVE_TEMPLATE_FROM_COLLECTIONS',
    REMOVE_TEMPLATE_FROM_COLLECTION = 'REMOVE_TEMPLATE_FROM_COLLECTION',
    REMOVE_COLLECTION_FROM_TEMPLATES = 'REMOVE_COLLECTION_FROM_TEMPLATES',
    SET_EDIT_TEMPLATE = 'SET_EDIT_TEMPLATE',
    SET_DELETE_TEMPLATE = 'SET_DELETE_TEMPLATE',
    SAVE_TEMPLATES_VIEW_COUNT = 'SAVE_TEMPLATES_VIEW_COUNT',
    SAVE_TEMPLATES_EXPORT_COUNT = 'SAVE_TEMPLATES_EXPORT_COUNT',
    SET_IS_COMPRESSING_IMAGE = 'SET_IS_COMPRESSING_IMAGE'
}

export const addTemplate = (template: Template, isUpdateRelations: boolean) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.IS_UPDATING_CONTENT,
        isUpdating: true,
    });

    dispatch({
        type: TemplatesActionType.ADD_TEMPLATE,
        template,
    });

    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });

    const onload = () => {
        dispatch({
            type: GeneralActionType.IS_UPDATING_CONTENT,
            isUpdating: false,
        });
    };
    const xhr = createRequest(dispatch, 'POST', '/reels/template', onload);

    xhr.send(JSON.stringify({...template, ...{isUpdateRelations}}));
};

export const moveTemplateInCategory = (
    orderIndexes: OrderIndexes,
    template: Template,
    fromIndex: number,
    toIndex: number,
    toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.MOVE_TEMPLATES_IN_CATEGORY,
        categoryId: toEntityId,
        fromIndex,
        toIndex,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const moveTemplateInCollection = (
  orderIndexes: OrderIndexes,
  template: Template,
  fromIndex: number,
  toIndex: number,
  toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.MOVE_TEMPLATES_IN_COLLECTION,
        collectionId: toEntityId,
        fromIndex,
        toIndex,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-collections/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const replaceTemplateFromCategoryToCategory = (
    orderIndexes: OrderIndexes,
    template: Template,
    fromIndex: number,
    toIndex: number,
    fromEntityId: string,
    toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.REPLACE_TEMPLATES_IN_CATEGORIES,
        toCategoryId: toEntityId,
        fromCategoryId: fromEntityId,
        toIndex,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const replaceTemplateFromCollectionToCollection = (
  orderIndexes: OrderIndexes,
  template: Template,
  fromIndex: number,
  toIndex: number,
  fromEntityId: string,
  toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.REPLACE_TEMPLATES_IN_COLLECTIONS,
        toCollectionId: toEntityId,
        fromCollectionId: fromEntityId,
        toIndex,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-collections/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};
export const removeTemplateFromCategory = (
    template: Template,
    entityId: string,
    relationId?: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.REMOVE_TEMPLATE_FROM_CATEGORY,
        categoryId: entityId,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify({relationId}));
};

export const removeTemplateFromCollection = (
  template: Template,
  entityId: string,
  relationId?: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.REMOVE_TEMPLATE_FROM_COLLECTION,
        collectionId: entityId,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-collections/order-indexes', onload);

    xhr.send(JSON.stringify({relationId}));
};

export const addTemplateToCategory = (
    orderIndexes: OrderIndexes,
    template: Template,
    toIndex: number,
    toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.ADD_TEMPLATE_TO_CATEGORY,
        toCategoryId: toEntityId,
        toIndex,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const addTemplateToCollection = (
  orderIndexes: OrderIndexes,
  template: Template,
  toIndex: number,
  toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TemplatesActionType.ADD_TEMPLATE_TO_COLLECTION,
        toCollectionId: toEntityId,
        toIndex,
        template,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/templates-to-collections/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const deleteTemplate = (templateId: string) => (dispatch: any, getState: any) => {
    batch(() => {
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({type: TemplatesActionType.DELETE_TEMPLATE, templateId, templates: getState().templates.templates});
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'DELETE', '/reels/template/' + templateId, onload);

    xhr.send();
};

export const updateTemplate = (template: Template, isUpdateRelations: boolean) => (dispatch: any, getState: any) => {
    const templates = getState().templates.templates;
    dispatch({type: TemplatesActionType.UPDATE_TEMPLATE, template: {...template}, isUpdateRelations});
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TagsActionTypes.UPDATE_TEMPLATE_TAGS,
        newTemplate: template,
        oldTemplate: templates.find((oldTemplate: Template) => {
            return oldTemplate.id === template.id;
        }),
        templates,
    });

    dispatch({
        type: GeneralActionType.IS_UPDATING_CONTENT,
        isUpdating: true,
    });

    const onload = () => {
        dispatch({
            type: GeneralActionType.IS_UPDATING_CONTENT,
            isUpdating: false,
        });
    };
    const xhr = createRequest(dispatch, 'PATCH', '/reels/template', onload);

    xhr.send(JSON.stringify({...template, ...{isUpdateRelations}}));
};

export const selectTemplate = (templateId: string, templateListType: TemplatesListType) => (dispatch: any) => {
    dispatch({
        type: TemplatesActionType.SELECT_TEMPLATE,
        templateId,
        templateListType
    });
};

export const setEditTemplate = (template?: Template | null) => (dispatch: any) => {
        dispatch({
            type: TemplatesActionType.SET_EDIT_TEMPLATE,
            template,
        });
};

export const setDeleteTemplate = (template?: Template | null) => (dispatch: any) => {
    dispatch({
        type: TemplatesActionType.SET_DELETE_TEMPLATE,
        template,
    });
};

export const getTemplatesViewCount = () => (dispatch: any) => {
    const onload = (responseText: any) => {
        let counts;
        try {
            counts = JSON.parse(new TextDecoder().decode(responseText));
        } catch (e) {
            console.log(e);
            counts = null;
        }
        dispatch({type: TemplatesActionType.SAVE_TEMPLATES_VIEW_COUNT, counts});
    };

    getFileArrayBuffer(`${configs.resUrl}reels_template_view/counts.json`, onload);
};

export const getTemplatesExportCount = () => (dispatch: any) => {
    const onload = (responseText: any) => {
        let counts;
        try {
            counts = JSON.parse(new TextDecoder().decode(responseText));
        } catch (e) {
            console.log(e);
            counts = null;
        }
        dispatch({type: TemplatesActionType.SAVE_TEMPLATES_EXPORT_COUNT, counts});
    };

    getFileArrayBuffer(`${configs.resUrl}reels_template_export/counts.json`, onload);
};

export const compressImage = (videoUrl: string, resultFileName: string) => (dispatch: any, getState: any) => {
    dispatch({
        type: TemplatesActionType.SET_IS_COMPRESSING_IMAGE,
        isCompressing: true,
    });
    const stateIdTemplates = getState().templates.stateId;
    const timeStampRequest = momentZ().format('x');

    const payload = {
        action: WebsocketAction.START_COMPRESS_IMAGE,
        requestTimeEpoch: Number(timeStampRequest),
        stateId: stateIdTemplates,
        mediaS3Url: videoUrl,
        resultFileName
    };
    dispatch({
        type: WEBSOCKET_SEND,
        payload: payload,
    });
};
