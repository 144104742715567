import React from 'react';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {Collection} from "../../models/Collection";
import {CollectionsList} from "./CollectionsList";
import {LibraryFilterType, PlatformType} from "../../models/Common";
import {setIsFullViewCollection, updateCollectionsOrderIndexes} from '../../actions/collections';
import {ModalType} from "../../models/Modals";
import {ReactComponent as PlusIcon} from "../../resources/imgs/plus-icon.svg";
import {IsFullViewCollection} from '../../reducers/collections';

interface StateProps {
  collections: Collection[],
  isLoadingCollections: boolean,
  templatesByCollectionsIds: any,
  selectedFilterType: LibraryFilterType,
  selectedPlatformType: PlatformType,
  isFullViewCollections: IsFullViewCollection
}

interface DispatchProps {
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  updateCollectionsOrderIndexes: (orderIndexes: any) => void,
  setIsFullViewCollection: (categoryId: string, isFullView: boolean) => void
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps


const CollectionsSection = ({collections, isLoadingCollections, selectedFilterType, templatesByCollectionsIds,
  updateCollectionsOrderIndexes, showModal, isFullViewCollections, setIsFullViewCollection}: Props) => {

  return (
    <div className={'section-container'} style={{marginTop: '30px'}}>
      <div style={{overflow: 'auto'}}>
        <CollectionsList
          isLoadingCollections={isLoadingCollections}
          selectedFilterType={selectedFilterType}
          updateCollectionsOrderIndexes={updateCollectionsOrderIndexes}
          templatesByCollectionsIds={templatesByCollectionsIds}
          collections={collections}
          isFullViewCollections={isFullViewCollections}
          setIsFullViewCollection={setIsFullViewCollection}
          showModal={showModal}
        />
      </div>
      {
        selectedFilterType === LibraryFilterType.BY_COLLECTION ? <div style={{display: 'flex', alignSelf: 'center', paddingTop: '46px'}}>
          <div className={'reels-add-header-add-container'} onClick={() => {
            showModal(ModalType.COLLECTION_ADD, true, {});
          }}>
            <PlusIcon style={{width: '12px', height: '12px'}}/>
            <div className={'reels-add-header-text-add'}>
              Add New Collection
            </div>
          </div>
        </div> : null
      }
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  collections: state.collections.collections,
  isLoadingCollections: state.collections.isLoadingCollections,
  selectedFilterType: state.appState.selectedFilterType,
  selectedPlatformType: state.appState.selectedPlatformType,
  templatesByCollectionsIds: state.templates.templatesByCollectionsIds,
  isFullViewCollections: state.collections.isFullViewCollections
});

const mapDispatchToProps = (dispatch: any) => ({
  showModal: bindActionCreators(showModal, dispatch),
  updateCollectionsOrderIndexes: bindActionCreators(updateCollectionsOrderIndexes, dispatch),
  setIsFullViewCollection: bindActionCreators(setIsFullViewCollection, dispatch)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(CollectionsSection);
