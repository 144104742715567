import React, {useCallback, useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import {bindActionCreators} from 'redux';
import {
  selectTemplate,
  updateTemplate,
} from '../../actions/templates';
import {connect} from 'react-redux';
import {TemplatesListType} from '../../models/Common';
import {ModalType} from "../../models/Modals";
import {Template} from "../../models/Template";
import {OrderIndexes} from "../../models/OrderIndexes";
import {showModal} from "../../actions/general";
import {spinnerColor} from "../../utils/StyleUtils";
import {TemplatesEventCount} from "../../reducers/templates";
import {closestCenter, DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, horizontalListSortingStrategy, rectSortingStrategy, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import SortableTemplateView from './SortableTemplateView';

interface StateProps {
  isLoadingTemplates: boolean,
  selectedTemplateIds: any,
  templatesAll: Template[],
  templatesViewCount: TemplatesEventCount,
  templatesExportCount: TemplatesEventCount
}

interface DispatchProps {
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  selectTemplate: (templateId: string, templateListType: TemplatesListType) => void,
  updateTemplate: (template: Template, isUpdateRelations: boolean) => void,
}

interface OwnProps {
  templatesListType: TemplatesListType,
  entityContainerId?: string | null,
  templates: Template[],
  externalId?: string,
  isGridLayout?: boolean | null,
  isDropDisabled?: boolean | undefined,
  moveTemplateInEntity?: ((
    orderIndexes: OrderIndexes,
    template: Template,
    fromIndex: number,
    toIndex: number,
    toEntityId: string
  ) => void) | undefined | null,

  removeTemplateFromEntity: (template: Template, entityId: string, relationId?: string) => void,
}

type Props = StateProps & DispatchProps & OwnProps

const TemplatesList = ({templates, isLoadingTemplates, selectTemplate,
  templatesListType, externalId = '', moveTemplateInEntity, isGridLayout,
  selectedTemplateIds, entityContainerId,
  templatesViewCount, templatesExportCount, isDropDisabled
}: Props) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id && entityContainerId) {
      const oldTemplate = findTemplate(active.id, templates);
      const newTemplate = findTemplate(over!.id, templates);
      const newLocalTemplates = arrayMove(templates, oldTemplate.index, newTemplate.index);

      moveTemplateInEntity!(
        {
          orderIndexes: newLocalTemplates.map((template, index) => {
            return {
              entityId: template.relationId!,
              entityIdOld: null,
              orderIndex: index,
            };
          }),
          parentId: entityContainerId
        },
        oldTemplate.template,
        oldTemplate.index,
        newTemplate.index,
        entityContainerId,
      );
    }
  }

  const findTemplate = (id: string | number, templates: Template[]) => {
    const template = templates.filter((c) => c.id === id)[0];

    return {
      template,
      index: templates.indexOf(template),
    };
  };

  if (isLoadingTemplates) {
    return (
      <div className='spinner-container' style={{height: '220px'}}>
        <MDSpinner size={100} singleColor={spinnerColor}/>
      </div>
    );
  }
  return (
      <div style={{zIndex: 100}} className={templatesListType === TemplatesListType.TEMPLATES_IN_CATEGORY || templatesListType === TemplatesListType.TEMPLATES_IN_COLLECTION ? (isGridLayout ? 'template-list-in-grid': 'template-list-in-scroll') : 'template-list-in-grid'}>
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >

      <SortableContext disabled={isDropDisabled} items={templates} strategy={isGridLayout ? rectSortingStrategy : horizontalListSortingStrategy}>
        {templates.map((template, index) => {
            return <SortableTemplateView
                key = {template.id}
                arrayIndex = {index}
                template = {template}
                isSelected = {selectedTemplateIds[templatesListType].indexOf(template.id + '_' + externalId) !== -1}
                selectTemplate = {selectTemplate}
                externalId = {externalId}
                templateListType = {templatesListType}
                entityContainerId = {entityContainerId}
                viewCount = {templatesViewCount[template.id] ? templatesViewCount[template.id] : 0}
                exportCount = {templatesExportCount[template.id] ? templatesExportCount[template.id] : 0}
                />
             })}
          </SortableContext>
        </DndContext>
      </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoadingTemplates: state.templates.isLoadingTemplates,
  selectedTemplateIds: state.templates.selectedTemplateIds,
  templatesByCategoriesIds: state.templates.templatesByCategoriesIds,
  templatesAll: state.templates.templates,
  templatesViewCount: state.templates.templatesViewCount,
  templatesExportCount: state.templates.templatesExportCount,
});

const mapDispatchToProps = (dispatch: any) => ({
  selectTemplate: bindActionCreators(selectTemplate, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
  showModal: bindActionCreators(showModal, dispatch)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(TemplatesList)
