import React from 'react';
import MDSpinner from 'react-md-spinner';
import {getStyle, spinnerColor} from '../../utils/StyleUtils';
import {getFilenameFromUrl} from "../../utils/FilesUtils";

interface Props {
    style?: any,
    value: any,
    handleChange: (file: File, fileName: string) => void,
    isLoading: boolean,
    placeholder?: string,
    clear: () => void
}

export const AudioFileInput = ({style, value, handleChange, isLoading, placeholder, clear}: Props) => {

  if (isLoading) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px'}}>
      <MDSpinner size={20} singleColor={spinnerColor}/>
    </div>;
  }

  const isEmpty = !value || value === '';

  return (
    <div className={getStyle('reels-input-file-container')} style={style}>
      <input type="file" className="reels-input-file"
        multiple={false}
        placeholder={placeholder}
        onChange={(event) => {
          if (event.target && event.target.files && event.target.files.length > 0 && event.target.files[0].type.startsWith('audio/') && event.target.files[0].size < 2000000) {
              const targetFiles: any = event.target.files;
              const files = Object.keys(targetFiles)
                  .map((keyFile) => {
                      return targetFiles[keyFile]
                  });
              handleChange(files[0], files[0].name.split(/(\\|\/)/g).pop());
          }
        }}/>
      {!isEmpty ? <div style={{marginLeft: '6px'}} className={'music-sign-img'}/> : null}
      <div className={isEmpty ? "reels-input-file-placeholder" : "reels-input-file-text"}>
        {!isEmpty ? getFilenameFromUrl(value) : placeholder}
      </div>
        {!isEmpty ? <div style={{paddingRight: '10px', marginLeft: 'auto'}} onClick={clear} className={getStyle('reels-input-delete-file-btn')}/> :
            <div style={{paddingRight: '10px', marginLeft: 'auto'}} className={getStyle('reels-input-add-file-btn')}/>
        }
    </div>
  );
};
