import React, { Component } from 'react'
// @ts-ignore
import debounce from 'throttle-debounce/debounce'
import TextareaAutosize from 'react-textarea-autosize';

type Props = {
    value: string,
    debounce?: number,
    handleChange: any,
    minRows?: number,
    maxRows?: number,
    isFocus?: boolean,
    style?: any
    placeholder?: string,
    isDisabled?: boolean,
    className?: string,
    onScroll?: () => void,
    key: string
}

type State = {
    inputValue: string
}

export class TextArea extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {inputValue: this.props.value}
    }

    handleChange = debounce(this.props.debounce ?
      this.props.debounce : 250, () => this.props.handleChange(this.state.inputValue)
    )

    shouldComponentUpdate (nextProps: Props, nextState: State, nextContext: any) {
        //console.log(nextProps.value)
        if(nextProps.value !== this.props.value){
            this.setState({inputValue: nextProps.value})
        }
        return nextProps.value !== this.props.value || this.state.inputValue !== nextState.inputValue
    }

    handleChangeInput = (value: string) => {
        this.setState({inputValue: value}, () => this.handleChange())
    }

    render () {
        const {minRows = 1, maxRows = 5, key = 'def'} = this.props
        return (
            <TextareaAutosize
                    key={key}
                   value={this.state.inputValue}
                   onScroll={this.props.onScroll}
                   onChange={(event) => (
                       this.handleChangeInput(event.target.value)
                   )}
                   minRows={minRows}
                   maxRows={maxRows}
                   className="reels-input"
                   placeholder={this.props.placeholder ? this.props.placeholder : ""}
                   disabled={this.props.isDisabled}
                   style={this.props.style}
            />
        )
    }
}
