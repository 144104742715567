import React, {forwardRef, HTMLAttributes} from 'react';
import {Collection} from "../../models/Collection";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setEditCollection} from '../../actions/collections';

interface StateProps {}

interface DispatchProps {
  editCollection: (collection: Collection) => void
}

export type CollectionSimpleOwnProps = HTMLAttributes<HTMLDivElement> & {
  collection: Collection,
  templatesCount: number,
}

type Props = StateProps & DispatchProps & CollectionSimpleOwnProps

const CollectionSimpleView = forwardRef<HTMLDivElement, Props>((
  {collection, templatesCount, editCollection, style, ...props}, ref
) => {
    return (<div className={'template-list-content'}
                 ref={ref}
                 {...props}
                   onClick={() => {
                     editCollection(collection)
                   }}
                   style={{...style, ...{gap: '10px'}}}
              >
                <div className={'collection-simple-list-item'}
                     style={{
                       cursor: ('pointer'),
                       backgroundColor: ('none'),
                       opacity: collection.isActive ? '1' : '0.4',
                       position: 'relative'
                     }}
                >
                  <div className={'collection-simple-video'} style={{backgroundColor: 'black', opacity: 0, position: 'absolute', zIndex: 1}}></div>
                  {
                    collection.previewVideoUrl ? <video className={'collection-simple-video'}>
                        <source src={collection.previewVideoUrl} type="video/mp4"/>
                      </video> :
                      <div className={'collection-simple-video'}></div>
                  }
                </div>
                <div className={'collection-simple-name'}>
                  {collection.name + ' '}({templatesCount})
                </div>
              </div>
    );
});

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
  editCollection: bindActionCreators(setEditCollection, dispatch)});

export default connect<StateProps, DispatchProps, CollectionSimpleOwnProps>(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CollectionSimpleView)
