import React from 'react';
import TrackCategories from './trackCategories';
import Tracks from './tracks';

export const Music = () => {
  return (
    <div style={{overflow: 'overlay', width: '100%'}}>
      <div className={'music-container'}>
        <TrackCategories/>
        <Tracks/>
      </div>
    </div>
  );
};
