import React from 'react';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {TagsList} from './TagsList';
import {AiOutlinePlus} from 'react-icons/ai';
import {addTag, orderTags, setDeleteTag, setEditTag, updateTag} from '../../actions/tags';
import uuid from 'uuid';
import MDSpinner from 'react-md-spinner';
import {spinnerColor} from "../../utils/StyleUtils";
import {Tag} from "../../models/Tag";
import {ModalType} from "../../models/Modals";

interface StateProps {
  tags: Tag[],
  allTags: Tag[],
  orderParams: any,
  isLoadingTags: boolean,
}

interface DispatchProps {
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  addTag: (tag: Tag) => void,
  updateTag: (tag: Tag) => void,
  setDeleteTag: (tag: Tag) => void,
  setEditTag: (tag: Tag) => void,
  orderTags: (orderParams: any) => void
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

const TagsSection = ({allTags, tags, addTag, updateTag, setDeleteTag,
  isLoadingTags, setEditTag, orderParams, orderTags}: Props) => {
  if (isLoadingTags) {
    return (
      <div className={'tags-container'}>
        <div className='spinner-container' style={{height: '424px'}}>
          <MDSpinner size={100} singleColor={spinnerColor}/>
        </div>
      </div>
    );
  }
  return (
    <div className={'tags-container'}>
      <TagsList
        tags={tags}
        updateTag={updateTag}
        setDeleteTag={setDeleteTag}
        setEditTag={setEditTag}
        orderParams={orderParams}
        orderTags={orderTags}
      />
      <div className={'tags-add-container tag-text'}
           onClick={() => {
             addTag({
               id: uuid.v4(),
               name: '1 new tag',
               imageUrl: null,
               localization: {},
               isActive: false,
               order: (allTags.length !== 0 ? allTags[allTags.length - 1].order + 1 : 0),
               templatesIds: [],
               createdAt: new Date(),
               updatedAt: new Date(),
             });
           }}
      >
        <AiOutlinePlus style={{fontSize: '19px'}}/>
        <div style={{paddingLeft: '8px'}}>Add Word</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  tags: state.tags.sortedTags,
  allTags: state.tags.tags,
  orderParams: state.tags.orderTagsParams,
  isLoadingTags: state.tags.isLoadingTags,
});

const mapDispatchToProps = (dispatch: any) => ({
  showModal: bindActionCreators(showModal, dispatch),
  addTag: bindActionCreators(addTag, dispatch),
  updateTag: bindActionCreators(updateTag, dispatch),
  setDeleteTag: bindActionCreators(setDeleteTag, dispatch),
  setEditTag: bindActionCreators(setEditTag, dispatch),
  orderTags: bindActionCreators(orderTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsSection);
