import React from 'react';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import {bindActionCreators} from 'redux';
import {Category} from "../../models/Category";
import {LibraryFilterType, PlatformType, reservedCategoriesIds} from "../../models/Common";
import {setIsFullViewCategory, updateCategoriesOrderIndexes} from '../../actions/categories';
import {ModalType} from "../../models/Modals";
import {ReactComponent as PlusIcon} from "../../resources/imgs/plus-icon.svg";
import {IsFullViewCategory} from '../../reducers/categories';
import {CategoriesList} from './CategoriesList';

interface StateProps {
  categories: Category[],
  isLoadingCategories: boolean,
  templatesByCategoriesIds: any,
  selectedFilterType: LibraryFilterType,
  selectedPlatformType: PlatformType,
  isFullViewCategories: IsFullViewCategory
}

interface DispatchProps {
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  updateCategoriesOrderIndexes: (orderIndexes: any) => void,
  setIsFullViewCategory: (categoryId: string, isFullView: boolean) => void
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps


const CategoriesSection = ({categories, isLoadingCategories, selectedFilterType, templatesByCategoriesIds,
  updateCategoriesOrderIndexes, showModal, isFullViewCategories, setIsFullViewCategory}: Props) => {

  let localCategories = categories.filter(category => {
    if (selectedFilterType === LibraryFilterType.BY_CATEGORY) {
      return reservedCategoriesIds.indexOf(category.id) === -1;
    } else {
      return reservedCategoriesIds.indexOf(category.id) !== -1 || category.isFeatured;
    }
  })
  return (
    <div className={'section-container'} style={{marginTop: '30px'}}>
      <div style={{overflow: 'auto'}}>
        <CategoriesList
          isLoadingCategories={isLoadingCategories}
          selectedFilterType={selectedFilterType}
          updateCategoriesOrderIndexes={updateCategoriesOrderIndexes}
          templatesByCategoriesIds={templatesByCategoriesIds}
          categories={localCategories}
          isFullViewCategories={isFullViewCategories}
          setIsFullViewCategory={setIsFullViewCategory}
          showModal={showModal}
        />
      </div>
      {
        selectedFilterType === LibraryFilterType.BY_CATEGORY ? <div style={{display: 'flex', alignSelf: 'center', paddingTop: '46px'}}>
          <div className={'reels-add-header-add-container'} onClick={() => {
            showModal(ModalType.CATEGORY_ADD, true, {});
          }}>
            <PlusIcon style={{width: '12px', height: '12px'}}/>
            <div className={'reels-add-header-text-add'}>
              Add New Category
            </div>
          </div>
        </div> : null
      }
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.categories.categories,
  isLoadingCategories: state.categories.isLoadingCategories,
  selectedFilterType: state.appState.selectedFilterType,
  selectedPlatformType: state.appState.selectedPlatformType,
  templatesByCategoriesIds: state.templates.templatesByCategoriesIds,
  isFullViewCategories: state.categories.isFullViewCategories
});

const mapDispatchToProps = (dispatch: any) => ({
  showModal: bindActionCreators(showModal, dispatch),
  updateCategoriesOrderIndexes: bindActionCreators(updateCategoriesOrderIndexes, dispatch),
  setIsFullViewCategory: bindActionCreators(setIsFullViewCategory, dispatch)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(CategoriesSection);
