import React from 'react';
import {IoMdClose as CloseIcon} from 'react-icons/io';

import MoveTo from './MoveTo';
import DropdownWrapper from './DropdownWrapper';
import {compareString} from '../../utils/ArrayUtils';
import {Tag} from "../../models/Tag";

interface Props {
    tagIds: string[],
    allTags: Tag[],
    addTag: (tag: Tag) => void,
    removeTag: (tagId: string) => void,
    sourceItem: any
}

export const TagsInput = ({tagIds, allTags, addTag, removeTag, sourceItem}: Props) => {
  return <DropdownWrapper style={{right: '31%', paddingTop: '3px'}} isRevert={true} isRerender={true}>
    <div className={'tags-input-container'}>
      {
        tagIds.map((tagId) => {
          const tag = allTags.find((tag) => tag.id === tagId);
          if (!tag) {
              throw new Error('Tag not found');
          }
          return <div className={'tag-badge'}
            key={tagId}
          >
            {`${tag.name}`}
            <div className={'close-tag'}>
              <CloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  removeTag(tagId);
                }}
                style={{paddingLeft: '5px', width: '20px'}}
              />
            </div>
          </div>;
        })
      }
    </div>

    <MoveTo
      moveTo={(sourceItem: any, tag: Tag) => {
        addTag(tag);
      }}
      items={allTags
          .filter((tag) => {
            return !tagIds.find((tagId) => {
              return tagId === tag.id;
            }) && tag.isActive;
          })
          .sort(compareString('name'))
      }
      isFocus={true}
      isClose={false}
      propName={'name'}
      renderProp={(tag: Tag) => {
        return `${tag.name} (${tag.templatesIds.length})`;
      }}
      placeholder={'Search'}
      sourceItem={sourceItem}
      containerClassName={'component-settings-container'}
    />

  </DropdownWrapper>;
};
