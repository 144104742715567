import React, {useState} from 'react';
import {Input} from '../../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {changeLocalization, getLocalizedValue, locales} from '../../../utils/LocalizationUtils';
import {Category} from "../../../models/Category";
import {DetailsModeType} from "../../../models/Common";
import {addTrackCategory, updateTrackCategory} from "../../../actions/categories";
import {Checkbox} from "../../general/Checkbox";
import uuid from "uuid";
import {TrackCategory} from '../../../models/TrackCategory';

interface StateProps {
  categories: Category[]
}

interface DispatchProps {
  addCategory: (category: TrackCategory) => void,
  updateCategory: (category: TrackCategory) => void,
}

interface OwnProps {
  mode: DetailsModeType,
  category: TrackCategory,
  cancel: () => void,
  deleteCategory?: (category: TrackCategory) => void
  isLoadingCategories?: boolean,
  templatesByCategoriesIds?: any,
  isBlockDelete?: boolean
}

type Props = StateProps & DispatchProps & OwnProps

const TrackCategoryDetails = ({mode, category, cancel, deleteCategory,
  addCategory, updateCategory, isBlockDelete = false, categories
}: Props) => {
  const [id, setId] = useState(mode === DetailsModeType.ADD ? uuid.v4() : category.id);
  const [name, setName] = useState(category.name);
  const [localization, setLocalization] = useState(category.localization);
  const [order, setOrder] = useState(mode === DetailsModeType.ADD ? 1 : category.order);
  const [isActive, setIsActive] = useState(category.isActive);

  const handleSaveGroup = () => {
    const categoryForSave = {...category};
    categoryForSave.id = id;
    categoryForSave.name = name;
    categoryForSave.localization = localization;
    categoryForSave.isActive = isActive;
    categoryForSave.order = order;

    mode === DetailsModeType.EDIT ? updateCategory(categoryForSave) : addCategory(categoryForSave);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'}>
      <div className={'reels-head-text'}>
        {mode === DetailsModeType.EDIT ? 'Edit Category' : 'Add Category'}
      </div>
    </div>;
  };

  const renderName = (lang: string) => {
    return <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column'}} key={lang + 'input'}>
      <div className={'reels-second-text'}>
        {lang}
      </div>
      <Input style={{marginTop: '7px', width: '200px'}}
        handleChange={(value: string) => {
          changeLocalization(value, 'name', localization, setName, setLocalization, lang);
        }}
        value={getLocalizedValue(localization, 'name', lang)}
       />
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'} style={{width: '100%'}}>
      <div style={{display: "flex"}}>
        <Checkbox
            key={'visibility-checkbox'}
            switch={setIsActive}
            isActive={isActive}
            text={'Visibility'}
        />
      </div>

      <div style={{display: 'flex', marginTop: '24px', flexDirection: 'column', width: '100%'}}>
        <div className={'reels-second-text'}>
                    English
        </div>
        <Input style={{marginTop: '7px'}}
          handleChange={(value: string) => {
            changeLocalization(value, 'name', localization, setName, setLocalization);
          }}
          value={getLocalizedValue(localization, 'name')}
        />
      </div>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {locales.map((locale) => {
          if (locale === 'en') {
            return null;
          }
          return renderName(locale);
        })}
      </div>
    </div>;
  };

  const renderFooter = () => {
    return <div className={'details-footer'}>
      <button className={mode === DetailsModeType.EDIT || isBlockDelete ? 'reels-delete-btn' : 'reels-second-btn'}
        style={{marginRight: (mode === DetailsModeType.EDIT || isBlockDelete ? 'auto' : undefined)}}
        onClick={() => {
          if (mode === DetailsModeType.EDIT || isBlockDelete) {
            deleteCategory ? deleteCategory(category) : () => {};
          }
          cancel();
        }}
      >
        {mode === DetailsModeType.EDIT || isBlockDelete ? 'Delete' : 'Cancel'}
      </button>
      <button className={'save-btn'} style={{marginLeft: '24px'}}
        onClick={handleSaveGroup}
        disabled={name === ''}
      >
        {mode === DetailsModeType.EDIT ? 'Save' : 'Add'}
      </button>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    {renderFooter()}
  </div>;
};

const mapStateToProps = (state: any) => ({
  categories: state.categories.tracksCategories
});

const mapDispatchToProps = (dispatch: any) => ({
  addCategory: bindActionCreators(addTrackCategory, dispatch),
  updateCategory: bindActionCreators(updateTrackCategory, dispatch),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(TrackCategoryDetails);
