import {combineReducers} from 'redux';
import appState from './appState';
import auth from './auth';
import categories from "./categories";
import templates from "./templates";
import tags from "./tags";
import collections from "./collections";
import tracks from './tracks';

const appReducer = combineReducers({
  appState,
  auth,
  categories,
  templates,
  tags,
  collections,
  tracks
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
