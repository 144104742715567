// Action types to be dispatched by the user
export const WEBSOCKET_CONNECT = 'WEBSOCKET:CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET:DISCONNECT';
export const WEBSOCKET_SEND = 'WEBSOCKET:SEND';
// Action types dispatched by the WebSocket implementation
export const WEBSOCKET_CONNECTING = 'WEBSOCKET:CONNECTING';
export const WEBSOCKET_OPEN = 'WEBSOCKET:OPEN';
export const WEBSOCKET_CLOSED = 'WEBSOCKET:CLOSED';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET:MESSAGE';
export const WEBSOCKET_ERROR = 'WEBSOCKET:ERROR';

export enum WebsocketServiceType {
    REELS_MAKER = 'REELS_MAKER',
}

export const WebsocketAction = {
    REELS_FEED_PUBLISH: {name: 'REELS_FEED_PUBLISH', serviceType: WebsocketServiceType.REELS_MAKER},
    IS_REELS_FEED_PUBLISHING: {name: 'IS_REELS_FEED_PUBLISHING', serviceType: WebsocketServiceType.REELS_MAKER},
    STATUS_REELS_FEED_PUBLISH: {name: 'STATUS_REELS_FEED_PUBLISH', serviceType: WebsocketServiceType.REELS_MAKER},
    START_COMPRESS_VIDEO: {name: 'START_COMPRESS_VIDEO', serviceType: WebsocketServiceType.REELS_MAKER},
    FINISH_COMPRESS_VIDEO: {name: 'FINISH_COMPRESS_VIDEO', serviceType: WebsocketServiceType.REELS_MAKER},
    START_COMPRESS_IMAGE: {name: 'START_COMPRESS_IMAGE', serviceType: WebsocketServiceType.REELS_MAKER},
    FINISH_COMPRESS_IMAGE: {name: 'FINISH_COMPRESS_IMAGE', serviceType: WebsocketServiceType.REELS_MAKER},
};

export enum WebsocketMessageType {
    SINGLE = 'SINGLE',
    ARRAY = 'ARRAY',
    FRAMES = 'FRAMES'
}
