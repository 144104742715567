import momentZ from 'moment-timezone';

import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {ModalType} from '../models/Modals';
import {LibraryFilterType, StatusPublishFeed} from "../models/Common";
import {WEBSOCKET_SEND, WebsocketAction} from "../models/Websockets";
import {CategoriesActionType} from "./categories";
import {TemplatesActionType} from "./templates";
import {TagsActionTypes} from "./tags";
import {configs} from "../resources/configs";
import {RemoteConfig} from "../models/RemoteConfig";
import {ReelsRemoteConfig} from "../../../../app-backend/src/models/ReelsMaker";
import {CollectionsActionType} from './collections';
import {TracksActionType} from './tracks';

export enum GeneralActionType {
  SHOW_MODAL = 'SHOW_MODAL',
  SAVE_ADMIN = 'SAVE_ADMIN',
  SAVE_ADMINS = 'SAVE_ADMINS',
  SET_ERROR = 'SET_ERROR',
  SET_IS_LOADING_ADMINS = 'SET_IS_LOADING_ADMINS',
  SAVE_FEED = 'SAVE_FEED',
  SET_IS_RELOAD_FEED = 'SET_IS_RELOAD_FEED',
  SELECT_FILTER_TYPE = 'SELECT_FILTER_TYPE',
  SET_IS_BLOCK_PUBLISH = 'SET_IS_BLOCK_PUBLISH',
  IS_UPDATING_CONTENT = 'IS_UPDATING_CONTENT',
  SET_IS_LOADING_REMOTE_CONFIG = 'SET_IS_LOADING_REMOTE_CONFIG',
  SAVE_REMOTE_CONFIG = 'SAVE_REMOTE_CONFIG',
  SET_IS_COMPRESSING_VIDEO = 'SET_IS_COMPRESSING_VIDEO',
}

export const showModal = (modalType: ModalType, isShow: boolean, meta: any) => (dispatch: any) => {
  dispatch({
    type: GeneralActionType.SHOW_MODAL,
    modalType,
    isShow,
    meta,
  });
};

export const selectFilterType = (filterType: LibraryFilterType) => (dispatch: any) => {
  dispatch({
    type: GeneralActionType.SELECT_FILTER_TYPE,
    filterType,
  });
};

export const setError = (error: string) => (dispatch: any) => {
  dispatch({
    type: GeneralActionType.SET_ERROR,
    error,
  });
};

export const getAdmins = () => (dispatch: any) => {
  const onload = (responseText: string) => {
    const admins = JSON.parse(responseText);
    dispatch({type: GeneralActionType.SAVE_ADMINS, admins});
  };
  const xhr = createRequest(dispatch, 'GET', `/admins`, onload);

  xhr.send();
};

export const saveAdmin = (admin: any) => (dispatch: any) => {
  dispatch({
    type: GeneralActionType.SAVE_ADMIN,
    admin,
  });
};

export const publish = () => (dispatch: any) => {
  dispatch({
    type: WebsocketAction.STATUS_REELS_FEED_PUBLISH.name,
    data: {message: {status: StatusPublishFeed.PUBLISHING}},
  });

  const payload = {
    action: WebsocketAction.REELS_FEED_PUBLISH,
  };
  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};

export const getFeed = () => (dispatch: any, getState: any) => {
  dispatch({type: TemplatesActionType.IS_LOADING_TEMPLATES, isLoading: true});
  dispatch({type: CategoriesActionType.IS_LOADING_CATEGORIES, isLoading: true});
  dispatch({type: TracksActionType.IS_LOADING_TRACKS, isLoading: true});
  dispatch({type: CategoriesActionType.IS_LOADING_TRACK_CATEGORIES, isLoading: true});
  dispatch({type: TagsActionTypes.IS_LOADING_TAGS, isLoading: true});
  dispatch({type: CollectionsActionType.IS_LOADING_COLLECTIONS, isLoading: true});
  dispatch({type: GeneralActionType.SET_IS_RELOAD_FEED, isReload: false});

  const stateIdCategories = getState().categories.stateId;
  const stateIdTemplates = getState().templates.stateId;
  const stateIdTags = getState().tags.stateId;
  const stateIdCollections = getState().collections.stateId;
  const stateIdTracks = getState().tracks.stateId;

  const onload = (responseText: string) => {
    const response = JSON.parse(responseText);
    const timeStampRequest = momentZ().format('x');
    const categories = response.categories ? response.categories : [];
    const collections = response.collections ? response.collections : [];
    const tracksCategories = response.tracksCategories ? response.tracksCategories : [];

    dispatch({
      type: GeneralActionType.SAVE_FEED,
      feed: response,
    });

    dispatch({
      type: CategoriesActionType.SAVE_CATEGORIES,
      stateId: stateIdCategories,
      requestTimeEpoch: timeStampRequest,
      categories: categories,
    });

    dispatch({
      type: CategoriesActionType.SAVE_TRACK_CATEGORIES,
      stateId: stateIdCategories,
      requestTimeEpoch: timeStampRequest,
      categories: tracksCategories,
    });

    dispatch({
      type: CollectionsActionType.SAVE_COLLECTIONS,
      stateId: stateIdCollections,
      requestTimeEpoch: timeStampRequest,
      collections: collections,
    });

    dispatch({
      type: TracksActionType.SAVE_TRACKS,
      stateId: stateIdTracks,
      requestTimeEpoch: timeStampRequest,
      tracksCategories,
      tracks: response.tracks ? response.tracks : [],
    });

    dispatch({
      type: TemplatesActionType.SAVE_TEMPLATES,
      stateId: stateIdTemplates,
      requestTimeEpoch: timeStampRequest,
      categories,
      collections,
      templates: response.templates ? response.templates : [],
    });

    dispatch({
      type: TagsActionTypes.SAVE_TAGS,
      stateId: stateIdTags,
      requestTimeEpoch: timeStampRequest,
      templates: response.templates ? response.templates : [],
      tags: response.tags ? response.tags : [],
    });
  };
  const xhr = createRequest(dispatch, 'GET', '/reels/feed', onload);

  xhr.send();
};

export const getStatusPublishFeed = () => (dispatch: any) => {
  const payload = {
    action: WebsocketAction.STATUS_REELS_FEED_PUBLISH,
  };
  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};

export const getRemoteConfig = () => (dispatch: any) => {
  dispatch({type: GeneralActionType.SET_IS_LOADING_REMOTE_CONFIG, isLoading: true});

  const onload = (responseText: string) => {
    let remoteConfig: ReelsRemoteConfig;
    try {
      remoteConfig = JSON.parse(responseText);
    } catch (e) {
      remoteConfig = {
        isTikTokEnabled: true,
        isYouTubeEnabled: true,
        isTikTokUniversalEnabled: true,
        isPushNotificationsEnabled: true,
        isCollectionsEnabled: true
      };
    }
    dispatch({type: GeneralActionType.SAVE_REMOTE_CONFIG, remoteConfig});
  };
  const xhr = createRequest(dispatch, 'GET', '/reels/config', onload, true);

  xhr.send();
};

export const updateRemoteConfig = (remoteConfig: RemoteConfig) => (dispatch: any) => {
  dispatch({type: GeneralActionType.SAVE_REMOTE_CONFIG, remoteConfig});
  const xhr = createRequest(dispatch, 'PUT', '/reels/config', () => {}, true);

  xhr.send(JSON.stringify(remoteConfig));
};

export const compressVideo = (videoUrl: string) => (dispatch: any, getState: any) => {
  dispatch({
    type: GeneralActionType.SET_IS_COMPRESSING_VIDEO,
    isCompressing: true,
  });
  const stateId = getState().appState.stateId;
  const timeStampRequest = momentZ().format('x');

  const payload = {
    action: WebsocketAction.START_COMPRESS_VIDEO,
    requestTimeEpoch: Number(timeStampRequest),
    stateId: stateId,
    mediaS3Url: videoUrl
  };
  dispatch({
    type: WEBSOCKET_SEND,
    payload: payload,
  });
};
