import {batch} from 'react-redux';
import {createRequest, getFileArrayBuffer} from '../utils/HttpRequestUtills';
import {GeneralActionType} from "./general";
import {Track} from "../models/Track";
import {OrderIndexes} from "../models/OrderIndexes";
import {TagsActionTypes} from "./tags";
import {configs} from "../resources/configs";
import {WEBSOCKET_SEND, WebsocketAction} from "../models/Websockets";
import momentZ from "moment-timezone";

export enum TracksActionType {
    SAVE_TRACKS = 'SAVE_TRACKS',
    IS_LOADING_TRACKS = 'IS_LOADING_TRACKS',
    DELETE_TRACK = 'DELETE_TRACK',
    UPDATE_TRACK = 'UPDATE_TRACK',
    SELECT_TRACK = 'SELECT_TRACK',
    REMOVE_TRACK_FROM_TRACK_CATEGORY = 'REMOVE_TRACK_FROM_TRACK_CATEGORY',
    MOVE_TRACKS_IN_TRACK_CATEGORY = 'MOVE_TRACKS_IN_TRACK_CATEGORY',
    REPLACE_TRACKS_IN_TRACK_CATEGORIES = 'REPLACE_TRACKS_IN_TRACK_CATEGORIES',
    ADD_TRACK_TO_TRACK_CATEGORY = 'ADD_TRACK_TO_TRACK_CATEGORY',
    ADD_TRACK = 'ADD_TRACK',
    REMOVE_TRACK_CATEGORY_FROM_TRACKS = 'REMOVE_TRACK_CATEGORY_FROM_TRACKS',
    UPDATE_TRACK_IN_TRACK_CATEGORY = 'UPDATE_TRACK_IN_TRACK_CATEGORY',
    UPDATE_RELATION_TRACK_TO_CATEGORY = 'UPDATE_RELATION_TRACK_TO_CATEGORY',
    REMOVE_TRACK_FROM_CATEGORIES = 'REMOVE_TRACK_FROM_CATEGORIES',
    SET_EDIT_TRACK = 'SET_EDIT_TRACK',
    SET_DELETE_TRACK = 'SET_DELETE_TRACK',
    SAVE_TRACKS_PLAYS_COUNT = 'SAVE_TRACKS_PLAYS_COUNT',
    SAVE_TRACKS_DOWNLOAD_COUNT = 'SAVE_TRACKS_DOWNLOAD_COUNT',
    SET_IS_COMPRESSING_IMAGE = 'SET_IS_COMPRESSING_IMAGE'
}

export const addTrack = (track: Track, isUpdateRelations: boolean) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.IS_UPDATING_CONTENT,
        isUpdating: true,
    });

    dispatch({
        type: TracksActionType.ADD_TRACK,
        track,
    });

    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });

    const onload = () => {
        dispatch({
            type: GeneralActionType.IS_UPDATING_CONTENT,
            isUpdating: false,
        });
    };
    const xhr = createRequest(dispatch, 'POST', '/reels/track', onload);

    xhr.send(JSON.stringify({...track, ...{isUpdateRelations}}));
};

export const moveTrackInCategory = (
    orderIndexes: OrderIndexes,
    track: Track,
    fromIndex: number,
    toIndex: number,
    toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TracksActionType.MOVE_TRACKS_IN_TRACK_CATEGORY,
        categoryId: toEntityId,
        fromIndex,
        toIndex,
        track,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/tracks-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const replaceTrackFromCategoryToCategory = (
    orderIndexes: OrderIndexes,
    track: Track,
    fromIndex: number,
    toIndex: number,
    fromEntityId: string,
    toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TracksActionType.REPLACE_TRACKS_IN_TRACK_CATEGORIES,
        toCategoryId: toEntityId,
        fromCategoryId: fromEntityId,
        toIndex,
        track,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/tracks-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const removeTrackFromCategory = (
    track: Track,
    entityId: string,
    relationId?: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TracksActionType.REMOVE_TRACK_FROM_TRACK_CATEGORY,
        categoryId: entityId,
        track,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/tracks-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify({relationId}));
};

export const addTrackToCategory = (
    orderIndexes: OrderIndexes,
    track: Track,
    toIndex: number,
    toEntityId: string,
) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: TracksActionType.ADD_TRACK_TO_TRACK_CATEGORY,
        toCategoryId: toEntityId,
        toIndex,
        track,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/tracks-to-categories/order-indexes', onload);

    xhr.send(JSON.stringify(orderIndexes));
};

export const deleteTrack = (trackId: string) => (dispatch: any, getState: any) => {
    batch(() => {
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({type: TracksActionType.DELETE_TRACK, trackId, tracks: getState().tracks.tracks});
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'DELETE', '/reels/track/' + trackId, onload);

    xhr.send();
};

export const updateTrack = (track: Track, isUpdateRelations: boolean) => (dispatch: any, getState: any) => {
    const tracks = getState().tracks.tracks;
    dispatch({type: TracksActionType.UPDATE_TRACK, track: {...track}, isUpdateRelations});
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });

    dispatch({
        type: GeneralActionType.IS_UPDATING_CONTENT,
        isUpdating: true,
    });

    const onload = () => {
        dispatch({
            type: GeneralActionType.IS_UPDATING_CONTENT,
            isUpdating: false,
        });
    };
    const xhr = createRequest(dispatch, 'PATCH', '/reels/track', onload);

    xhr.send(JSON.stringify({...track, ...{isUpdateRelations}}));
};

export const selectTrack = (trackId: string) => (dispatch: any) => {
    dispatch({
        type: TracksActionType.SELECT_TRACK,
        trackId
    });
};

export const setEditTrack = (track?: Track | null) => (dispatch: any) => {
        dispatch({
            type: TracksActionType.SET_EDIT_TRACK,
            track,
        });
};

export const setDeleteTrack = (track?: Track | null) => (dispatch: any) => {
    dispatch({
        type: TracksActionType.SET_DELETE_TRACK,
        track,
    });
};

export const getTracksPlaysCount = () => (dispatch: any) => {
    const onload = (responseText: any) => {
        let counts;
        try {
            counts = JSON.parse(new TextDecoder().decode(responseText));
        } catch (e) {
            console.log(e);
            counts = null;
        }
        dispatch({type: TracksActionType.SAVE_TRACKS_PLAYS_COUNT, counts});
    };

    getFileArrayBuffer(`${configs.resUrl}reels_track_plays/counts.json`, onload);
};

export const getTracksDownloadCount = () => (dispatch: any) => {
    const onload = (responseText: any) => {
        let counts;
        try {
            counts = JSON.parse(new TextDecoder().decode(responseText));
        } catch (e) {
            console.log(e);
            counts = null;
        }
        dispatch({type: TracksActionType.SAVE_TRACKS_DOWNLOAD_COUNT, counts});
    };

    getFileArrayBuffer(`${configs.resUrl}reels_track_download/counts.json`, onload);
};

export const compressImage = (videoUrl: string, resultFileName: string) => (dispatch: any, getState: any) => {
    dispatch({
        type: TracksActionType.SET_IS_COMPRESSING_IMAGE,
        isCompressing: true,
    });
    const stateIdTracks = getState().tracks.stateId;
    const timeStampRequest = momentZ().format('x');

    const payload = {
        action: WebsocketAction.START_COMPRESS_IMAGE,
        requestTimeEpoch: Number(timeStampRequest),
        stateId: stateIdTracks,
        mediaS3Url: videoUrl,
        resultFileName
    };
    dispatch({
        type: WEBSOCKET_SEND,
        payload: payload,
    });
};
