import React, { Component } from 'react'
import { ReactComponent as AttentionIcon} from '../../resources/imgs/attention-icon.svg';
import {getStyle} from '../../utils/StyleUtils';

interface Props {
    description: string;
    cancel: () => void;
    onDelete: () => void;
}

interface State {
    isActiveSwitcher: boolean;
}

export class DeleteAttention extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isActiveSwitcher: false
        }
    }

    switch = () => {
        this.setState({isActiveSwitcher: !this.state.isActiveSwitcher})
    }

    renderBody = () => {

        return <div className={"attention-body"}>
            <AttentionIcon/>
            <div className={"reels-attention-text"} style={{marginTop: "12px"}}>
                Are you sure ?
            </div>
            <div className={getStyle("reels-attention-description-text")} style={{marginTop: "25px"}}>
                Your will not be able to recover this <b>{this.props.description}</b>
            </div>
        </div>
    }

    renderFooter = () => {

        return <div className={"attention-footer"}>
            <button className={"reels-second-btn"} onClick={()=>{this.props.cancel()}}>
                Cancel
            </button>

            <button className={"reels-delete-btn"} style={{
                marginLeft: "25px",
            }}
                    onClick={() => {
                        this.props.onDelete()
                    }}
            >
                Yes, delete it!
            </button>
        </div>
    }

    render () {
        return (
            <div className={getStyle("attention-container")}>
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }
}
