import React, { Component } from 'react'
import tb from 'throttle-debounce'
import ReactDOM from 'react-dom';

type Props = {
    value: string,
    debounce?: number,
    handleChange: any,
    excludeCharacters?: string[],
    isFocus?: boolean,
    style?: any
    placeholder?: string,
    isDisabled?: boolean,
    className?: string,
    type?: string | null,
    predicateValue?: (value: string) => boolean
}

type State = {
    inputValue: string
}

export class Input extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {inputValue: this.props.value}
    }

    handleChange = tb.debounce(
        this.props.debounce ? this.props.debounce : 200,
        false,
        () => this.props.handleChange(this.state.inputValue)
    )

    handleChangeInput = (value: string) => {
        const {excludeCharacters = []} = this.props
        excludeCharacters.forEach(excludeCharacter => {
            value = value.replace(excludeCharacter, '');
        })

        if (!this.props.predicateValue || this.props.predicateValue(value)) {
            this.setState({inputValue: value}, () => this.handleChange())
        }
    }

    componentWillUpdate (nextProps: Props, nextState: State, nextContext: any) {
        if(this.props.value !== nextProps.value) {
            this.setState({inputValue: nextProps.value})
        }
    }

    componentDidMount() {
        this.focusDiv();
    }
    focusDiv = () => {
        if(this.props.isFocus){
            setTimeout(() => {
                const node: any = ReactDOM.findDOMNode(this.refs.theDiv)
                if(node && node.focus){
                    node.focus()
                }
            }, 100);
        }
    }

    render () {
        const type = this.props.type ? this.props.type : 'text';
        return (
            <input type={type}
                   value={this.state.inputValue}
                   onChange={(event) => (
                       this.handleChangeInput(event.target.value)
                   )}
                   ref={"theDiv"}
                   style={this.props.style}
                   placeholder={this.props.placeholder ? this.props.placeholder : ""}
                   disabled={this.props.isDisabled}
                   className={this.props.className ? this.props.className : "reels-input"}/>
        )
    }
}
