import React, {forwardRef, HTMLAttributes, useState} from 'react';
import {Collection} from "../../models/Collection";
import {Template} from "../../models/Template";
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-horizontal-icon.svg';
import DropdownWrapper from '../general/DropdownWrapper';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';
import ComponentSettings from '../general/ComponentSettings';
import {ComponentType} from "../../models/Dnd";
import {ModalType} from "../../models/Modals";
import TemplatesList from "../templates/TemplatesList";
import {LibraryFilterType, TemplatesListType} from "../../models/Common";
import {bindActionCreators} from 'redux';
import {
  addTemplateToCollection,
  moveTemplateInCollection,
  removeTemplateFromCollection, replaceTemplateFromCollectionToCollection,
} from '../../actions/templates';
import {connect} from 'react-redux';
import {OrderIndexes} from '../../models/OrderIndexes';

interface StateProps {
  templatesByCollectionsIds: any,
}

interface DispatchProps {
  replaceTemplateFromCollectionToCollection: (
    orderIndexes: OrderIndexes,
    template: Template,
    fromIndex: number,
    toIndex: number,
    fromEntityId: string,
    toEntityId: string
  ) => void,
  addTemplateToCollection: (
    orderIndexes: OrderIndexes,
    template: Template,
    toIndex: number,
    toEntityId: string
  ) => void,
  moveTemplateInCollection: (
    orderIndexes: OrderIndexes,
    template: Template,
    fromIndex: number,
    toIndex: number,
    toEntityId: string
  ) => void,
  removeTemplateFromCollection: (template: Template, entityId: string, relationId?: string) => void,
}

export type CollectionOwnProps = HTMLAttributes<HTMLDivElement> & {
  collection: Collection,
  templates: Template[],
  style: any,
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  selectedFilterType: LibraryFilterType
  isFullView: boolean,
  setIsFullViewCollection: (collectionId: string, isFullView: boolean) => void
}

type Props = StateProps & DispatchProps & CollectionOwnProps

const CollectionView = forwardRef<HTMLDivElement, Props>(({collection, templates, style, showModal, selectedFilterType,
    setIsFullViewCollection, isFullView, removeTemplateFromCollection, moveTemplateInCollection, addTemplateToCollection,
  replaceTemplateFromCollectionToCollection, templatesByCollectionsIds, ...props}, ref) => {
    const [isMouseOver, setMouseOver] = useState(false);
    const renderHeader = () => {
        return <div className={'category-header'}
                    onMouseLeave={() => setMouseOver(false)}
                    onMouseEnter={() => setMouseOver(true)}
        >
            <div className={'drag-dots'}
                 style={{opacity: (isMouseOver ? 1 : 0)}}
                 ref={ref}
                 {...props}
            />

            <div className={'category-header-name'}>
                {collection.name + ` (${templates.length})`}
            </div>
            <DropdownWrapper style={{right: '-100%'}}>
                <DotsHorizontal
                    style={{opacity: (isMouseOver ? 1 : 0)}}
                />
                <ComponentSettings componentType={ComponentType.COLLECTION} componentTypeIn={null} item={collection}/>
            </DropdownWrapper>
            <div className={'hide-arrow' + (isFullView ? '-active' : '')}
                onClick={() => {setIsFullViewCollection(collection.id, !isFullView)}}
            />
            {
              selectedFilterType === LibraryFilterType.BY_COLLECTION ?
                <div style={{marginLeft: 'auto', paddingRight: '11px', display: 'flex', alignItems: 'center'}}>
                    <div className={'reels-add-header-add-container'} onClick={() => {
                        showModal(ModalType.TEMPLATE_ADD, true, {collectionId: collection.id});
                    }}>
                        <PlusIcon style={{width: '12px', height: '12px'}}/>
                        <div className={'reels-add-header-text-add'}>
                            Add
                        </div>
                    </div>
                </div> : null
            }
        </div>
    };

    return (
                <div className={'reels-items-container-none-height'}
                     style={style}
                >
                    {renderHeader()}
                    { <TemplatesList
                        templates={templates}
                        externalId={collection.id}
                        entityContainerId={collection.id}
                        isGridLayout={isFullView}
                        templatesListType={TemplatesListType.TEMPLATES_IN_COLLECTION}
                        removeTemplateFromEntity={removeTemplateFromCollection}
                        moveTemplateInEntity={moveTemplateInCollection}
                    />}
                </div>

    );
});

const mapStateToProps = (state: any) => ({
  templatesByCollectionsIds: state.templates.templatesByCollectionsIds,
});

const mapDispatchToProps = (dispatch: any) => ({
  replaceTemplateFromCollectionToCollection: bindActionCreators(replaceTemplateFromCollectionToCollection, dispatch),
  addTemplateToCollection: bindActionCreators(addTemplateToCollection, dispatch),
  moveTemplateInCollection: bindActionCreators(moveTemplateInCollection, dispatch),
  removeTemplateFromCollection: bindActionCreators(removeTemplateFromCollection, dispatch),
});

export default connect<StateProps, DispatchProps, CollectionOwnProps>(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CollectionView)
