import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {uploadFileToS3Post} from '../../../utils/FilesUtils';
import uuid from 'uuid';
import {DetailsFooter} from '../../general/DetailsFooter';
import {addTrack, updateTrack} from "../../../actions/tracks";
import {DetailsModeType} from '../../../models/Common';
import {TracksByCategoryId} from "../../../reducers/tracks";
import {Version} from "../../../models/Version";
import {configs} from "../../../resources/configs";
import momentZ from 'moment-timezone';
import {Badge} from "../../general/Badge";
import {ReactComponent as ArrowButton} from '../../../resources/imgs/arrow-button-icon.svg';
import {AudioFileInput} from "../../general/AudioFileInput";
import {Checkbox} from '../../general/Checkbox';
import {TrackCategory} from '../../../models/TrackCategory';
import {convertReelsTrackSourceTypeToReadValue, ReelsTrackSourceType, Track} from '../../../models/Track';
import {Input} from '../../general/Input';
import {CheckboxTrackDetails} from './CheckboxTrackDetails';
import DropdownWrapper from '../../general/DropdownWrapper';
import MoveTo from '../../general/MoveTo';

interface StateProps {
  categories: TrackCategory[],
  tracksByCategoriesIds: TracksByCategoryId,
  nextVersion: Version,
  currentVersion: Version,
}

interface DispatchProps {
  addTrack: (track: Track, isUpdateRelations: boolean) => void,
  updateTrack: (Track: Track, isUpdateRelations: boolean) => void,
}

interface OwnProps {
  track: Track,
  mode: DetailsModeType,
  cancel: () => void,
  deleteTrack?: (track: Track) => void,
  meta?: any
}

type Props = StateProps & DispatchProps & OwnProps;

const TrackDetails = ({track, mode, categories, cancel, deleteTrack, tracksByCategoriesIds, addTrack, updateTrack,
  nextVersion, currentVersion, meta}: Props) => {
  const [id, setId] = useState(mode === DetailsModeType.ADD ? uuid.v4() : track.id);
  const [isUpdateRelations, setIsUpdateRelations] = useState(false);
  const [isVisible, setIsVisible] = useState(track.isActive);
  const [isNew, setIsNew] = useState(track.isNew);
  const [isPopular, setIsPopular] = useState(track.isPopular);
  const [isFree, setIsFree] = useState(track.isFree);
  const [artistName, setArtistName] = useState(track.artistName);
  const [isLoadingMp3, setIsLoadingMp3] = useState(false);
  const [mp3Url, setMp3Url] = useState(track.mp3Url);
  const [duration, setDuration] = useState(track.duration);
  const [sourceType, setSourceType] = useState(track.sourceType);

  const [trackName, setTrackName] = useState(track.trackName);
  const [trackToCategories, setTrackToCategories] = useState(mode === DetailsModeType.EDIT ? track.trackToCategories : []);

  useEffect(() => {
    if(meta.categoryId) {
      handleClickCategory(meta.categoryId, true)
    }
  }, [meta.categoryId]);

  const handleFileDrop = (file: any, fileName: string, setIsLoading: (isLoading: boolean) => void, setUrl: (path: string) => void) => {
    if (file) {
      setIsLoading(true);
      uploadFileToS3Post(file, fileName, 'RES_REELS', `tracks/${id}`, (path: string) => {
        setUrl(configs.resUrl + path);
        setIsLoading(false);
      });
    }
  };

  const handleClickCategory = (categoryId: string, isActive: boolean) => {
    const newTrackToCategories = [...trackToCategories];

    const trackToCategory = newTrackToCategories.find((newTrackToCategory) => {
      return newTrackToCategory.categoryId === categoryId;
    });
    if (isActive && !trackToCategory) {
      let order = 0;
      if(tracksByCategoriesIds[categoryId]) {
        tracksByCategoriesIds[categoryId].forEach(track => {
          const trackToCategory = track.trackToCategories.find((trackToCategory) => trackToCategory.categoryId === categoryId);
          if(trackToCategory && trackToCategory.order <= order) {
            order = trackToCategory.order - 1;
          }
        })
      }

      newTrackToCategories.push({
        categoryId,
        trackId: id,
        trackToCategoryId: uuid.v4(),
        order: order
      });
    }

    if (!isActive && trackToCategory) {
      const index = newTrackToCategories.indexOf(trackToCategory);
      newTrackToCategories.splice(index, 1);
    }
    setIsUpdateRelations(true);
    setTrackToCategories(newTrackToCategories);
  };

  const handleSaveTrack = () => {
    const trackForSave = {...track};
    trackForSave.id = id;
    trackForSave.isActive = isVisible;
    trackForSave.isNew = isNew;
    trackForSave.isPopular = isPopular;
    trackForSave.artistName = artistName;
    trackForSave.trackName = trackName;
    trackForSave.mp3Url = mp3Url;
    trackForSave.version = nextVersion.id;
    trackForSave.trackToCategories = trackToCategories;
    trackForSave.isFree = isFree;
    trackForSave.duration = duration;
    trackForSave.sourceType = sourceType;
    trackForSave.updatedAt = new Date();

    mode === DetailsModeType.EDIT ? updateTrack(trackForSave, isUpdateRelations) : addTrack(trackForSave, isUpdateRelations);
    cancel();
  };

  const renderCategories = () => {
    return <div style={{
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'reels-second-text'}>
        Add to Category
      </div>
      <div className={'reels-badge-container'}>
        {categories
            .sort((a, b) => {return a.order - b.order})
            .map((category) => {
              return <Badge
                  key={category.id}
                  onChange={(isActive: boolean)=>{
                    handleClickCategory(category.id, isActive);
                  }}
                  value={category.name}
                  isActive={!!trackToCategories.find((trackToCategory) => {
                    return trackToCategory.categoryId === category.id;
                  })}
              />;
            })}
      </div>
    </div>;
  };


  const renderHeader = () => {
    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <div className={'details-header'} style={{flexDirection: 'column'}}>
        <div className={'reels-head-text'}>
          {mode === DetailsModeType.EDIT ? 'Edit Track' : 'Add Track'}
        </div>
      </div>
      <Checkbox
        key={'visibility-checkbox'}
        switch={setIsVisible}
        isActive={isVisible}
        text={'VISIBILITY'}
        isCircle={true}
      />
    </div>

  };

  const renderSelectSource = () => {
    return <div style={{...{display: 'flex', alignSelf: 'flex-end', flexDirection: 'column', width: '213px'}}}>
      <DropdownWrapper style={{right: '31%'}}>
        <div className={'reels-input-select-container'} style={{marginTop: '7px', resize: 'vertical'}}>
          <div>{convertReelsTrackSourceTypeToReadValue(sourceType)}</div>
          <ArrowButton/>
        </div>

        <MoveTo
          moveTo={(track: Track, source: any) => {
            setSourceType(source);
          }}
          items={Object.values(ReelsTrackSourceType).filter((sourceTypeItem) => {
            return sourceType !== sourceTypeItem;
          })}
          renderProp={convertReelsTrackSourceTypeToReadValue}
          propName={'source'}
          sourceItem={track}
          isClose={true}
          containerClassName={'component-settings-container'}
          isActiveNone={false}
          noneText={'None'}
          onNoneAction={() => {}}
        />
      </DropdownWrapper>
    </div>
  }

  const renderBody = () => {
    return <div className={'details-body'}>
      <CheckboxTrackDetails
        isFree={isFree}
        setIsFree={setIsFree}
        setIsNew={setIsNew}
        setIsPopular={setIsPopular}
        isNew={isNew}
        isPopular={isPopular}
      />
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{marginTop: '8px', width: '100%', display: 'flex', gap: '15px'}}>
            <div style={{width: '210px',  flexDirection: 'column'}}>
              <div className={'reels-second-text'}>
                Track
              </div>
              <AudioFileInput
                value={mp3Url ? decodeURIComponent(mp3Url) : ''}
                handleChange={(file: File, fileName: string) => {
                  const mp3LocalUrl = URL.createObjectURL(file);
                  const audio = new Audio(mp3LocalUrl);
                  audio.addEventListener("loadeddata", () => {
                    let duration = audio.duration;
                    setDuration(duration);
                    handleFileDrop(file, decodeURIComponent(`${nextVersion.id}_${momentZ().tz('UTC').format('x')}_${fileName}`), setIsLoadingMp3, setMp3Url);
                  });
                }
              }
                isLoading={isLoadingMp3}
                style={{width: '210px'}}
                clear={() => {setMp3Url('')}}
                placeholder={'Add Music File'}
              />
            </div>
            {renderSelectSource()}
          </div>
          <div style={{marginTop: '8px', width: '100%', display: 'flex', gap: '15px'}}>
            <Input style={{width: '200px'}}
                   handleChange={(artistName: string) => {setArtistName(artistName)}}
                   placeholder={'Artist name'}
                   value={artistName}
            />
            <Input style={{width: '200px'}}
                   handleChange={(trackName: string) => {setTrackName(trackName)}}
                   placeholder={'Track name'}
                   value={trackName}
            />
          </div>
        </div>
      </div>
      {renderCategories()}
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteTrack}
      saveText={'Save'}
      deleteText={'Delete Track'}
      cancel={cancel}
      entity={track}
      handleSave={handleSaveTrack}
      isDisabled={ !mp3Url || mp3Url == '' || artistName === '' || trackName === ''}
    />
  </div>;
};

const mapStateToProps = (state: any) => ({
  categories: state.categories.trackCategories,
  tracksByCategoriesIds: state.tracks.tracksByCategoriesIds,
  TracksCount: state.tracks.tracks.length,
  nextVersion: state.appState.nextVersion,
  currentVersion: state.appState.version,
});

const mapDispatchToProps = (dispatch: any) => ({
  addTrack: bindActionCreators(addTrack, dispatch),
  updateTrack: bindActionCreators(updateTrack, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackDetails);
