export enum ComponentType {
    TEMPLATE = 'TEMPLATE',
    CATEGORY = 'CATEGORY',
    TRACK_CATEGORY = 'TRACK_CATEGORY',
    TRACK = 'TRACK',
    TAG = 'TAG',
    COLLECTION = 'COLLECTION'
}

export enum DndActionType {
    MOVE = 'MOVE',
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    REMOVE_LOCAL = 'REMOVE_LOCAL',
    REPLACE = 'REPLACE',
    START_DRAGGING = 'START_DRAGGING',
    END_DRAGGING = 'END_DRAGGING',
}

export enum DndDroppableSource {
    ListTemplates = 'ListTemplates',
    ListCategories = 'ListCategories',
    ListCollections = 'ListCollections'
}
