import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MDSpinner from 'react-md-spinner';
import momentZ from 'moment-timezone';
import {toUserTimeZoneDate} from '../../utils/TimeUtils';
import {LibraryFilterType} from "../../models/Common";
import {ModalType} from "../../models/Modals";
import {WebsocketServiceType} from "../../models/Websockets";
import {getStatusPublishFeed, selectFilterType, showModal} from "../../actions/general";

type LibraryProps = {
  selectedFilterType: LibraryFilterType,
  selectFilterType: any,
  getStatusPublishFeed: any,
  version: any,
  isPublishing: boolean,
  showModal: any,
  isBlockPublish: boolean,
  isUpdatingContent: boolean,
  isConnectedWSS: boolean
}

const LibraryHeader = ({selectedFilterType, selectFilterType, getStatusPublishFeed,
  version, isPublishing, showModal, isBlockPublish, isUpdatingContent, isConnectedWSS}: LibraryProps) => {
  const [refreshInterval, setRefreshInterval] = useState(0);

  useEffect(() => {
    if (isConnectedWSS) {
      getStatusPublishFeed();
      setTimeout(function() {
        setRefreshInterval(refreshInterval + 1);
      }, 2400000);
    }
  }, [refreshInterval, isConnectedWSS]);

  const renderFilterSwitcher = () => {
    return <div className={'content-types-container'}>
      {Object.values(LibraryFilterType)
          .map((filterType, index) => {
            return <button
                key={filterType}
                className={'content-type-' + (selectedFilterType === filterType ? 'selected' : 'unselected')}
                onClick={() => {
                  selectFilterType(filterType);
                }}
                style={{marginLeft: index === 0 ? '0px' : '12px'}}
            >
              {filterType}
            </button>;
          })}
    </div>;
  };

  const renderPublish = () => {
    return <div className={'publish-container'}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'reels-second-text'}>
          {version ? 'Published ' + toUserTimeZoneDate(momentZ(version.publishDate)).format('DD.MM.YYYY HH:mm') : ''}
        </div>
        <div className={'reels-second-text'}>
          {version ? 'Publisher ' + version.publisher : ''}
        </div>
      </div>
      <div style={{width: '100px', paddingLeft: '14px'}}>
        {
                    isPublishing ?
                    <div className='spinner-container' >
                      <MDSpinner size={30} singleColor={'#81E727'}/>
                    </div> :
                    <button className={'publish-btn'}
                      disabled={isBlockPublish || isUpdatingContent}
                      onClick={()=>{
                        showModal(ModalType.PUBLISH, true);
                      }}
                    >
                      {isUpdatingContent ? 'Uploading' : 'Publish'}
                    </button>
        }
      </div>
    </div>;
  };

  return (
    <div className={'library-header'}>
      {renderFilterSwitcher()}
      {renderPublish()}
    </div>
  );
};


const mapStateToProps = (state: any) => ({
  contentTypes: state.appState.contentTypes,
  selectedFilterType: state.appState.selectedFilterType,
  version: state.appState.version,
  isPublishing: state.appState.isPublishing,
  isBlockPublish: state.appState.isBlockPublish,
  isUpdatingContent: state.appState.isUpdatingContent,
  isConnectedWSS: state.appState.isConnectedWSS[WebsocketServiceType.REELS_MAKER],
});

const mapDispatchToProps = (dispatch: any) => ({
  selectFilterType: bindActionCreators(selectFilterType, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  getStatusPublishFeed: bindActionCreators(getStatusPublishFeed, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LibraryHeader);
