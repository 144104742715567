export enum PlatformType {
    INSTAGRAM = 'Instagram',
    TIKTOK = 'TikTok',
    YOUTUBE = 'YouTube'
}

export enum LibraryFilterType {
    ALL_REELS = 'ALL REELS',
    BY_CATEGORY = 'BY CATEGORY',
    BY_COLLECTION = 'BY COLLECTION',
    ORPHANS = 'СИРОТКИ'
}

export enum TemplatesListType {
    ONLY_TEMPLATES = 'ONLY_TEMPLATES',
    TEMPLATES_IN_CATEGORY = 'TEMPLATES_IN_CATEGORY',
    TEMPLATES_IN_COLLECTION = 'TEMPLATES_IN_COLLECTION'
}

export enum DetailsModeType {
    EDIT = 'EDIT',
    ADD = 'ADD'
}

export enum StatusPublishFeed {
    PUBLISHING = 'PUBLISHING',
    PUBLISHED = 'PUBLISHED',
}

export const reservedCategoriesIds = ['free', 'most_popular', 'hot_trends', 'new', 'universal_templates'];

export interface ForceLogoutParams {
    version: number,
}
