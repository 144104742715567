import uuid from 'uuid';
import {CategoriesActionType} from "../actions/categories";
import {Category} from "../models/Category";
import {TemplatesActionType} from "../actions/templates";
import {Template} from "../models/Template";
import {TemplateToCategory} from "../models/TemplateToCategory";
import {TracksActionType} from '../actions/tracks';
import {TrackCategory} from '../models/TrackCategory';
import {Track} from '../models/Track';
import {TrackToTrackCategory} from '../models/TrackToTrackCategory';

export type IsFullViewCategory = {[id: string]: boolean}

const getInitialState = () => {
    return {
        stateId: uuid.v4(),
        categories: [],
        isLoadingCategories: false,
        selectedCategory: {id: -1},
        timeStampSaveCategories: 0,
        timeStampAddCategory: 0,
        isFullViewCategories: {},
        trackCategories: [],
        isLoadingTrackCategories: false,
        selectedTrackCategory: null,
        timeStampSaveTrackCategories: 0,
        timeStampAddTrackCategory: 0,
    };
};

export default function categories(state = getInitialState(), action: any) {
    switch (action.type) {
        case CategoriesActionType.SAVE_CATEGORIES:
            const timeStampSaveCategories = action.requestTimeEpoch;
            const stateIdRequestedSaveCategories = action.stateId;
            if (
                timeStampSaveCategories > state.timeStampSaveCategories &&
                stateIdRequestedSaveCategories === state.stateId
            ) {
                return Object.assign({}, state, {
                    categories: action.categories,
                    isLoadingCategories: false,
                    timeStampSaveCategories: timeStampSaveCategories,
                });
            }
            return state;


        case CategoriesActionType.SELECT_CATEGORY:
            return Object.assign({}, state, {
                selectedCategory: action.category,
            });

        case CategoriesActionType.SET_IS_FULL_VIEW_CATEGORY:
            return Object.assign({}, state, {
                isFullViewCategories: {...state.isFullViewCategories, ...{[action.categoryId]: action.isFullView}},
            });

        case CategoriesActionType.IS_LOADING_CATEGORIES:
            return Object.assign({}, state, {
                isLoadingCategories: action.isLoading,
            });

        case CategoriesActionType.UPDATE_CATEGORY:
            return Object.assign({}, state, {
                categories: state.categories.map((category: Category) => {
                        if (category.id === action.category.id) {
                            return action.category;
                        }
                        if (action.category.isFeatured) {
                            return {...category, ...{isFeatured: false}}
                        }
                        return category;
                }).sort((a, b) => (a.order - b.order)),
            });

        case TemplatesActionType.UPDATE_TEMPLATE_IN_CATEGORY:
            return Object.assign({}, state, {
                categories: state.categories.map((category: Category) => {
                    const template: Template = action.template;
                    const isContainCategory = template.templateToCategories.find((templateToCategory) => {
                        return templateToCategory.categoryId === category.id;
                    });
                    if (isContainCategory) {
                        const isCategoryContainTemplate = category.templatesToCategory.find((templateToCategory) => {
                            return templateToCategory.templateId === action.template.id;
                        });
                        if (!isCategoryContainTemplate) {
                            const newCategory: Category = {...category};
                            newCategory.templatesToCategory.push({
                                categoryId: category.id,
                                templateId: action.template.id,
                                order: isContainCategory.order,
                                templateToCategoryId: isContainCategory.templateToCategoryId,
                            });
                            return newCategory;
                        }
                    } else {
                        const isCategoryContainTemplate = category.templatesToCategory.find((templateToCategory) => {
                            return templateToCategory.templateId === action.template.id;
                        });
                        if (isCategoryContainTemplate) {
                            const newCategory = {...category};
                            newCategory.templatesToCategory = newCategory.templatesToCategory.filter((templateToCategory) => {
                                return templateToCategory.templateId !== action.template.id;
                            });
                            return newCategory;
                        }
                    }

                    return category;
                }),
            });

        case CategoriesActionType.DELETE_CATEGORY:
            return Object.assign({}, state, {
                categories: state.categories.filter((category: Category) => {
                    return category.id !== action.categoryId;
                }),
            });

        case TemplatesActionType.ADD_TEMPLATE || TemplatesActionType.UPDATE_TEMPLATE:
            const copyCategories = [...state.categories];
            const templateForAdd: Template = action.template;
            copyCategories.forEach((category: Category) => {
                const isAddToCategory = templateForAdd.templateToCategories.find((templateToCategory) => templateToCategory.categoryId === category.id);
                let isExistInCategory = false;
                if(!isAddToCategory) {
                    category.templatesToCategory = category.templatesToCategory
                        .filter(templateToCategory => {
                            if (!isExistInCategory && templateToCategory.templateId === templateForAdd.id) {
                                isExistInCategory = true;
                            }
                            return templateToCategory.templateId !== templateForAdd.id;
                        })
                }
                if(isAddToCategory && !isExistInCategory) {
                    const newTemplateToCategory: TemplateToCategory | undefined = templateForAdd.templateToCategories
                        .find(templateToCategory => templateToCategory.categoryId === category.id);
                    if(!newTemplateToCategory) {
                        throw new Error('TemplateToCategory not exists');
                    }
                    category.templatesToCategory.push(newTemplateToCategory);
                    category.templatesToCategory = category.templatesToCategory.sort((a, b) => a.order - b.order);
                }
            })
            return Object.assign({}, state, {
                categories: state.categories.filter((category: Category) => {
                    return category.id !== action.categoryId;
                }),
            });

        case CategoriesActionType.ADD_CATEGORY:
            return Object.assign({}, state, {
                categories: [...state.categories, ...[action.category]].sort((a: Category, b: Category) => a.order - b.order),
            });

        case TemplatesActionType.REMOVE_TEMPLATE_FROM_CATEGORIES:
            return Object.assign({}, state, {
                categories: state.categories.map((category: Category) => {
                    const newCategory = {...category};
                    newCategory.templatesToCategory = newCategory.templatesToCategory.filter((templateToCategory) => {
                        return templateToCategory.templateId !== action.templateId;
                    });

                    return newCategory;
                }),
            });

        case CategoriesActionType.UPDATE_ORDERS_INDEXES_CATEGORIES:
            const categoriesWithNewOrderIndexes = state.categories.map((category: Category) => {
                const orderIndex = action.orderIndexes.find((orderIndex: any) => (orderIndex.entityId === category.id));

                if (orderIndex) {
                    const newCategory = {...category};
                    newCategory.order = orderIndex.orderIndex;
                    return newCategory;
                }

                return category;
            }).sort((a, b) => (a.order - b.order));

            return Object.assign({}, state, {
                categories: categoriesWithNewOrderIndexes,
            });

        case TemplatesActionType.UPDATE_RELATION_TEMPLATE_TO_CATEGORY:
            const categoriesUpdatedRelation = state.categories
                .map((category: Category) => {
                    const newCategory = {...category};
                    if (category.id === action.newRelation.toEntityId) {
                        newCategory.templatesToCategory.push({
                            templateToCategoryId: action.newRelation.templateToCategoryId,
                            templateId: action.newRelation.fromEntityId,
                            categoryId: category.id,
                            order: action.newRelation.orderIndex,
                        });
                    }

                    if (category.id === action.fromCategoryId) {
                        newCategory.templatesToCategory = newCategory.templatesToCategory.filter((templateToCategory) => {
                            return templateToCategory.templateId !== action.newRelation.fromEntityId;
                        });
                    }

                    return newCategory;
                });

            return Object.assign({}, state, {
                categories: categoriesUpdatedRelation,
            });

        case CategoriesActionType.SAVE_TRACK_CATEGORIES:
            const timeStampSaveTrackCategories = action.requestTimeEpoch;
            const stateIdRequestedSaveTrackCategories = action.stateId;
            if (
              timeStampSaveTrackCategories > state.timeStampSaveTrackCategories &&
              stateIdRequestedSaveTrackCategories === state.stateId
            ) {
                return Object.assign({}, state, {
                    trackCategories: action.categories,
                    isLoadingTrackCategories: false,
                    timeStampSaveTrackCategories: timeStampSaveTrackCategories,
                });
            }
            return state;


        case CategoriesActionType.SELECT_TRACK_CATEGORY:
            return Object.assign({}, state, {
                selectedTrackCategory: action.category,
            });

        case CategoriesActionType.IS_LOADING_TRACK_CATEGORIES:
            return Object.assign({}, state, {
                isLoadingTrackCategories: action.isLoading,
            });

        case CategoriesActionType.UPDATE_TRACK_CATEGORY:
            return Object.assign({}, state, {
                trackCategories: state.trackCategories.map((category: Category) => {
                    if (category.id === action.category.id) {
                        return action.category;
                    }
                    return category;
                }).sort((a, b) => (a.order - b.order)),
            });

        case TracksActionType.UPDATE_TRACK_IN_TRACK_CATEGORY:
            return Object.assign({}, state, {
                categories: state.trackCategories.map((category: TrackCategory) => {
                    const track: Track = action.track;
                    const isContainCategory = track.trackToCategories.find((trackToCategory) => {
                        return trackToCategory.categoryId === category.id;
                    });
                    if (isContainCategory) {
                        const isCategoryContainTrack = category.tracksToCategory.find((trackToCategory) => {
                            return trackToCategory.trackId === action.track.id;
                        });
                        if (!isCategoryContainTrack) {
                            const newCategory: TrackCategory = {...category};
                            newCategory.tracksToCategory.push({
                                categoryId: category.id,
                                trackId: action.track.id,
                                order: isContainCategory.order,
                                trackToCategoryId: isContainCategory.trackToCategoryId,
                            });
                            return newCategory;
                        }
                    } else {
                        const isCategoryContainTrack = category.tracksToCategory.find((trackToCategory) => {
                            return trackToCategory.trackId === action.track.id;
                        });
                        if (isCategoryContainTrack) {
                            const newCategory = {...category};
                            newCategory.tracksToCategory = newCategory.tracksToCategory.filter((trackToCategory) => {
                                return trackToCategory.trackId !== action.track.id;
                            });
                            return newCategory;
                        }
                    }

                    return category;
                }),
            });

        case CategoriesActionType.DELETE_TRACK_CATEGORY: {
            let selectedTrackCategory = state.selectedTrackCategory;
            if(selectedTrackCategory && selectedTrackCategory['id'] === action.categoryId) {
                selectedTrackCategory = null;
            }
            return Object.assign({}, state, {
                selectedTrackCategory,
                trackCategories: state.trackCategories.filter((category: Category) => {
                    return category.id !== action.categoryId;
                }),
            });
        }


        case TracksActionType.ADD_TRACK || TracksActionType.UPDATE_TRACK: {
            const copyTrackCategories = [...state.trackCategories];
            const trackForAdd: Track = action.track;
            copyTrackCategories.forEach((category: TrackCategory) => {
                const isAddToCategory = trackForAdd.trackToCategories.find((tackToCategory) => tackToCategory.categoryId === category.id);
                let isExistInCategory = false;
                if(!isAddToCategory) {
                    category.tracksToCategory = category.tracksToCategory
                      .filter(trackToCategory => {
                          if (!isExistInCategory && trackToCategory.trackId === trackForAdd.id) {
                              isExistInCategory = true;
                          }
                          return trackToCategory.trackId !== trackForAdd.id;
                      })
                }
                if(isAddToCategory && !isExistInCategory) {
                    const newTrackToCategory: TrackToTrackCategory | undefined = trackForAdd.trackToCategories
                      .find(trackToCategory => trackToCategory.categoryId === category.id);
                    if(!newTrackToCategory) {
                        throw new Error('TrackToCategory not exists');
                    }
                    category.tracksToCategory.push(newTrackToCategory);
                    category.tracksToCategory = category.tracksToCategory.sort((a, b) => a.order - b.order);
                }
            })
            return Object.assign({}, state, {
                trackCategories: state.trackCategories.filter((category: TrackCategory) => {
                    return category.id !== action.categoryId;
                }),
            });
        }

        case CategoriesActionType.ADD_TRACK_CATEGORY:
            return Object.assign({}, state, {
                trackCategories: [...state.trackCategories, ...[action.category]].sort((a: TrackCategory, b: TrackCategory) => a.order - b.order),
            });

        case TracksActionType.REMOVE_TRACK_FROM_CATEGORIES:
            return Object.assign({}, state, {
                trackCategories: state.trackCategories.map((category: TrackCategory) => {
                    const newCategory = {...category};
                    newCategory.tracksToCategory = newCategory.tracksToCategory.filter((trackToCategory) => {
                        return trackToCategory.trackId !== action.trackId;
                    });

                    return newCategory;
                }),
            });

        case CategoriesActionType.UPDATE_ORDERS_INDEXES_TRACK_CATEGORIES: {
            const categoriesWithNewOrderIndexes = state.trackCategories.map((category: TrackCategory) => {
                const orderIndex = action.orderIndexes.find((orderIndex: any) => (orderIndex.entityId === category.id));

                if (orderIndex) {
                    const newCategory = {...category};
                    newCategory.order = orderIndex.orderIndex;
                    return newCategory;
                }

                return category;
            }).sort((a, b) => (a.order - b.order));

            return Object.assign({}, state, {
                trackCategories: categoriesWithNewOrderIndexes,
            });
        }

        case TracksActionType.UPDATE_RELATION_TRACK_TO_CATEGORY: {
            const categoriesUpdatedRelation = state.trackCategories
              .map((category: TrackCategory) => {
                  const newCategory = {...category};
                  if (category.id === action.newRelation.toEntityId) {
                      newCategory.tracksToCategory.push({
                          trackToCategoryId: action.newRelation.trackToCategoryId,
                          trackId: action.newRelation.fromEntityId,
                          categoryId: category.id,
                          order: action.newRelation.orderIndex,
                      });
                  }

                  if (category.id === action.fromCategoryId) {
                      newCategory.tracksToCategory = newCategory.tracksToCategory.filter((trackToCategory) => {
                          return trackToCategory.trackId !== action.newRelation.fromEntityId;
                      });
                  }

                  return newCategory;
              });

            return Object.assign({}, state, {
                trackCategories: categoriesUpdatedRelation,
            });
        }

        default: return state;
    }
}
