import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CollectionSimpleView, {CollectionSimpleOwnProps} from './CollectionSimpleView';

const SortableCollectionSimpleView: FC<CollectionSimpleOwnProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        isDragging,
        transform,
        transition
    } = useSortable({ id: props.collection.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        cursor: isDragging ? 'grabbing' : 'grab',
    };
    return (
      <CollectionSimpleView
        ref={setNodeRef}
        {...props}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
};

export default SortableCollectionSimpleView;
