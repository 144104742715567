import uuid from 'uuid';
import {TAGS_ORDER_TYPES, TagsActionTypes} from "../actions/tags";
import {Tag} from "../models/Tag";
import {Template} from "../models/Template";
import {TemplatesActionType} from "../actions/templates";

const getInitialState = () => {
    return {
        stateId: uuid.v4(),
        tags: [],
        sortedTags: [],
        isLoadingTags: false,
        timeStampSaveTags: 0,
        timeStampAddTag: 0,
        orderTagsParams: {
            orderBy: TAGS_ORDER_TYPES.NAME,
            orderIndex: 'ASC',
        }
    };
};

export default function tags(state = getInitialState(), action: any) {
    switch (action.type) {
        case TagsActionTypes.SAVE_TAGS: {
            const timeStampSaveTags = action.requestTimeEpoch;
            const stateIdRequestedSaveTags = action.stateId;

            if (
                timeStampSaveTags > state.timeStampSaveTags &&
                stateIdRequestedSaveTags === state.stateId
            ) {
                const sortedEntities = sortTags(
                    action.tags,
                    action.templates,
                    state.orderTagsParams,
                );
                return Object.assign({}, state, {
                    tags: action.tags.sort((a: Tag, b: Tag) => {
                        return a.order - b.order;
                    }),
                    isLoadingTags: false,
                    timeStampSaveTags: timeStampSaveTags,
                    sortedTags: sortedEntities,
                });
            }
            return state;
        }

        case TagsActionTypes.ORDER_TAGS:
            const sortedEntities = sortTags(
                state.tags,
                action.templates,
                action.orderParams,
            );
            return Object.assign({}, state, {
                orderTagsParams: action.orderParams,
                sortedTags: sortedEntities,
            });

        case TagsActionTypes.DELETE_TAG:
            return Object.assign({}, state, {
                tags: state.tags.filter((tag: Tag) => {
                    return tag.id !== action.tagId;
                }),
                sortedTags: state.sortedTags.filter((tag: Tag) => {
                    return tag.id !== action.tagId;
                }),
            });

        case TagsActionTypes.UPDATE_TAG:
            return Object.assign({}, state, {
                tags: state.tags.map((tag: Tag) => {
                    if (tag.id === action.tag.id) {
                        return action.tag;
                    }
                    return tag;
                }),
                sortedTags: state.sortedTags.map((tag: Tag) => {
                    if (tag.id === action.tag.id) {
                        return action.tag;
                    }
                    return tag;
                }),
            });

        case TagsActionTypes.ADD_TAG: {
            const tags: Tag[] = [...state.tags];
            tags.push(action.tag);

            return Object.assign({}, state, {
                tags: tags,
                sortedTags: sortTags(
                    tags,
                    action.templates,
                    state.orderTagsParams
                ),
            });
        }

        case TagsActionTypes.IS_LOADING_TAGS:
            return Object.assign({}, state, {
                isLoadingTags: action.isLoading,
            });

        case TagsActionTypes.MOVE_TAGS:
            const movedTags = state.tags.map((tag: Tag) => {
                const orderIndex = action.orderIndexes.find((orderIndex: any) => {
                    return orderIndex.entityId === tag.id;
                });
                if (orderIndex) {
                    tag.order = orderIndex.orderIndex;
                }
                return tag;
            }).sort((tagA, tagB) => tagA.order - tagB.order);

            return Object.assign({}, state, {
                tags: movedTags,
            });

        case TemplatesActionType.DELETE_TEMPLATE:
            const tagsWithoutTemplateId = state.tags.map((tag: Tag) => {
                const newTag = {...tag};
                newTag.templatesIds = newTag.templatesIds.filter((templateId) => {
                    return templateId !== action.templateId;
                });
                return newTag;
            });
            return Object.assign({}, state, {
                tags: tagsWithoutTemplateId,
                sortedTags: sortTags(
                    tagsWithoutTemplateId,
                    action.templates,
                    state.orderTagsParams,
                ),
            });

        case TagsActionTypes.UPDATE_TEMPLATE_TAGS: {
            const addedTagIds: string[] = [];
            const removedTagIds: string[] = [];
            action.oldTemplate.tagIds.forEach((oldTagId: string) => {
                if (action.newTemplate.tagIds.indexOf(oldTagId) === -1) {
                    removedTagIds.push(oldTagId);
                }
            });
            action.newTemplate.tagIds.forEach((oldTagId: string) => {
                if (action.oldTemplate.tagIds.indexOf(oldTagId) === -1) {
                    addedTagIds.push(oldTagId);
                }
            });

            const updatedTags = [...state.tags];
            updatedTags.forEach((tag: Tag) => {
                if (addedTagIds.indexOf(tag.id) !== -1) {
                    tag.templatesIds.push(action.newTemplate.id);
                }
                if (removedTagIds.indexOf(tag.id) !== -1) {
                    tag.templatesIds = tag.templatesIds.filter((templateId) => {
                        return templateId !== action.newTemplate.id;
                    });
                }
            });
            return Object.assign({}, state, {
                tags: updatedTags,
                sortedTags: sortTags(
                    updatedTags,
                    action.templates,
                    state.orderTagsParams,
                ),
            });
        }

        default: return state;
    }
}

const sortTags = (tags: Tag[], templates: Template[], orderParams: any) => {
    const copyTags: Tag[] = JSON.parse(JSON.stringify(tags));

    return copyTags.sort((tagA, tagB) => {
        let a: string | number = 0;
        let b: string | number = 0;
        if (orderParams.orderBy === TAGS_ORDER_TYPES.NAME) {
            a = tagA.name.toLowerCase();
            b = tagB.name.toLowerCase();
        }
        if (orderParams.orderBy === TAGS_ORDER_TYPES.COUNT_TEMPLATES) {
            a = tagA.templatesIds.length;
            b = tagB.templatesIds.length;
            return orderParams.orderIndex === 'ASC' ? a - b : b - a;
        }
        if (orderParams.orderBy === TAGS_ORDER_TYPES.IS_VISIBLE) {
            return orderParams.orderIndex === 'ASC' ? (tagA.isActive ? -1 : 1) : (tagB.isActive ? -1 : 1);
        }
        if (orderParams.orderBy === TAGS_ORDER_TYPES.IS_IMAGE) {
            return orderParams.orderIndex === 'ASC' ? (tagA.imageUrl ? -1 : 1) : (tagB.imageUrl ? -1 : 1);
        }
        if (a < b) {
            return orderParams.orderIndex === 'ASC' ? -1 : 1;
        }
        if (a > b) {
            return orderParams.orderIndex === 'DESC' ? -1 : 1;
        }
        return 0;
    });
};
