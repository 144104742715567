import React, {CSSProperties, forwardRef, HTMLAttributes, useState} from 'react';
import {Category} from "../../models/Category";
import {Template} from "../../models/Template";
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-horizontal-icon.svg';
import DropdownWrapper from '../general/DropdownWrapper';
import {ReactComponent as PlusIcon} from '../../resources/imgs/plus-icon.svg';
import {ReactComponent as StarIcon} from '../../resources/imgs/star-icon.svg';
import ComponentSettings from '../general/ComponentSettings';
import {ComponentType} from "../../models/Dnd";
import {ModalType} from "../../models/Modals";
import TemplatesList from "../templates/TemplatesList";
import {LibraryFilterType, TemplatesListType} from "../../models/Common";
import {bindActionCreators} from 'redux';
import {addTemplateToCategory, moveTemplateInCategory, removeTemplateFromCategory, replaceTemplateFromCategoryToCategory} from '../../actions/templates';
import {connect} from 'react-redux';
import {OrderIndexes} from '../../models/OrderIndexes';

interface StateProps {
    templatesByCategoriesIds: any,
}

interface DispatchProps {
    replaceTemplateFromCategoryToCategory: (
      orderIndexes: OrderIndexes,
      template: Template,
      fromIndex: number,
      toIndex: number,
      fromEntityId: string,
      toEntityId: string
    ) => void,
    addTemplateToCategory: (
      orderIndexes: OrderIndexes,
      template: Template,
      toIndex: number,
      toEntityId: string
    ) => void,
    moveTemplateInCategory: (
      orderIndexes: OrderIndexes,
      template: Template,
      fromIndex: number,
      toIndex: number,
      toEntityId: string
    ) => void,
    removeTemplateFromCategory: (template: Template, entityId: string, relationId?: string) => void,
}

export type CategoryOwnProps = HTMLAttributes<HTMLDivElement> & {
    category: Category,
    templates: Template[],
    arrayIndex: number,
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
    selectedFilterType: LibraryFilterType
    isFullView: boolean,
    setIsFullViewCategory: (categoryId: string, isFullView: boolean) => void,
};

type Props = StateProps & DispatchProps & CategoryOwnProps

const CategoryView = forwardRef<HTMLDivElement, Props>(({category, templates, showModal, selectedFilterType,
    setIsFullViewCategory, isFullView, arrayIndex,removeTemplateFromCategory, style,
    moveTemplateInCategory, addTemplateToCategory, replaceTemplateFromCategoryToCategory, templatesByCategoriesIds,  ...props
                      }, ref) => {
    const [isMouseOver, setMouseOver] = useState(false);

    const renderHeader = () => {
        return <div className={'category-header'}
                    onMouseLeave={() => setMouseOver(false)}
                    onMouseEnter={() => setMouseOver(true)}
        >
            <div className={'drag-dots'}
                 ref={ref}
                 {...props}
                 style={{opacity: (isMouseOver ? 1 : 0)}}/>

            <div className={'category-header-name'}>
                {category.isFeatured ? <StarIcon/> : null}
                {category.name + ` (${templates.length})`}
            </div>
            <DropdownWrapper style={{right: '-100%'}}>
                <DotsHorizontal
                    style={{opacity: (isMouseOver ? 1 : 0)}}
                />
                <ComponentSettings componentType={ComponentType.CATEGORY} componentTypeIn={null} item={category}/>
            </DropdownWrapper>
            <div className={'reels-hide-arrow' + (isFullView ? '-active' : '')}
                onClick={() => {setIsFullViewCategory(category.id, !isFullView)}}
            />
            {
              selectedFilterType === LibraryFilterType.BY_CATEGORY ?
                <div style={{marginLeft: 'auto', paddingRight: '11px', display: 'flex', alignItems: 'center'}}>
                    <div className={'reels-add-header-add-container'} onClick={() => {
                        showModal(ModalType.TEMPLATE_ADD, true, {categoryId: category.id});
                    }}>
                        <PlusIcon style={{width: '12px', height: '12px'}}/>
                        <div className={'reels-add-header-text-add'}>
                            Add
                        </div>
                    </div>
                </div> : null
            }
        </div>
    };
    return (<div className={'reels-items-container-none-height'}
                     style={style}
                >
                    {renderHeader()}
                    { <TemplatesList
                      templates={templates}
                      externalId={category.id}
                      entityContainerId={category.id}
                      isGridLayout={isFullView}
                      templatesListType={TemplatesListType.TEMPLATES_IN_CATEGORY}
                      removeTemplateFromEntity={removeTemplateFromCategory}
                      moveTemplateInEntity={moveTemplateInCategory}
                    />}
                </div>
    );
});

const mapStateToProps = (state: any) => ({
    templatesByCategoriesIds: state.templates.templatesByCategoriesIds,
});

const mapDispatchToProps = (dispatch: any) => ({
    replaceTemplateFromCategoryToCategory: bindActionCreators(replaceTemplateFromCategoryToCategory, dispatch),
    addTemplateToCategory: bindActionCreators(addTemplateToCategory, dispatch),
    moveTemplateInCategory: bindActionCreators(moveTemplateInCategory, dispatch),
    removeTemplateFromCategory: bindActionCreators(removeTemplateFromCategory, dispatch),
});

export default connect<StateProps, DispatchProps, CategoryOwnProps>(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CategoryView)
