import React from 'react';
import LibraryHeader from './LibraryHeader';
import CategoriesSection from '../categories/CategoriesSection';
import {connect} from 'react-redux';
import {LibraryFilterType, TemplatesListType} from '../../models/Common';
import CollectionsSection from '../collections/CollectionsSection';
import TemplatesList from '../templates/TemplatesList';
import { Template } from '../../models/Template';
import {OrderIndexes} from '../../models/OrderIndexes';

type LibraryProps = {
  selectedFilterType: LibraryFilterType,
  useRegisterDroppable: any,
  orphansTemplates: Template[]
}
const Library = ({useRegisterDroppable, orphansTemplates, selectedFilterType}: LibraryProps) => {
  return (
    <div className={'library-container'}>
      <LibraryHeader/>
      <div className={'library-body'}>
          <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
            {selectedFilterType === LibraryFilterType.BY_COLLECTION ? <CollectionsSection/> : null}
            {selectedFilterType === LibraryFilterType.BY_CATEGORY ? <CategoriesSection/> : null}
            {selectedFilterType === LibraryFilterType.ALL_REELS ?
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <CollectionsSection/>
              <CategoriesSection/>
            </div>
              : null}
            {selectedFilterType === LibraryFilterType.ORPHANS ? <TemplatesList
              templates={orphansTemplates}
              isGridLayout={true}
              isDropDisabled={true}
              templatesListType={TemplatesListType.ONLY_TEMPLATES} removeTemplateFromEntity={function(template: Template, entityId: string, relationId?: string | undefined): void {
              throw new Error('Function not implemented.');
            }}            /> : null}

          </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  selectedFilterType: state.appState.selectedFilterType,
  orphansTemplates: state.templates.templates.filter((template: Template) => {
    return template.templateToCollections.length === 0 && template.templateToCategories.length === 0;
  })
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Library);
