export enum ModalType {
    ERROR = 'ERROR',
    PUBLISH = 'PUBLISH',
    CATEGORY_ADD = 'CATEGORY_ADD',
    CATEGORY_EDIT = 'CATEGORY_EDIT',
    CATEGORY_DELETE = 'CATEGORY_DELETE',
    TRACK_CATEGORY_ADD = 'TRACK_CATEGORY_ADD',
    TRACK_CATEGORY_EDIT = 'TRACK_CATEGORY_EDIT',
    TRACK_CATEGORY_DELETE = 'TRACK_CATEGORY_DELETE',
    TRACK_ADD = 'TRACK_ADD',
    TRACK_EDIT = 'TRACK_EDIT',
    TRACK_DELETE = 'TRACK_DELETE',
    TEMPLATE_ADD = 'TEMPLATE_ADD',
    TEMPLATE_EDIT = 'TEMPLATE_EDIT',
    TEMPLATE_DELETE = 'TEMPLATE_DELETE',
    COLLECTION_ADD = 'COLLECTION_ADD',
    COLLECTION_EDIT = 'COLLECTION_EDIT',
    COLLECTION_DELETE = 'COLLECTION_DELETE',
    TAG_ADD = 'TAG_ADD',
    TAG_EDIT = 'TAG_EDIT',
    TAG_DELETE = 'TAG_DELETE',
}
