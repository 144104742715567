import React, {useCallback, useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import {spinnerColor} from '../../../utils/StyleUtils';
import {ModalType} from '../../../models/Modals';

import {closestCenter, DndContext, DragEndEvent, DragStartEvent, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {TrackCategory} from '../../../models/TrackCategory';
import SortableTrackCategoryView from './SortableTrackCategoryView';

type CategoriesList = {
    categories: TrackCategory[],
    isLoadingCategories: boolean,
    tracksByCategoriesIds: any,
    updateCategoriesOrderIndexes: any,
    selectedTrackCategory: TrackCategory
    selectTrackCategory: (trackCategory: TrackCategory) => void
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
}

export const TrackCategoriesList = ({categories, isLoadingCategories, showModal, tracksByCategoriesIds,
    updateCategoriesOrderIndexes, selectTrackCategory, selectedTrackCategory}: CategoriesList) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [localCategories, setCategories] = useState(categories);

    useEffect(() => {
        setCategories(categories);
    }, [categories]);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            setCategories((localCategories) => {
                const oldCategory = findCategory(active.id, localCategories);
                const newCategory = findCategory(over!.id, localCategories);
                const newLocalCategories = arrayMove(localCategories, oldCategory.index, newCategory.index);

                updateCategoriesOrderIndexes(
                  newLocalCategories.map((category, index) => {
                      return {
                          entityId: category.id,
                          orderIndex: index + 1,
                      };
                  }),
                );

                return arrayMove(localCategories, oldCategory.index, newCategory.index);
            });
        }
    }, []);

    if (isLoadingCategories) {
        return (
            <div></div>
        );
    }

    const findCategory = (id: string | number, categories: TrackCategory[] ) => {
        const category = categories.filter((c) => {
            return c.id === id;
        })[0];
        return {
            category,
            index: categories.indexOf(category),
        };
    };

    return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
            <SortableContext items={localCategories} strategy={verticalListSortingStrategy}>
                    {localCategories.map((category, index) => (
                      <SortableTrackCategoryView
                        key={category.id}
                        category={category}
                        selectTrackCategory={selectTrackCategory}
                        selectedTrackCategory={selectedTrackCategory}
                        arrayIndex={index}
                        showModal={showModal}
                        tracksCount={tracksByCategoriesIds[category.id] ? tracksByCategoriesIds[category.id].length : 0}
                      />
                    ))}
            </SortableContext>
        </DndContext>
    );
};
