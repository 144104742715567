import React from 'react';
import {getStyle} from '../../utils/StyleUtils';
import {isMobileOnly} from 'react-device-detect';
import {DetailsModeType} from "../../models/Common";

interface Props {
  mode: DetailsModeType,
  deleteEntity?: (entity: any) => void,
  handleSave: () => void,
  entity: any,
  cancel: () => void,
  isDisabled: boolean,
  saveText?: string,
  deleteText?: string
}


export const DetailsFooter = ({mode, deleteEntity, handleSave, entity, cancel, isDisabled, deleteText = 'Delete', saveText = 'Save'}: Props) => {
  if (isMobileOnly) {
    return <div className={getStyle('details-footer')}>
      <button className={'save-btn-footer'}
        onClick={handleSave}
        disabled={isDisabled}
      >
        {mode === DetailsModeType.EDIT ? saveText : 'Add'}
      </button>
    </div>;
  }

  return <div className={getStyle('details-footer')}>
    <button className={mode === DetailsModeType.EDIT ? 'reels-second-delete-btn' : 'reels-second-btn'}
      style={{marginRight: (mode === DetailsModeType.EDIT ? 'auto' : undefined)}}
      onClick={() => {
        if (mode === DetailsModeType.EDIT) {
          deleteEntity!(entity);
        }
        cancel();
      }}
    >
      {mode === DetailsModeType.EDIT ? deleteText : 'Cancel'}
    </button>
    <button className={'save-btn'} style={{marginLeft: '24px'}}
      onClick={handleSave}
      disabled={isDisabled}
    >
      {mode === DetailsModeType.EDIT ? saveText : 'Add'}
    </button>
  </div>;
};
