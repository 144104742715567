import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {TrackCategoryOwnProps, TrackCategoryView} from './TrackCategoryView';

const SortableTrackCategoryView: FC<TrackCategoryOwnProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        isDragging,
        transform,
        transition
    } = useSortable({ id: props.category.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        cursor: isDragging ? 'grabbing' : 'grab',
        boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : undefined,
    };
    return (
      <TrackCategoryView
        ref={setNodeRef}
        {...props}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
};

export default SortableTrackCategoryView;
