
export function getClipsStr(sourceClipsBeat) {
  return sourceClipsBeat
    .split(`\n`)
    .filter((clip) => {
      const clipTrim = clip.trim();
      return clipTrim !== '' && !clipTrim.startsWith('{') && !clipTrim.startsWith('\\') && !clipTrim.startsWith('}');
    })
    .map((clip) => {
      return clip.replaceAll('\\', '').replaceAll('}', '').trim();
    })
}

function isClip(clip) {
  const firstChar = clip.charAt(0);
  return  !isNaN(firstChar);
}
export function createBeatClipsObject(clipsBeat) {
  const clips = [];
  let clip = {id: '-010', duration: 0};

  clipsBeat.forEach((clipBeat) => {
    const clipParts = clipBeat.split(' ');

    if(clip.id === clipParts[0]) {
      if(clipParts.length > 2) {
        clip.effects.push({
          duration: Number(clipParts[1]),
          start: Number(clip.duration.toFixed(2)),
          name: clipParts[2]
        })
      }
      clip.duration += Number(clipParts[1]);
    } else {
      if(!isClip(clipParts[0])) {
        clip.transition = {
          name: clipParts[0]
        }
        if(clipParts.length > 1) {
          clip.transition.duration = Number(clipParts[1])
        }
      } else {
        clip.duration ? clips.push(clip) : null;
        clip = {
          id: clipParts[0],
          duration: Number(clipParts[1]),
          effects: []
        }
        if(clipParts.length > 2) {
          clip.effects.push({
            duration: Number(clipParts[1]),
            start: 0,
            name: clipParts[2]
          })
        }
      }
    }
  })
  clips.push(clip);

  return clips;
}

export function addCrossEffectsToClipsBeat(crossEffects, clipsBeatObject) {
  const clipsBeatObjectCopy = JSON.parse(JSON.stringify(clipsBeatObject))
  crossEffects.forEach((crossEffectBeat, index) => {
    const crossEffectParts = crossEffectBeat.split(' ');
    const crossEffectName = crossEffectParts[0];
    let crossEffectStart = Number(crossEffectParts[1]);
    let crossEffectDuration = Number(crossEffectParts[2]);

    let clipStart = 0;
    for (let clip of clipsBeatObjectCopy) {
      let clipEnd = clipStart + clip.duration;
      if(!clip.effects) {
        clip.effects = [];
      }
      if(crossEffectStart >= clipStart && crossEffectStart < clipEnd) {
        let crossEffectEnd = crossEffectStart + crossEffectDuration;
        if(crossEffectEnd > clipEnd) {
          const effect = {
            duration: Number((crossEffectEnd - (crossEffectEnd - clipEnd)).toFixed(2)),
            start: Number((crossEffectStart - clipStart).toFixed(2)),
            name: crossEffectName,
            id: String(index)
          }
          clip.effects.push(effect);
          crossEffectStart = clipEnd;
          crossEffectDuration = crossEffectDuration - effect.duration;
        } else {
          const effect = {
            duration: Number(crossEffectDuration.toFixed(2)),
            start: Number((crossEffectStart - clipStart).toFixed(2)),
            name: crossEffectName,
            id: String(index)
          }

          clip.effects.push(effect);
          break;
        }
      }
      clipStart += clip.duration;
    }
  })

  return clipsBeatObjectCopy;
}

export function getClipsStrFromJson(clips) {
  let clipsStr = '';
  clips.forEach((clip, index) => {
    if(!clip.effects) {
      clip.effects = [];
    }
    if(!clip.id) {
      clip.id = String(index + 1);
    }

    const clipsEffects = clip
      .effects.filter(effect => !effect.id)
      .sort((effectA, effectB) => {
        return effectA.start - effectB.start;
      })
    if(clipsEffects.length === 0) {
      clipsStr += `${clip.id} ${clip.duration}\n`
    } else {
      let durationCursor = 0;
      clipsEffects.forEach((effect) => {
        if(durationCursor !== effect.start) {
          const duration = Number((effect.start - durationCursor).toFixed(2))
          if(duration !== 0) {
            clipsStr += `${clip.id} ${duration}\n`;
            durationCursor += Number((effect.start - durationCursor).toFixed(2));
          }
        }
        clipsStr += `${clip.id} ${effect.duration} ${effect.name}\n`;
        durationCursor += effect.duration;
      })
      if(durationCursor !== clip.duration) {
        clipsStr += `${clip.id} ${Number(clip.duration - durationCursor).toFixed(2)}\n`;
      }
    }

    if(clip.transition) {
      clipsStr += `${clip.transition.name}${clip.transition.duration ? ' ' + clip.transition.duration : ''}\n`
    }
  })

  return clipsStr;
}

export function getCrossEffectsFromJson(clips) {
  let effectsStr = '';
  const effectsById = {}
  let clipsDurationCursor = 0;
  JSON.parse(JSON.stringify(clips)).forEach((clip) => {
    if(clip.effects) {
      clip.effects
        .filter(effect => effect.id)
        .forEach((effect => {
          if(!effectsById[effect.id]) {
            effectsById[effect.id] = effect;
            effectsById[effect.id].start = Number((clipsDurationCursor + effect.start).toFixed(2));
          } else {
            effectsById[effect.id].duration = Number((effectsById[effect.id].duration + effect.duration).toFixed(2));
          }
        }))
    }

    clipsDurationCursor += clip.duration;
  })

  Object.keys(effectsById).forEach(effectId => {
    const effect = effectsById[effectId];
    effectsStr += `${effect.name} ${effect.start} ${effect.duration}\n`
  })

  return effectsStr;
}
