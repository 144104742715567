import {getAccessToken} from '../utils/AuthUtill';
import {configs} from '../resources/configs';
import {
  WEBSOCKET_CONNECT,
  WEBSOCKET_CONNECTING,
  WEBSOCKET_DISCONNECT,
  WebsocketServiceType
} from "../models/Websockets";

export const getWebsocketUrl = (serviceTypes: any) => {
  return [
    {
      url: configs.reelsWebsocketUrl + '?token=' + getAccessToken(),
      serviceType: WebsocketServiceType.REELS_MAKER,
    },
  ].filter((websocketUrl) => serviceTypes.indexOf(websocketUrl.serviceType) !== -1);
};

export const connectWSS = (serviceTypes: any) => (dispatch: any) => {
  dispatch({
    type: WEBSOCKET_CONNECT,
    payload: {
      urls: getWebsocketUrl(serviceTypes),
    },
  });
  dispatch({type: WEBSOCKET_CONNECTING, serviceTypes});
};

export const disconnectWSS = () => (dispatch: any) => {
  dispatch({type: WEBSOCKET_DISCONNECT});
};
