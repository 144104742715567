import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {setDeleteTemplate, setEditTemplate, updateTemplate} from '../../actions/templates';
import {connect} from 'react-redux';
import {Input} from './Input';

type MoveToProps = {
  moveTo: any,
  items: any[],
  propName: string,
  sourceItem: any,
  isFocus?: boolean,
  style?: any,
  onClose?: any,
  isClose?: boolean,
  containerClassName?: string,
  placeholder?: string,
  renderProp?: any,
  isActiveNone?: boolean
  noneText?: string,
  onNoneAction?: any
}

const MoveTo = ({
  moveTo, items, propName, sourceItem, isFocus = true, isActiveNone, noneText = 'None', onNoneAction,
  style, onClose, isClose = false, containerClassName, placeholder = 'Move to...', renderProp = null,
}: MoveToProps) => {
  const [filter, setFilter] = useState('');
  const [localItems, setLocalItems] = useState(items);

  useEffect(() => {
    setLocalItems(items.filter((item) => {
      if(typeof item === 'object' && item !== null) {
        return item[propName].toLowerCase().includes(filter.toLowerCase());
      } else {
        return item.toLowerCase().includes(filter.toLowerCase());
      }
    }));
  }, [filter]);

  const renderBody = () => {
    return <div className={'move-to-container'}>
      <Input handleChange={setFilter}
        value={filter}
        className={'move-to-input'}
        placeholder={placeholder}
        debounce={100}
        isFocus={isFocus}
      />
      <div style={{maxHeight: '210px', overflowX: 'auto'}}>
        {
          isActiveNone ? <div
            className={'component-setting'}
            style={style}
            onClick={() => {
              if (isClose && onClose) {
                onClose();
              }
              setTimeout(() => onNoneAction(sourceItem), 100);
            }}
          >
            <div className={'component-setting-text'}>{noneText}</div>
          </div> : null
        }
        {
          localItems.map((item, index) => {
            return <div
              className={'component-setting'}
              style={style}
              key={index + 'move_to_' + JSON.stringify(item)}
              onClick={() => {
                if (isClose && onClose) {
                  onClose();
                }
                setTimeout(() => moveTo(sourceItem, item), 100);
              }}
            >
              <div className={'component-setting-text'}>{!renderProp ? item[propName] : renderProp(item)}</div>
            </div>;
          })
        }
      </div>
    </div>;
  };
  if (containerClassName) {
    return <div className={containerClassName}>
      {renderBody()}
    </div>;
  }

  return (renderBody());
};

const mapDispatchToProps = (dispatch: any) => ({
  editTemplate: bindActionCreators(setEditTemplate, dispatch),
  deleteTemplate: bindActionCreators(setDeleteTemplate, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
});

export default connect(null, mapDispatchToProps)(MoveTo);

