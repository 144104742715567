import momentZ from 'moment-timezone';

export const toUserTimeZoneDate = (date) => {
  if (!date) {
    return date;
  }
  // eslint-disable-next-line new-cap
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mo1 = momentZ(date);
  const mo2 = momentZ(date).tz(timeZone);

  const modifiedDate = momentZ(date);
  modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');

  return modifiedDate;
};

export const getFirstsTimesOfMonth = (dates, propName) => {
  const monthsYears = {};
  let resultDates = [];

  dates.forEach((date) => {
    const currentDate = propName ? momentZ(date[propName]) : momentZ(date);
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();
    if (!monthsYears[currentYear]) {
      monthsYears[currentYear] = {};
    }
    if (!monthsYears[currentYear][currentMonth]) {
      monthsYears[currentYear][currentMonth] = currentDate;
    } else {
      if (currentDate.isBefore(monthsYears[currentYear][currentMonth])) {
        monthsYears[currentYear][currentMonth] = currentDate;
      }
    }
  });

  Object.values(monthsYears).forEach((year) => {
    resultDates = resultDates.concat(Object.values(year));
  });

  return resultDates;
};

export const getTimestampUTCms = () => {
  return (new Date()).getTime();
};
