import React, {useState} from 'react';
import {EditImg} from '../general/EditImg';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import uuid from 'uuid';
import {DetailsFooter} from '../general/DetailsFooter';
import {Checkbox} from '../general/Checkbox';
import {addTag, updateTag} from '../../actions/tags';
import {uploadFileToS3Post} from "../../utils/FilesUtils";
import {Tag} from "../../models/Tag";
import {DetailsModeType} from "../../models/Common";
import {configs} from "../../resources/configs";

interface StateProps {
  lastTagOrder: number
}

interface DispatchProps {
  addTag: (tag: Tag) => void,
  updateTag: (tag: Tag) => void
}

interface OwnProps {
  tag: Tag,
  mode: DetailsModeType,
  cancel: () => void,
  deleteTag: (tag: Tag) => void,
}

type Props = StateProps & DispatchProps & OwnProps;

const TagDetails = ({tag, mode, cancel, deleteTag,
  addTag, updateTag, lastTagOrder,
}: Props) => {
  const [isActive, setIsActive] = useState(tag.isActive);
  const [name, setName] = useState(tag.name);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(tag.imageUrl);


  const handleFileDrop = (file: any, fileName: string, setIsLoading: (isLoading: boolean) => void, setUrl: (path: string) => void) => {
    if (file) {
      setIsLoading(true);
      uploadFileToS3Post(file, fileName, 'RES_REELS', `tags/${tag.id}`, (path: string) => {
        setUrl(configs.resUrl + path);
        setIsLoading(false);
      });
    }
  };

  const handleSave = () => {
    const tagForSave = {...tag};
    tagForSave.id = !tagForSave.id ? uuid.v4() : tagForSave.id;
    tagForSave.isActive = isActive;
    tagForSave.name = name;
    tagForSave.imageUrl = imageUrl;
    tagForSave.order = mode === 'EDIT' ? tagForSave.order : lastTagOrder + 1;
    if (!tagForSave.localization.en) {
      tagForSave.localization.en = {};
    }
    tagForSave.localization.en.name = name;
    mode === 'EDIT' ? updateTag(tagForSave) : addTag(tagForSave);
    cancel();
  };

  const renderHeader = () => {
    return <div className={'details-header'} style={{flexDirection: 'row'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={'reels-head-text'}>
          {mode === 'EDIT' ? 'Edit Tag' : 'Add Tag'}
        </div>
        <div className={'reels-second-text'}>
          {tag.id ? 'ID: ' + tag.id : ''}
        </div>
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={'details-body'}>
      <Checkbox
        key={'visibility-checkbox'}
        switch={setIsActive}
        isActive={isActive}
        text={'Visibility'}
      />
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', marginTop: '13px', flexDirection: 'column', width: '182px'}}>
          <div className={'reels-second-text'}>
            Presets photo
          </div>
          <EditImg imgUrl={imageUrl}
            style={{marginTop: '5px', height: '136px', width: '182px'}}
            heightImg={'136px'}
            widthImg={'182px'}
            onDrop={(file) => {handleFileDrop(file, `${uuid.v4()}_image.png`, setIsLoadingImage, setImageUrl)}}
            isLoading={isLoadingImage}
            clear={() => {
              setImageUrl(null);
            }}
          />
        </div>
      </div>

      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{display: 'flex', marginTop: '20px', flexDirection: 'column', width: '200px'}}>
          <div className={'reels-second-text'}>
                        Tag Name
          </div>
          <Input style={{marginTop: '5px', width: '100%'}}
            handleChange={setName}
            value={name}
          />
        </div>
      </div>
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteTag}
      cancel={cancel}
      entity={tag}
      handleSave={handleSave}
      isDisabled={name === ''}
    />
  </div>;
};

const mapStateToProps = (state: any) => ({
  lastTagOrder: state.tags.tags[state.tags.tags.length - 1].order,
});

const mapDispatchToProps = (dispatch: any) => ({
  addTag: bindActionCreators(addTag, dispatch),
  updateTag: bindActionCreators(updateTag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagDetails);
