import React, {forwardRef, HTMLAttributes, useState} from 'react';
import {ReactComponent as DotsHorizontal} from '../../resources/imgs/dots-with-area.svg';
import ComponentSettings from '../general/ComponentSettings';
import DropdownWrapper from '../general/DropdownWrapper';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setEditTemplate, updateTemplate} from "../../actions/templates";
import {TemplatesListType} from "../../models/Common";
import {Template} from "../../models/Template";
import {ComponentType} from "../../models/Dnd";
import {ReactComponent as ViewIcon} from '../../resources/imgs/view-icon.svg';
import {ReactComponent as ExportIcon} from '../../resources/imgs/export-icon.svg';
import {ReactComponent as FreeBadge} from '../../resources/imgs/free-badge.svg';
import {ReactComponent as UniversalBadge} from '../../resources/imgs/universal-badge.svg';
import {getFilenameFromUrl} from "../../utils/FilesUtils";

interface StateProps {}

interface DispatchProps {
  editTemplate: (template: Template, isUpdateRelations: boolean) => void,
  updateTemplate: (template: Template, isUpdateRelations: boolean) => void
  setEditTemplate: (template: Template) => void,
}

export type TemplateOwnProps = HTMLAttributes<HTMLDivElement> &  {
  template: Template,
  isSelected: boolean,
  externalId: string,
  arrayIndex: number,
  entityContainerId?: string | null,
  selectTemplate: (templateId: string, templateListType: TemplatesListType) => void,
  templateListType: TemplatesListType,
  viewCount: number,
  exportCount: number
}

type Props = StateProps & DispatchProps & TemplateOwnProps


const TemplateView = forwardRef<HTMLDivElement, Props>(({template, isSelected, viewCount, exportCount,
  entityContainerId, selectTemplate, templateListType, setEditTemplate, style, ...props}, ref) => {
  const [isMouseOver, setMouseOver] = useState(false);
  const renderButtons = () => {
    return <div style={{position: "absolute", top: '8px', right: '8px'}}>
      <DropdownWrapper style={{right: '-100%'}}>
        <DotsHorizontal
          style={{opacity: (isMouseOver ? 1 : 0)}}
        />
        <ComponentSettings componentType={ComponentType.TEMPLATE} componentTypeIn={templateListType}
          item={template} sourceItemId={entityContainerId}
        />
      </DropdownWrapper>
    </div>;
  };

  return (
        <div className={'template-list-content'}
          onMouseLeave={() => setMouseOver(false)}
          onMouseEnter={() => setMouseOver(true)}
          onClick={() => {
            setEditTemplate(template)
          }}
          style={{...style , ...{margin: '0px 6px 6px 0px'}}}
           ref={ref}
           {...props}
        >
          <div className={'template-list-item' + (isSelected ? '-active' : '')}
            style={{
              cursor: 'move',
              backgroundColor: ('none'),
              opacity: template.isActive ? '1' : '0.4'
            }}
            onClick={(e) => {
              selectTemplate(template.id, templateListType);
            }}
          >
            <img className={'template-list-img'}
              src={`${template.previewImageUrl.replace(getFilenameFromUrl(decodeURIComponent(template.previewImageUrl)), 'compressed_' + getFilenameFromUrl(decodeURIComponent(template.previewImageUrl)))}`}
              alt={template.previewImageUrl}
              onError={({ currentTarget }) => {
                 currentTarget.onerror = null;
                 currentTarget.src=template.previewImageUrl;
              }}
            />
            <div style={{display: 'flex', flexDirection: 'column', position: "absolute", bottom: '20px', left: '10px'}}>
              <div className={'template-list-value-clips'}>
                {`${template.clips.length}fr ${template.clips.map(clip => {return Number(clip.duration)}).reduce((prev, curr) => {
                  return prev + curr;
                }, 0).toFixed(1)}s`}
              </div>
              <div className={'template-list-value-name'}>
                {template.artistName}: {template.trackName}
              </div>
            </div>
            <div style={{display: 'flex', gap: '6px', position: "absolute", left: '6px', top: '9px'}}>
              {template.isFree ? <FreeBadge /> : null}
              {template.isUniversal ? <UniversalBadge/> : null}
            </div>
            {renderButtons()}
          </div>
          <div className={'template-list-stats-container'}>
            <div className={'template-list-stats '}>
              <ViewIcon style={{paddingRight: '3px'}}/>
              {viewCount}
            </div>
            <div className={'template-list-stats '}>
              <ExportIcon style={{paddingRight: '3px'}}/>
              {exportCount}
            </div>
          </div>
        </div>
  );
});

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  editTemplate: bindActionCreators(setEditTemplate, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
  setEditTemplate: bindActionCreators(setEditTemplate, dispatch),
});

export default connect<StateProps, DispatchProps, TemplateOwnProps>(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TemplateView);
