import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MoveTo from './MoveTo';
import {setDeleteTrackCategory, setEditCategory, setEditTrackCategory, updateCategory, updateTrackCategory} from '../../actions/categories';
import {setDeleteTag, setEditTag, updateTag} from '../../actions/tags';
import {ComponentType, DndActionType} from "../../models/Dnd";
import {setDeleteCategory} from "../../actions/categories";
import {setDeleteTemplate, setEditTemplate, updateTemplate} from "../../actions/templates";
import {Category} from "../../models/Category";
import {reservedCategoriesIds, TemplatesListType} from "../../models/Common";
import {Template} from "../../models/Template";
import {Collection} from '../../models/Collection';
import {setDeleteCollection, setEditCollection, updateCollection} from '../../actions/collections';
import {setDeleteTrack, setEditTrack, updateTrack} from '../../actions/tracks';
import {Track} from '../../models/Track';
import {TrackCategory} from '../../models/TrackCategory';

type ComponentSettingsProps = {
  componentType: ComponentType,
  componentTypeIn: any,
  updateTemplate: any,
  sourceItemId?: any,
  editCategory: any,
  deleteCategory: any,
  updateCategory: (category: Category) => void,
  editTrackCategory: any,
  deleteTrackCategory: any,
  updateTrackCategory: (category: TrackCategory) => void,
  updateTrack: any,
  editTrack: (track: Track) => void,
  deleteTrack: any,
  editCollection: any,
  deleteCollection: any,
  updateCollection: (collection: Collection) => void,
  item: any,
  editTemplate: (template: Template) => void,
  deleteTemplate: any,
  onClose?: any,
  categories: Category[],
  updateTag: any,
  editTag: any,
  deleteTag: any
}

const ComponentSettings = ({componentType, componentTypeIn, updateTemplate, sourceItemId, updateCategory,
  editCategory, deleteCategory, item, editTemplate, deleteTemplate, onClose, categories, updateTag,
  editTag, deleteTag, updateCollection, editCollection, deleteCollection,
  editTrackCategory, deleteTrackCategory, updateTrackCategory, updateTrack, editTrack, deleteTrack}: ComponentSettingsProps) => {
  const [moveToProps, setMoveToProps]: any = useState(null);
  const [isShowMoveTo, setIsShowMoveTo] = useState(false);

  useEffect(() => {
    if (moveToProps) {
      setIsShowMoveTo(true);
    }
  }, [moveToProps]);

  const getSettings = () => {
    let settings: any[] = [];
    // TEMPLATE
    if (componentType === ComponentType.TEMPLATE) {
/*
      const edit = {name: 'Edit', action: () => (onClose(), editTemplate(item))};
*/
      const showHide = {name: (item.isActive ? 'Hide' : 'Show'), action: () => {
        onClose();
        updateTemplate(
            Object.assign({}, item, {
              isActive: !item.isActive,
            }),
        );
      }};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteTemplate(item))};
/*      const moveTo = {name: componentTypeIn === TemplatesListType.TEMPLATES_IN_CATEGORY ? 'Move to Section' : 'Add to Section', action: () => {
        setMoveToProps({
          moveTo: (template: Template, category: Category) => {
            onClose();
            if (sourceItemId) {
              globalActions.changeDroppableState(
                  category.id,
                  {
                    action: DndActionType.REPLACE,
                    toIndex: 0,
                    draggableId: template.id,
                    sourceDroppableId: sourceItemId,
                  },
              );
            } else {
              globalActions.changeDroppableState(
                  category.id,
                  {
                    action: DndActionType.ADD,
                    draggableId: template.id,
                    toIndex: 0,
                  },
              );
            }
          },
          items: categories.filter((category) => (category.id !== sourceItemId)),
          propName: 'name',
        });
      }};
      const remove = {name: 'Delete from Section', action: () => {
        globalActions.changeDroppableState(
            sourceItemId,
            {
              action: DndActionType.REMOVE,
              draggableId: item.id,
            },
        );
        onClose();
      }}
      */

      if (componentTypeIn === TemplatesListType.ONLY_TEMPLATES) {
        settings = [showHide, deleteAction];
      }

      if (componentTypeIn === TemplatesListType.TEMPLATES_IN_CATEGORY) {
        settings = [showHide, deleteAction];
      }

      if (componentTypeIn === TemplatesListType.TEMPLATES_IN_COLLECTION) {
        settings = [showHide, deleteAction];
      }
    }
    // CATEGORY
    if (componentType === ComponentType.CATEGORY) {
      const category: Category = item;
      const edit = {name: 'Edit', action: () => (onClose(), editCategory(category))};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteCategory(category))};
      const featuredAction = {
        name: (category.isFeatured ? 'Remove from featured' : 'Add to featured'),
        action: () => (onClose(), updateCategory({...category, ...{isFeatured: !category.isFeatured, order: !category.isFeatured ? 0 : categories.length}}))
      };
      const hideAction = {
        name: (category.isActive ? 'Hide' : 'Show'),
        action: () => (onClose(), updateCategory({...category, ...{isActive: !category.isActive}}))
      };

      settings = [edit, featuredAction, hideAction];
      if (reservedCategoriesIds.indexOf(category.id) === -1) {
        settings.push(deleteAction);
      }
    }

    // TRACK CATEGORY
    if (componentType === ComponentType.TRACK_CATEGORY) {
      const category: TrackCategory = item;
      const edit = {name: 'Edit', action: () => (onClose(), editTrackCategory(category))};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteTrackCategory(category))};
      const hideAction = {
        name: (category.isActive ? 'Hide' : 'Show'),
        action: () => (onClose(), updateTrackCategory({...category, ...{isActive: !category.isActive}}))
      };

      settings = [edit, hideAction, deleteAction];
    }
    // TRACK
    if (componentType === ComponentType.TRACK) {

      const edit = {name: 'Edit', action: () => (onClose(), editTrack(item))};
      const showHide = {name: (item.isActive ? 'Hide' : 'Show'), action: () => {
          onClose();
          updateTrack(
            Object.assign({}, item, {
              isActive: !item.isActive,
            }),
          );
        }};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteTrack(item))};
      settings = [edit, showHide, deleteAction];
    }
    // COLLECTION
    if (componentType === ComponentType.COLLECTION) {
      const collection: Collection = item;
      const edit = {name: 'Edit', action: () => (onClose(), editCollection(collection))};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteCollection(collection))};
      const hideAction = {
        name: (collection.isActive ? 'Hide' : 'Show'),
        action: () => (onClose(), updateCollection({...collection, ...{isActive: !collection.isActive}}))
      };

      settings = [edit, hideAction, deleteAction];
    }
    // TAG
    if (componentType === ComponentType.TAG) {
      const edit = {name: 'Edit', action: () => (onClose(), editTag(item))};
      const deleteAction = {name: 'Delete', action: () => (onClose(), deleteTag(item))};
      const showHide = {isDisabled: !item.imageUrl, name: (item.isActive ? 'Hide' : 'Show'), action: () => {
        onClose();
        updateTag(
            Object.assign({}, item, {
              isActive: !item.isActive,
            }),
        );
      }};
      settings = [edit, showHide, deleteAction];
    }
    return settings;
  };

  return (
    <div className={'component-settings-container'}>
      {
                isShowMoveTo ?
                <MoveTo
                    moveTo={moveToProps.moveTo}
                    items={moveToProps.items}
                    propName={moveToProps.propName}
                    sourceItem={item}
                /> :
                getSettings().map((setting, index) => {
                  return <div
                    className={'component-setting'}
                    key={index + 'setting_' + setting.name + item.id}
                    onClick={setting.isDisabled ? () => {} : setting.action}
                  >
                    <div style={{opacity: (setting.isDisabled ? 0.5 : 1)}}
                      className={'component-setting-text'}>{setting.name}</div>
                  </div>;
                })
      }
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.categories.categories,
});

const mapDispatchToProps = (dispatch: any) => ({
  editTemplate: bindActionCreators(setEditTemplate, dispatch),
  deleteTemplate: bindActionCreators(setDeleteTemplate, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),

  deleteCategory: bindActionCreators(setDeleteCategory, dispatch),
  updateCategory: bindActionCreators(updateCategory, dispatch),
  editCategory: bindActionCreators(setEditCategory, dispatch),

  editTrack: bindActionCreators(setEditTrack, dispatch),
  deleteTrack: bindActionCreators(setDeleteTrack, dispatch),
  updateTrack: bindActionCreators(updateTrack, dispatch),

  deleteTrackCategory: bindActionCreators(setDeleteTrackCategory, dispatch),
  updateTrackCategory: bindActionCreators(updateTrackCategory, dispatch),
  editTrackCategory: bindActionCreators(setEditTrackCategory, dispatch),

  deleteCollection: bindActionCreators(setDeleteCollection, dispatch),
  updateCollection: bindActionCreators(updateCollection, dispatch),
  editCollection: bindActionCreators(setEditCollection, dispatch),

  updateTag: bindActionCreators(updateTag, dispatch),
  editTag: bindActionCreators(setEditTag, dispatch),
  deleteTag: bindActionCreators(setDeleteTag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentSettings);

