import React, {useCallback, useEffect, useState} from 'react';
import {ModalType} from '../../../models/Modals';

import {closestCenter, DndContext, DragEndEvent, DragStartEvent, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import SortableTrackView from './SortableTrackView';
import {Track} from '../../../models/Track';
import {OrderIndexes} from '../../../models/OrderIndexes';

type TracksList = {
    isLoadingTracks: boolean,
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
    tracks: Track[],
    setEditTrack: (track?: Track) => void,
    trackForEdit?: Track | null,
    entityContainerId?: string | null,
    moveTrackInEntity: (
      orderIndexes: OrderIndexes,
      track: Track,
      fromIndex: number,
      toIndex: number,
      toEntityId: string
    ) => void,
}

export const TracksList = ({tracks, isLoadingTracks, showModal, entityContainerId,
    moveTrackInEntity, setEditTrack, trackForEdit}: TracksList) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [localTracks, setTracks] = useState(tracks);

    useEffect(() => {
        setTracks(tracks);
    }, [tracks]);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (active.id !== over?.id && entityContainerId) {
            const oldTrack = findTrack(active.id, tracks);
            const newTrack = findTrack(over!.id, tracks);
            const newLocalTracks = arrayMove(tracks, oldTrack.index, newTrack.index);

            moveTrackInEntity(
              {
                  orderIndexes: newLocalTracks.map((track, index) => {
                      return {
                          entityId: track.relationId!,
                          entityIdOld: null,
                          orderIndex: index,
                      };
                  }),
                  parentId: entityContainerId
              },
              oldTrack.track,
              oldTrack.index,
              newTrack.index,
              entityContainerId,
            );
        }
    }

    if (isLoadingTracks) {
        return (
            <div></div>
        );
    }

    const findTrack = (id: string | number, tracks: Track[] ) => {
        const track = tracks.filter((c) => {
            return c.id === id;
        })[0];
        return {
            track,
            index: tracks.indexOf(track),
        };
    };

    return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
            <SortableContext items={localTracks} strategy={verticalListSortingStrategy}>
                    {localTracks.map((track, index) => {
                        return <SortableTrackView
                          isBlockDragonDrop={!!entityContainerId}
                          key={track.id}
                          track={track}
                          setEditTrack={setEditTrack}
                          trackForEdit={trackForEdit}
                          arrayIndex={index}
                          showModal={showModal}
                        />
                    })}
            </SortableContext>
        </DndContext>
    );
};
