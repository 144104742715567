import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import websocket from './middlewares/websockets';
import {BrowserRouter, Route, useHistory, useLocation} from 'react-router-dom';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import reducer from './reducers';
import {withAuthenticator} from './components/authorization';

import momentZ from 'moment-timezone';

import Head from './components/general/Head';
import {compareString} from './utils/ArrayUtils';
import {getAdminIamActions} from './utils/AuthUtill';
import Library from "./components/library";
import {Tags} from "./components/tags";
import Modals from "./components/general/Modals";
import Settings from "./components/settings";
import './resources/sass/index.scss'
import {Music} from './components/music';

momentZ.tz.setDefault('UTC');

const routsTemplates: any = {
  'default': [
    {path: '/', component: <div>def</div>, order: 0},
  ],
  'webUI:AccessReelsFeed': [
    {path: '/', component: Library, order: 0},
  ],
  'webUI:AccessReelsTags': [
    {path: '/tags', component: Tags, order: 1},
  ],
  'webUI:AccessReelsMusic': [
    {path: '/music', component: Music, order: 2},
  ],
  'webUI:AccessReelsConfig': [
    {path: '/settings', component: Settings, order: 3},
  ],
};

const HandlerLocation = ({routs}: {routs: any[]}) => {
  const history = useHistory();
  const location = useLocation();

  if (routs.length !== 0 && location.pathname === '/' && routs[0].path !== '/') {
    history.push(routs[0].path);
  }
  return null;
};

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(createDebounce(),thunk, websocket)),
);

const WithProvider = () => {
  const iamActions = getAdminIamActions();

  let routs: any[] = routsTemplates['default'];
  if (iamActions.length !== 0) {
    const routsSet: any = {};

    iamActions.forEach((action) => {
      if (action.includes('webUI')) {
        const tempRouts = routsTemplates[action];
        if (tempRouts) {
          tempRouts.forEach((route: any) => {
            routsSet[route.path] = route;
          });
        }
      }
    });
    routs = Object.values(routsSet).sort(compareString('order'));
  }

  return <Provider store={store}>
    <BrowserRouter>
      <div className="app-container">
        <Modals/>
        <Head/>
        <HandlerLocation
            routs={routs}
        />
        <div className={'body-container'}>
          {
            routs.map((rout) => {
              // eslint-disable-next-line react/jsx-key
              return <Route exact path={rout.path} component={rout.component}/>;
            })
          }
        </div>
      </div>
    </BrowserRouter>
  </Provider>;
};

export default withAuthenticator(WithProvider);
