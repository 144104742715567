import React, {useState} from 'react';
import {locales} from '../../utils/LocalizationUtils';
import {LocaleTagColumn} from './LocaleTagColumn';
import {Tag} from "../../models/Tag";

interface Props {
  tags: Tag[],
  updateTag: (tag: Tag) => void,
  setDeleteTag: (tag: Tag) => void,
  setEditTag: (tag: Tag) => void,
  orderParams: any,
  orderTags: (orderParams: any) => void
}

export const TagsList = ({tags, updateTag, setDeleteTag, setEditTag, orderParams, orderTags}: Props) => {
  const [selectedTagId, selectTagId] = useState<string | null | undefined>(null);

  return <div className={'tags-list-container'}>
    {locales.map((locale, index) => {
      return <LocaleTagColumn
        locale={locale}
        key={locale}
        tags={tags}
        selectedTagId={selectedTagId}
        selectTagId={selectTagId}
        updateTag={updateTag}
        setDeleteTag={setDeleteTag}
        setEditTag={setEditTag}
        orderParams={orderParams}
        orderTags={orderTags}
        style={{
          backgroundColor: (index % 2 === 1 ? '#f5f5f5' : undefined),
          width: (index === 0 ? '120px' : '100px'),
        }}
      />;
    })}
  </div>;
};
