import {LOGOUT} from '../actions/auth';
import {logoutApp} from '../utils/AuthUtill';

const initialState = {};

export default function auth(state = initialState, action: any) {
  switch (action.type) {
    case LOGOUT:
      logoutApp();
      return state;

    default: return state;
  }
}
