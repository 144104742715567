import React, {useState} from 'react';
import MDSpinner from 'react-md-spinner';
import uuid from 'uuid';
import {saveFile, webpIsSupported} from '../../utils/FilesUtils';
import {spinnerColor} from "../../utils/StyleUtils";

interface Props {
  imgUrl?: string | null,
  onDrop: (file: any) => void,
  clear: () => void,
  style: any,
  heightImg: string,
  widthImg: string,
  isLoading: boolean
}

export const EditImg = ({imgUrl, onDrop, clear, style, heightImg, widthImg, isLoading}: Props) => {
  const [urlPostfix, setUrlPostfix] = useState(uuid.v4());

  const isActive = false;

  if (isLoading) {
    return <div
      className={'reels-img-edit-dnd-container-active'}
      style={style}
    >
      <MDSpinner size={40} singleColor={spinnerColor}/>
    </div>;
  }

  if (!imgUrl) {
    return <div
      className={'reels-img-edit-dnd-container' + (isActive ? '-active' : '') }
      style={style}
    >
      <img style={{width: '46px', height: '31px'}}
        src={require('../../resources/imgs/upload_image.png')}
      />

      <input type="file" id="file" style={{opacity: '0', width: '100%', height: '100%', position: 'absolute'}}
        onChange={(event) => {
            if (event.target && event.target.files && event.target.files[0].type.startsWith('image/')) {
              webpIsSupported(event.target.files[0], (isSupport: boolean, file: any) => {
                if (isSupport) {
                  onDrop(file);
                  setUrlPostfix(uuid.v4());
                }
              });
            }
        }}/>
    </div>;
  }

  return <div className= "reels-img-edit-container" style={style}>
    <img className={'reels-img-edit'} src={imgUrl}
      style={{height: heightImg, width: widthImg}}
      onClick={() => {
        saveFile(imgUrl);
      }}
    />
    <img className={'reels-close-img'}
      src={require('../../resources/imgs/close.svg').default}
      onClick={clear}
    />
  </div>;
};
