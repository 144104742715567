import React from 'react';
import {ModalType} from '../../../models/Modals';
import {bindActionCreators} from 'redux';
import {showModal} from '../../../actions/general';
import {selectTrackCategory, updateTrackCategoriesOrderIndexes} from '../../../actions/categories';
import {connect} from 'react-redux';
import {TrackCategory} from '../../../models/TrackCategory';
import {TrackCategoriesList} from './TrackCategoriesList';
import {ReactComponent as PlusIcon} from "../../../resources/imgs/plus-icon.svg";

interface StateProps {
  categories: TrackCategory[],
  isLoadingCategories: boolean,
  tracksByCategoriesIds: any,
  selectedTrackCategory: TrackCategory
}

interface DispatchProps {
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  updateCategoriesOrderIndexes: (orderIndexes: any) => void,
  selectTrackCategory: (trackCategory: TrackCategory) => void
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps


const TrackCategories = ({categories, tracksByCategoriesIds, isLoadingCategories,
  updateCategoriesOrderIndexes, showModal, selectTrackCategory, selectedTrackCategory}: Props) => {
  return (
    <div className={'track-categories-container'}>
      <div className={'reels-add-header-add-tack-category'} onClick={() => {
        showModal(ModalType.TRACK_CATEGORY_ADD, true, {});
      }}>
        Category
        <PlusIcon style={{width: '20px', height: '20px'}}/>
      </div>
      <div style={{overflow: 'auto'}}>
        <TrackCategoriesList
          isLoadingCategories={isLoadingCategories}
          updateCategoriesOrderIndexes={updateCategoriesOrderIndexes}
          tracksByCategoriesIds={tracksByCategoriesIds}
          categories={categories}
          selectTrackCategory={selectTrackCategory}
          selectedTrackCategory={selectedTrackCategory}
          showModal={showModal}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.categories.trackCategories,
  isLoadingCategories: state.categories.isLoadingTrackCategories,
  tracksByCategoriesIds: state.tracks.tracksByCategoriesIds,
  selectedTrackCategory: state.categories.selectedTrackCategory
});

const mapDispatchToProps = (dispatch: any) => ({
  showModal: bindActionCreators(showModal, dispatch),
  updateCategoriesOrderIndexes: bindActionCreators(updateTrackCategoriesOrderIndexes, dispatch),
  selectTrackCategory: bindActionCreators(selectTrackCategory, dispatch)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(TrackCategories);
