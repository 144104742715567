import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {updateRemoteConfig} from '../../actions/general';
import {bindActionCreators} from 'redux';
import MDSpinner from 'react-md-spinner';
import {getStyle, spinnerColor} from '../../utils/StyleUtils';
import {Input} from '../general/Input';
import {Checkbox} from '../general/Checkbox';
import {RemoteConfig} from "../../models/RemoteConfig";

interface StateProps {
  remoteConfig: RemoteConfig,
  isLoadingRemoteConfig: boolean,
}

interface DispatchProps {
  updateRemoteConfig: (remoteConfig: RemoteConfig) => void,
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

const RemoteConfigSection = ({remoteConfig, updateRemoteConfig, isLoadingRemoteConfig}: Props) => {
  const [isTikTokEnabled, setIsEnabledTikTok] = useState(remoteConfig ? remoteConfig.isTikTokEnabled : false);
  const [isYouTubeEnabled, setIsEnabledYouTube] = useState(remoteConfig ? remoteConfig.isYouTubeEnabled : false);
  const [isTikTokUniversalEnabled, setIsEnabledTikTokUniversal] = useState(remoteConfig ? remoteConfig.isTikTokUniversalEnabled : false);
  const [isPushNotificationsEnabled, setIsPushNotificationsEnabled] = useState(remoteConfig ? remoteConfig.isPushNotificationsEnabled : false);
  const [isCollectionsEnabled, setIsCollectionsEnabled] = useState(remoteConfig ? remoteConfig.isCollectionsEnabled : true);

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (remoteConfig) {
      setIsEnabledTikTok(remoteConfig.isTikTokEnabled);
      setIsEnabledYouTube(remoteConfig.isYouTubeEnabled);
      setIsEnabledTikTokUniversal(remoteConfig.isTikTokUniversalEnabled);
      setIsPushNotificationsEnabled(remoteConfig.isPushNotificationsEnabled);
      setIsCollectionsEnabled(remoteConfig.isCollectionsEnabled);
    }
  }, [remoteConfig]);

  if (isLoadingRemoteConfig || !remoteConfig) {
    return (
      <div className={getStyle('remote-config-container')}>
        <div className='spinner-container' style={{height: '424px'}}>
          <MDSpinner size={100} singleColor={spinnerColor}/>
        </div>
      </div>
    );
  }

  const onSave = () => {
    const remoteConfig = {
      isTikTokEnabled,
      isYouTubeEnabled,
      isTikTokUniversalEnabled,
      isPushNotificationsEnabled,
      isCollectionsEnabled
    };

    updateRemoteConfig(remoteConfig);
  };

  const renderProp = (title: string, value: string, onEdit: (value: any) => void, isInput: boolean, paddingTop = '14px') => {
    return <div className={'remote-config-prop-container'} style={{paddingTop}} key={title}>
      <div className={'remote-config-prop-title'}>{title}</div>
      {isInput ? <Input
        value={value}
        handleChange={(value: string) => {
          setIsChanged(true);
          const valueNumber = Number(value);
          if (Number.isInteger(valueNumber)) {
            onEdit(valueNumber);
          }
        }}
        style={{width: '100px', textAlign: 'center', paddingLeft: '0px'}}
      /> :
        <div className={'remote-config-prop-text'}>{value}</div>}
    </div>;
  };

  return (
    <div className={getStyle('remote-config-container')}>
      <div className={'remote-config-header'}>
        <div className={'remote-config-header-text'}>Remote config</div>
      </div>
      <div className={'remote-config-body'}>
        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
          <Checkbox
            isActive={isTikTokEnabled}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsEnabledTikTok(isEnabled);
            }}
           text={'Is active TikTok'}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
          <Checkbox
            isActive={isYouTubeEnabled}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsEnabledYouTube(isEnabled);
            }}
            text={'Is active YouTube'}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
          <Checkbox
            isActive={isTikTokUniversalEnabled}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsEnabledTikTokUniversal(isEnabled);
            }}
            text={'Is active TikTok Universal templates'}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
          <Checkbox
            isActive={isPushNotificationsEnabled}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsPushNotificationsEnabled(isEnabled);
            }}
            text={'Is active push notifications'}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
          <Checkbox
            isActive={isCollectionsEnabled}
            switch={(isEnabled) => {
              setIsChanged(true);
              setIsCollectionsEnabled(isEnabled);
            }}
            text={'Is active collections'}/>
        </div>
      </div>
      <div className={'remote-config-footer'}>
        <button className={'save-btn'} disabled={!isChanged} onClick={() => {
          onSave();
          setIsChanged(false);
        }}>
          Save
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoadingRemoteConfig: state.appState.isLoadingRemoteConfig,
  remoteConfig: state.appState.remoteConfig,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateRemoteConfig: bindActionCreators(updateRemoteConfig, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfigSection);
