import {saveItem, getItem} from './LocalStoargeUtill';
import {Auth} from "../models/Auth";
import {ForceLogoutParams} from "../models/Common";
import {configs} from "../resources/configs";

export const saveAuth = (auth: Auth) => {
  if (auth.access_token_ol) {
    saveItem(auth.access_token_ol, 'access_token_ol');
  }

  if (auth.admin) {
    saveItem(auth.admin, 'admin');
    if (auth.admin.iamRoles) {
      const iamActionsSet: any = {};
      auth.admin.iamRoles.forEach((role) => {
        role.actions.forEach((action) => {
          iamActionsSet[action] = true;
        });
      });
      saveItem(Object.keys(iamActionsSet), 'iamActions');
    }
  }

  if (auth.cookies) {
    saveItem(auth.cookies, 'cookies');
    auth.cookies.forEach((cookie) => {
      document.cookie = cookie;
    });
  }
};

export const getAdminIamActions = (): string[] => {
  return getItem('iamActions', []);
};

export const isAuth = () => {
  let forceLogoutParams: ForceLogoutParams = getItem('force_logout_params', null);
  if(!forceLogoutParams || forceLogoutParams.version < configs.forceLogoutVersion) {
    forceLogoutParams = {version: configs.forceLogoutVersion};
    saveItem(forceLogoutParams, 'force_logout_params');
    saveItem(null, 'access_token_ol');
    saveItem([], 'iamActions');
    return false;
  }

  const accessToken = getItem('access_token_ol', null);

  return accessToken !== null;
};

export const getAccessToken = () => {
  return getItem('access_token_ol', null);
};

export const registerCookiesFromLocalStorage = () => {
  const cookies = getItem('cookies', []);

  cookies.forEach((cookie: string) => {
    document.cookie = cookie;
  });
};


export const getAdmin = () => {
  return getItem('admin', null);
};

let handlerAuthState: any = null;

export const registerLogout = (logoutFunc: any) => {
  handlerAuthState = logoutFunc;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const logoutApp = () => {
  localStorage.clear();
  sessionStorage.clear();
  deleteAllCookies();
  handlerAuthState(false);
};

