import uuid from 'uuid';
import update from 'immutability-helper';
import {TemplatesListType} from "../models/Common";
import {TemplatesActionType} from "../actions/templates";
import {Category} from "../models/Category";
import {Template} from "../models/Template";
import {CategoriesActionType} from "../actions/categories";
import {TagsActionTypes} from "../actions/tags";
import {WebsocketAction} from "../models/Websockets";
import {Collection} from '../models/Collection';
import {CollectionsActionType} from '../actions/collections';

export type TemplatesByCategoryId = {[id: string]: Template[]}
export type TemplatesByCollectionId = {[id: string]: Template[]}
export type TemplatesEventCount = {[id: string]: number}

export interface TemplatesState {
    stateId: string,
    templates: Template[],
    isLoadingTemplates: boolean,
    selectedTemplateIds: any,
    timeStampSaveTemplates: number,
    timeStampAddTemplates: number,
    templatesByCategoriesIds: TemplatesByCategoryId
    templatesByCollectionsIds: TemplatesByCollectionId,
    templatesViewCount: TemplatesEventCount,
    templatesExportCount: TemplatesEventCount,
    isCompressingImage: boolean
}

const getInitialState = (): TemplatesState => {
    return {
        stateId: uuid.v4(),
        templates: [],
        isLoadingTemplates: false,
        selectedTemplateIds: generateSelectedTemplateIds(),
        timeStampSaveTemplates: 0,
        timeStampAddTemplates: 0,
        templatesByCategoriesIds: {},
        templatesByCollectionsIds: {},
        templatesViewCount: {},
        templatesExportCount: {},
        isCompressingImage: false
    };
};

const generateSelectedTemplateIds = () => {
    const selectedTemplateIds: any = {};
    Object.keys(TemplatesListType).forEach((key) => {
        selectedTemplateIds[key] = [];
    });
    return selectedTemplateIds;
};

export default function templates(state = getInitialState(), action: any) {
    switch (action.type) {
        case TemplatesActionType.SAVE_TEMPLATES:
            const timeStampSaveTemplates = action.requestTimeEpoch;
            const stateIdRequestedSaveTemplates = action.stateId;

            if (
                timeStampSaveTemplates > state.timeStampSaveTemplates &&
                stateIdRequestedSaveTemplates === state.stateId
            ) {
                const templatesByCategoriesIds: TemplatesByCategoryId = {};

                action.categories.forEach((category: Category) => {
                    templatesByCategoriesIds[category.id] = [];
                });

                const templatesByCollectionsIds: TemplatesByCollectionId = {};

                action.collections.forEach((collection: Collection) => {
                    templatesByCollectionsIds[collection.id] = [];
                });

                action.templates.forEach((template: Template) => {
                    template.templateToCategories.forEach((templateToCategory) => {
                        const copyTemplate = {...template};
                        copyTemplate.orderIndex = templateToCategory.order;
                        copyTemplate.relationId = templateToCategory.templateToCategoryId;
                        templatesByCategoriesIds[templateToCategory.categoryId].push(copyTemplate);
                    });
                    template.templateToCollections.forEach((templateToCollection) => {
                        const copyTemplate = {...template};
                        copyTemplate.orderIndex = templateToCollection.order;
                        copyTemplate.relationId = templateToCollection.templateToCollectionId;
                        templatesByCollectionsIds[templateToCollection.collectionId].push(copyTemplate);
                    });
                });

                Object.keys(templatesByCategoriesIds).forEach((key) => {
                    templatesByCategoriesIds[key] = templatesByCategoriesIds[key].sort((a, b) => {
                        return a.orderIndex! - b.orderIndex!;
                    });
                });


                Object.keys(templatesByCollectionsIds).forEach((key) => {
                    templatesByCollectionsIds[key] = templatesByCollectionsIds[key].sort((a, b) => {
                        return a.orderIndex! - b.orderIndex!;
                    });
                });

                return Object.assign({}, state, {
                    templates: action.templates,
                    isLoadingTemplates: false,
                    timeStampSaveTemplates: timeStampSaveTemplates,
                    templatesByCategoriesIds,
                    templatesByCollectionsIds
                });
            }
            return state;

        case TemplatesActionType.ADD_TEMPLATE_TO_CATEGORY: {
            const toTemplatesAdd = state.templatesByCategoriesIds[action.toCategoryId] ?
              [...state.templatesByCategoriesIds[action.toCategoryId]].filter((template) => (template.id !== action.template.id)) : [];

            toTemplatesAdd.splice(action.toIndex, 0, action.template);

            return Object.assign({}, state, {
                templatesByCategoriesIds: Object.assign({}, state.templatesByCategoriesIds, {
                    [action.toCategoryId]: toTemplatesAdd,
                }),
            });
        }


        case TemplatesActionType.ADD_TEMPLATE_TO_COLLECTION: {
            const toTemplatesAdd = state.templatesByCollectionsIds[action.toCollectionId] ?
              [...state.templatesByCategoriesIds[action.toCollectionId]].filter((template) => (template.id !== action.template.id)) : [];

            toTemplatesAdd.splice(action.toIndex, 0, action.template);

            return Object.assign({}, state, {
                templatesByCollectionsIds: Object.assign({}, state.templatesByCollectionsIds, {
                    [action.toCollectionId]: toTemplatesAdd,
                }),
            });
        }

        case TemplatesActionType.MOVE_TEMPLATES_IN_CATEGORY: {
            const movedTemplates = update(state.templatesByCategoriesIds[action.categoryId], {
                $splice: [[action.fromIndex, 1], [action.toIndex, 0, action.template]],
            });

            return Object.assign({}, state, {
                templatesByCategoriesIds: Object.assign({}, state.templatesByCategoriesIds, {
                    [action.categoryId]: movedTemplates,
                }),
            });
        }

        case TemplatesActionType.MOVE_TEMPLATES_IN_COLLECTION: {
            const movedTemplates = update(state.templatesByCollectionsIds[action.collectionId], {
                $splice: [[action.fromIndex, 1], [action.toIndex, 0, action.template]],
            });

            return Object.assign({}, state, {
                templatesByCollectionsIds: Object.assign({}, state.templatesByCollectionsIds, {
                    [action.collectionId]: movedTemplates,
                }),
            });
        }

        case TemplatesActionType.REPLACE_TEMPLATES_IN_CATEGORIES: {
            const toTemplatesReplaced = state.templatesByCategoriesIds[action.toCategoryId] ?
              [...state.templatesByCategoriesIds[action.toCategoryId]].filter((template) => (template.id !== action.template.id)) : [];
            toTemplatesReplaced.splice(action.toIndex, 0, action.template);

            const fromTemplatesReplaced = state.templatesByCategoriesIds[action.fromCategoryId]
              .filter((template) => (template.id !== action.template.id));

            return Object.assign({}, state, {
                templatesByCategoriesIds: Object.assign({}, state.templatesByCategoriesIds, {
                    [action.toCategoryId]: toTemplatesReplaced,
                    [action.fromCategoryId]: fromTemplatesReplaced,
                }),
            });
        }

        case TemplatesActionType.REPLACE_TEMPLATES_IN_COLLECTIONS: {
            const toTemplatesReplaced = state.templatesByCollectionsIds[action.toCollectionId] ?
              [...state.templatesByCollectionsIds[action.toCategoryId]].filter((template) => (template.id !== action.template.id)) : [];
            toTemplatesReplaced.splice(action.toIndex, 0, action.template);

            const fromTemplatesReplaced = state.templatesByCollectionsIds[action.fromCollectioId]
              .filter((template) => (template.id !== action.template.id));

            return Object.assign({}, state, {
                templatesByCollectionsIds: Object.assign({}, state.templatesByCollectionsIds, {
                    [action.toCollectionId]: toTemplatesReplaced,
                    [action.fromCollectioId]: fromTemplatesReplaced,
                }),
            });
        }

        case TemplatesActionType.REMOVE_TEMPLATE_FROM_CATEGORY: {
            const fromTemplatesRemove = [...state.templatesByCategoriesIds[action.fromCategoryId]]
              .filter((template) => (template.id !== action.template.id));

            return Object.assign({}, state, {
                templatesByCategoriesIds: Object.assign({}, state.templatesByCategoriesIds, {
                    [action.fromCategoryId]: fromTemplatesRemove,
                }),
            });
        }

        case TemplatesActionType.REMOVE_TEMPLATE_FROM_COLLECTION: {
            const fromTemplatesRemove = [...state.templatesByCollectionsIds[action.fromCollectionId]]
              .filter((template) => (template.id !== action.template.id));

            return Object.assign({}, state, {
                templatesByCollectionsIds: Object.assign({}, state.templatesByCollectionsIds, {
                    [action.fromCollectionId]: fromTemplatesRemove,
                }),
            });
        }

        case TemplatesActionType.SELECT_TEMPLATE:
            const newSelectedTemplateIds = [...state.selectedTemplateIds[action.templateListType]];
            const indexInArray = newSelectedTemplateIds.indexOf(action.templateId);
            if (indexInArray === -1) {
                newSelectedTemplateIds.push(action.templateId);
            } else {
                newSelectedTemplateIds.splice(indexInArray, 1);
            }
            return Object.assign({}, state, {
                selectedTemplateIds: Object.assign({}, state.selectedTemplateIds, {
                    [action.templateListType]: newSelectedTemplateIds,
                }),
            });

        case CategoriesActionType.DELETE_CATEGORY: {
            const templatesByCategoriesIdsDeleteCategory = {...state.templatesByCategoriesIds};
            delete templatesByCategoriesIdsDeleteCategory[action.categoryId];

            return Object.assign({}, state, {
                templatesByCategoriesIds: templatesByCategoriesIdsDeleteCategory,
            });
        }

        case CollectionsActionType.DELETE_COLLECTION: {
            const templatesByCollectionsIdsDeleteCollection = {...state.templatesByCollectionsIds};
            delete templatesByCollectionsIdsDeleteCollection[action.collectionId];

            return Object.assign({}, state, {
                templatesByCollectionsIds: templatesByCollectionsIdsDeleteCollection,
            });
        }

        case TemplatesActionType.IS_LOADING_TEMPLATES:
            return Object.assign({}, state, {
                isLoadingTemplates: action.isLoading,
            });

        case TemplatesActionType.SAVE_TEMPLATES_EXPORT_COUNT:
            return Object.assign({}, state, {
                templatesExportCount: action.counts ? action.counts : {},
            });

        case TemplatesActionType.SAVE_TEMPLATES_VIEW_COUNT:
            return Object.assign({}, state, {
                templatesViewCount: action.counts ? action.counts : {},
            });

        case TemplatesActionType.DELETE_TEMPLATE:
            const templatesByCategoriesIdsForDeleteTemplate = {...state.templatesByCategoriesIds};
            Object.keys(templatesByCategoriesIdsForDeleteTemplate)
                .forEach((templateInCategoryKey) => {
                    templatesByCategoriesIdsForDeleteTemplate[templateInCategoryKey] = templatesByCategoriesIdsForDeleteTemplate[templateInCategoryKey]
                        .filter((template) => (template.id !== action.templateId));
                });
            const templatesByCollectionsIdsForDeleteTemplate = {...state.templatesByCollectionsIds};
            Object.keys(templatesByCollectionsIdsForDeleteTemplate)
              .forEach((templateInCollectionKey) => {
                  templatesByCollectionsIdsForDeleteTemplate[templateInCollectionKey] = templatesByCollectionsIdsForDeleteTemplate[templateInCollectionKey]
                    .filter((template) => (template.id !== action.templateId));
              });

            return Object.assign({}, state, {
                templatesByCategoriesIds: templatesByCategoriesIdsForDeleteTemplate,
                templatesByCollectionsIds: templatesByCollectionsIdsForDeleteTemplate,
                templates: state.templates.filter((template) => {
                    return template.id !== action.templateId;
                }),
            });

        case TemplatesActionType.ADD_TEMPLATE: {
            const templatesByCategoriesIdsForAddTemplate = {...state.templatesByCategoriesIds};
            const templatesByCollectionsIdsForAddTemplate = {...state.templatesByCollectionsIds};
            const templateForAdd: Template = action.template;

            Object.keys(templatesByCategoriesIdsForAddTemplate)
              .forEach((templateInCategoryKey) => {
                  const isAddToCategory = templateForAdd.templateToCategories
                    .find((templateToCategory) => templateToCategory.categoryId === templateInCategoryKey);
                  let isExistInCategory = false;
                  if(!isAddToCategory) {
                      templatesByCategoriesIdsForAddTemplate[templateInCategoryKey] = templatesByCategoriesIdsForAddTemplate[templateInCategoryKey]
                        .filter(template => {
                            if (!isExistInCategory && template.id === templateForAdd.id) {
                                isExistInCategory = true;
                            }
                            return template.id !== templateForAdd.id;
                        })
                  }
                  if(isAddToCategory && !isExistInCategory) {
                      templatesByCategoriesIdsForAddTemplate[templateInCategoryKey]
                        .unshift({...templateForAdd, ...{relationId: isAddToCategory.templateToCategoryId}});
                  }
              });

            Object.keys(templatesByCollectionsIdsForAddTemplate)
              .forEach((templateInCollectionKey) => {
                  const isAddToCollection = templateForAdd.templateToCollections
                    .find((templateToCollection) => templateToCollection.collectionId === templateInCollectionKey);
                  let isExistInCollection = false;
                  if(!isAddToCollection) {
                      templatesByCollectionsIdsForAddTemplate[templateInCollectionKey] = templatesByCollectionsIdsForAddTemplate[templateInCollectionKey]
                        .filter(template => {
                            if (!isExistInCollection && template.id === templateForAdd.id) {
                                isExistInCollection = true;
                            }
                            return template.id !== templateForAdd.id;
                        })
                  }
                  if(isAddToCollection && !isExistInCollection) {
                      templatesByCollectionsIdsForAddTemplate[templateInCollectionKey]
                        .unshift({...templateForAdd, ...{relationId: isAddToCollection.templateToCollectionId}});
                  }
              });
            return Object.assign({}, state, {
                templatesByCategoriesIds: templatesByCategoriesIdsForAddTemplate,
                templatesByCollectionsIds: templatesByCollectionsIdsForAddTemplate,
                templates: [...[templateForAdd], ...state.templates],
            });
        }

        case CollectionsActionType.ADD_COLLECTION:
            return Object.assign({}, state, {
                templatesByCollectionsIds: {...state.templatesByCollectionsIds, ...{[action.collection.id]: []}},
            });

        case CategoriesActionType.ADD_CATEGORY:
            return Object.assign({}, state, {
                templatesByCategoriesIds: {...state.templatesByCategoriesIds, ...{[action.category.id]: []}},
            });

        case TemplatesActionType.UPDATE_TEMPLATE:
            const templatesByCategoriesIdsForUpdateTemplate = {...state.templatesByCategoriesIds};
            Object.keys(templatesByCategoriesIdsForUpdateTemplate)
                .forEach((templateInCategoryKey) => {
                    let isContainTemplate = false;
                    templatesByCategoriesIdsForUpdateTemplate[templateInCategoryKey] = templatesByCategoriesIdsForUpdateTemplate[templateInCategoryKey]
                        .map((template) => {
                            if (template.id === action.template.id) {
                                isContainTemplate = true;
                                const newTemplate = {...template, ...action.template};
                                newTemplate.relationId = template.relationId;
                                return newTemplate;
                            }
                            return template;
                        });
                    if (action.isUpdateRelations) {
                        const template: Template = action.template;
                        const templateToCategory = template.templateToCategories
                            .find((templateToCategory) => templateToCategory.categoryId === templateInCategoryKey);
                        if (!isContainTemplate && templateToCategory) {
                            templatesByCategoriesIdsForUpdateTemplate[templateInCategoryKey]
                                .unshift({...action.template, ...{relationId: templateToCategory.templateToCategoryId}});
                        }
                        if (isContainTemplate && !templateToCategory) {
                            templatesByCategoriesIdsForUpdateTemplate[templateInCategoryKey] = templatesByCategoriesIdsForUpdateTemplate[templateInCategoryKey]
                                .filter((template) => (template.id !== action.template.id));
                        }
                    }
                });

            const templatesByCollectionsIdsForUpdateTemplate = {...state.templatesByCollectionsIds};
            Object.keys(templatesByCollectionsIdsForUpdateTemplate)
              .forEach((templateInCollectionKey) => {
                  let isContainTemplate = false;
                  templatesByCollectionsIdsForUpdateTemplate[templateInCollectionKey] = templatesByCollectionsIdsForUpdateTemplate[templateInCollectionKey]
                    .map((template) => {
                        if (template.id === action.template.id) {
                            isContainTemplate = true;
                            const newTemplate = {...template, ...action.template};
                            newTemplate.relationId = template.relationId;
                            return newTemplate;
                        }
                        return template;
                    });
                  if (action.isUpdateRelations) {
                      const template: Template = action.template;
                      const templateToCollection = template.templateToCollections
                        .find((templateToCollection) => templateToCollection.collectionId === templateInCollectionKey);
                      if (!isContainTemplate && templateToCollection) {
                          templatesByCollectionsIdsForUpdateTemplate[templateInCollectionKey]
                            .unshift({...action.template, ...{relationId: templateToCollection.templateToCollectionId}});
                      }
                      if (isContainTemplate && !templateToCollection) {
                          templatesByCollectionsIdsForUpdateTemplate[templateInCollectionKey] = templatesByCollectionsIdsForUpdateTemplate[templateInCollectionKey]
                            .filter((template) => (template.id !== action.template.id));
                      }
                  }
              });
            return Object.assign({}, state, {
                templatesByCategoriesIds: templatesByCategoriesIdsForUpdateTemplate,
                templatesByCollectionsIds: templatesByCollectionsIdsForUpdateTemplate,
                templates: state.templates.map((template) => {
                    if (template.id === action.template.id) {
                        return action.template;
                    }
                    return template;
                }),
            });

        case TagsActionTypes.REMOVE_TAG_FROM_TEMPLATES: {
            const templatesByCategoriesIds = {...state.templatesByCategoriesIds};
            Object.keys(templatesByCategoriesIds)
                .forEach((templateInCategoryKey) => {
                    templatesByCategoriesIds[templateInCategoryKey] = templatesByCategoriesIds[templateInCategoryKey]
                        .map((template) => {
                            const newTemplate = {...template};
                            newTemplate.tagIds = template.tagIds.filter((tagId) => {
                                return tagId !== action.tagId;
                            });
                            return newTemplate;
                        });
                });

            const templatesWithoutTag = state.templates
                .map((template) => {
                    const newTemplate = {...template};
                    newTemplate.tagIds = template.tagIds.filter((tagId) => {
                        return tagId !== action.tagId;
                    });
                    return newTemplate;
                });

            return Object.assign({}, state, {
                templates: templatesWithoutTag,
                templatesByCategoriesIds,
            });
        }

        case TemplatesActionType.REMOVE_CATEGORY_FROM_TEMPLATES:
            const templatesWithoutCategory = state.templates
                .map((template) => {
                    const newTemplate = {...template};
                    newTemplate.templateToCategories = template.templateToCategories.filter((templateToCategories) => {
                        return templateToCategories.categoryId !== action.categoryId;
                    });
                    return newTemplate;
                });

            return Object.assign({}, state, {
                templates: templatesWithoutCategory,
            });

        case TemplatesActionType.REMOVE_COLLECTION_FROM_TEMPLATES:
            const templatesWithoutCollection = state.templates
              .map((template) => {
                  const newTemplate = {...template};
                  newTemplate.templateToCollections = template.templateToCollections.filter((templateToCollections) => {
                      return templateToCollections.collectionId !== action.collectionId;
                  });
                  return newTemplate;
              });

            return Object.assign({}, state, {
                templates: templatesWithoutCollection,
            });

        case TemplatesActionType.SET_IS_COMPRESSING_IMAGE:
            return Object.assign({}, state, {
                isCompressingImage: action.isCompressing,
            });

        case WebsocketAction.FINISH_COMPRESS_IMAGE.name:
            if(state.stateId === action.data.stateId) {
                return Object.assign({}, state, {
                    isCompressingImage: false
                });
            } else  return state;

        case TemplatesActionType.UPDATE_RELATION_TEMPLATE_TO_CATEGORY: {
            const templatesUpdatedRelation = state.templates
              .map((template) => {
                  const newTemplate = {...template};
                  if (template.id === action.newRelation.fromEntityId) {
                      newTemplate.templateToCategories = template.templateToCategories.filter((templateToCategories) => {
                          return templateToCategories.templateToCategoryId !== action.newRelation.oldUUID;
                      });
                      newTemplate.templateToCategories.push({
                          templateToCategoryId: action.newRelation.uuid,
                          templateId: template.id,
                          categoryId: action.newRelation.toEntityId,
                          order: action.newRelation.orderIndex,
                      });
                  }

                  return newTemplate;
              });

            return Object.assign({}, state, {
                templates: templatesUpdatedRelation,
            });
        }

        case TemplatesActionType.UPDATE_RELATION_TEMPLATE_TO_COLLECTION: {
            const templatesUpdatedRelation = state.templates
              .map((template) => {
                  const newTemplate = {...template};
                  if (template.id === action.newRelation.fromEntityId) {
                      newTemplate.templateToCollections = template.templateToCollections.filter((templateToCollections) => {
                          return templateToCollections.templateToCollectionId !== action.newRelation.oldUUID;
                      });
                      newTemplate.templateToCollections.push({
                          templateToCollectionId: action.newRelation.uuid,
                          templateId: template.id,
                          collectionId: action.newRelation.toEntityId,
                          order: action.newRelation.orderIndex,
                      });
                  }

                  return newTemplate;
              });

            return Object.assign({}, state, {
                templates: templatesUpdatedRelation,
            });
        }

        default: return state;
    }
}
