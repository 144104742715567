import uuid from 'uuid';
import update from 'immutability-helper';
import {TracksActionType} from "../actions/tracks";
import {Track} from "../models/Track";
import {CategoriesActionType} from "../actions/categories";
import {WebsocketAction} from "../models/Websockets";
import {TrackCategory} from '../models/TrackCategory';

export type TracksByCategoryId = {[id: string]: Track[]}
export type TracksByCollectionId = {[id: string]: Track[]}
export type TracksEventCount = {[id: string]: number}

export interface TracksState {
    stateId: string,
    tracks: Track[],
    isLoadingTracks: boolean,
    selectedTrackId?: string | null,
    timeStampSaveTracks: number,
    timeStampAddTracks: number,
    tracksByCategoriesIds: TracksByCategoryId
    tracksPlaysCount: TracksEventCount,
    tracksDownloadCount: TracksEventCount,
    isCompressingImage: boolean
}

const getInitialState = (): TracksState => {
    return {
        stateId: uuid.v4(),
        tracks: [],
        isLoadingTracks: false,
        selectedTrackId: null,
        timeStampSaveTracks: 0,
        timeStampAddTracks: 0,
        tracksByCategoriesIds: {},
        tracksPlaysCount: {},
        tracksDownloadCount: {},
        isCompressingImage: false
    };
};

export default function tracks(state = getInitialState(), action: any) {
    switch (action.type) {
        case TracksActionType.SAVE_TRACKS:
            const timeStampSaveTracks = action.requestTimeEpoch;
            const stateIdRequestedSaveTracks = action.stateId;

            if (
                timeStampSaveTracks > state.timeStampSaveTracks &&
                stateIdRequestedSaveTracks === state.stateId
            ) {
                const tracksByCategoriesIds: TracksByCategoryId = {};

                action.tracksCategories.forEach((trackCategory: TrackCategory) => {
                    tracksByCategoriesIds[trackCategory.id] = [];
                });
                action.tracks.forEach((track: Track) => {
                    track.trackToCategories.forEach((trackToCategory) => {
                        const copyTrack = {...track};
                        copyTrack.orderIndex = trackToCategory.order;
                        copyTrack.relationId = trackToCategory.trackToCategoryId;
                        tracksByCategoriesIds[trackToCategory.categoryId].push(copyTrack);
                    });
                });

                Object.keys(tracksByCategoriesIds).forEach((key) => {
                    tracksByCategoriesIds[key] = tracksByCategoriesIds[key].sort((a, b) => {
                        return a.orderIndex! - b.orderIndex!;
                    });
                });

                return Object.assign({}, state, {
                    tracks: action.tracks,
                    isLoadingTracks: false,
                    timeStampSaveTracks: timeStampSaveTracks,
                    tracksByCategoriesIds,
                });
            }
            return state;

        case TracksActionType.ADD_TRACK_TO_TRACK_CATEGORY: {
            const toTracksAdd = state.tracksByCategoriesIds[action.toCategoryId] ?
              [...state.tracksByCategoriesIds[action.toCategoryId]].filter((track) => (track.id !== action.track.id)) : [];

            toTracksAdd.splice(action.toIndex, 0, action.track);

            return Object.assign({}, state, {
                tracksByCategoriesIds: Object.assign({}, state.tracksByCategoriesIds, {
                    [action.toCategoryId]: toTracksAdd,
                }),
            });
        }

        case TracksActionType.MOVE_TRACKS_IN_TRACK_CATEGORY: {
            const movedTracks = update(state.tracksByCategoriesIds[action.categoryId], {
                $splice: [[action.fromIndex, 1], [action.toIndex, 0, action.track]],
            });

            return Object.assign({}, state, {
                tracksByCategoriesIds: Object.assign({}, state.tracksByCategoriesIds, {
                    [action.categoryId]: movedTracks,
                }),
            });
        }

        case TracksActionType.REPLACE_TRACKS_IN_TRACK_CATEGORIES: {
            const toTracksReplaced = state.tracksByCategoriesIds[action.toCategoryId] ?
              [...state.tracksByCategoriesIds[action.toCategoryId]].filter((track) => (track.id !== action.track.id)) : [];
            toTracksReplaced.splice(action.toIndex, 0, action.track);

            const fromTracksReplaced = state.tracksByCategoriesIds[action.fromCategoryId]
              .filter((track) => (track.id !== action.track.id));

            return Object.assign({}, state, {
                tracksByCategoriesIds: Object.assign({}, state.tracksByCategoriesIds, {
                    [action.toCategoryId]: toTracksReplaced,
                    [action.fromCategoryId]: fromTracksReplaced,
                }),
            });
        }

        case TracksActionType.REMOVE_TRACK_FROM_TRACK_CATEGORY: {
            const fromTracksRemove = [...state.tracksByCategoriesIds[action.fromCategoryId]]
              .filter((track) => (track.id !== action.track.id));

            return Object.assign({}, state, {
                tracksByCategoriesIds: Object.assign({}, state.tracksByCategoriesIds, {
                    [action.fromCategoryId]: fromTracksRemove,
                }),
            });
        }

        case TracksActionType.SELECT_TRACK:
            return Object.assign({}, state, {
                selectedTrackId: action.trackId,
            });

        case CategoriesActionType.DELETE_CATEGORY: {
            const tracksByCategoriesIdsDeleteCategory = {...state.tracksByCategoriesIds};
            delete tracksByCategoriesIdsDeleteCategory[action.categoryId];

            return Object.assign({}, state, {
                tracksByCategoriesIds: tracksByCategoriesIdsDeleteCategory,
            });
        }

        case TracksActionType.IS_LOADING_TRACKS:
            return Object.assign({}, state, {
                isLoadingTracks: action.isLoading,
            });

        case TracksActionType.SAVE_TRACKS_PLAYS_COUNT:
            return Object.assign({}, state, {
                tracksPlaysCount: action.counts ? action.counts : {},
            });

        case TracksActionType.SAVE_TRACKS_DOWNLOAD_COUNT:
            return Object.assign({}, state, {
                tracksDownloadCount: action.counts ? action.counts : {},
            });

        case TracksActionType.DELETE_TRACK:
            const tracksByCategoriesIdsForDeleteTrack = {...state.tracksByCategoriesIds};
            Object.keys(tracksByCategoriesIdsForDeleteTrack)
                .forEach((trackInCategoryKey) => {
                    tracksByCategoriesIdsForDeleteTrack[trackInCategoryKey] = tracksByCategoriesIdsForDeleteTrack[trackInCategoryKey]
                        .filter((track) => (track.id !== action.trackId));
                });

            return Object.assign({}, state, {
                tracksByCategoriesIds: tracksByCategoriesIdsForDeleteTrack,
                tracks: state.tracks.filter((track) => {
                    return track.id !== action.trackId;
                }),
            });

        case TracksActionType.ADD_TRACK: {
            const tracksByCategoriesIdsForAddTrack = {...state.tracksByCategoriesIds};
            const trackForAdd: Track = action.track;

            Object.keys(tracksByCategoriesIdsForAddTrack)
              .forEach((trackInCategoryKey) => {
                  const isAddToCategory = trackForAdd.trackToCategories
                    .find((trackToCategory) => trackToCategory.categoryId === trackInCategoryKey);
                  let isExistInCategory = false;
                  if(!isAddToCategory) {
                      tracksByCategoriesIdsForAddTrack[trackInCategoryKey] = tracksByCategoriesIdsForAddTrack[trackInCategoryKey]
                        .filter(track => {
                            if (!isExistInCategory && track.id === trackForAdd.id) {
                                isExistInCategory = true;
                            }
                            return track.id !== trackForAdd.id;
                        })
                  }
                  if(isAddToCategory && !isExistInCategory) {
                      tracksByCategoriesIdsForAddTrack[trackInCategoryKey]
                        .unshift({...trackForAdd, ...{relationId: isAddToCategory.trackToCategoryId}});
                  }
              });

            return Object.assign({}, state, {
                tracksByCategoriesIds: tracksByCategoriesIdsForAddTrack,
                tracks: [...[trackForAdd], ...state.tracks],
            });
        }

        case CategoriesActionType.ADD_TRACK_CATEGORY:
            return Object.assign({}, state, {
                tracksByCategoriesIds: {...state.tracksByCategoriesIds, ...{[action.category.id]: []}},
            });

        case TracksActionType.UPDATE_TRACK:
            const tracksByCategoriesIdsForUpdateTrack = {...state.tracksByCategoriesIds};
            Object.keys(tracksByCategoriesIdsForUpdateTrack)
                .forEach((trackInCategoryKey) => {
                    let isContainTrack = false;
                    tracksByCategoriesIdsForUpdateTrack[trackInCategoryKey] = tracksByCategoriesIdsForUpdateTrack[trackInCategoryKey]
                        .map((track) => {
                            if (track.id === action.track.id) {
                                isContainTrack = true;
                                const newTrack = {...track, ...action.track};
                                newTrack.relationId = track.relationId;
                                return newTrack;
                            }
                            return track;
                        });
                    if (action.isUpdateRelations) {
                        const track: Track = action.track;
                        const trackToCategory = track.trackToCategories
                            .find((trackToCategory) => trackToCategory.categoryId === trackInCategoryKey);
                        if (!isContainTrack && trackToCategory) {
                            tracksByCategoriesIdsForUpdateTrack[trackInCategoryKey]
                                .unshift({...action.track, ...{relationId: trackToCategory.trackToCategoryId}});
                        }
                        if (isContainTrack && !trackToCategory) {
                            tracksByCategoriesIdsForUpdateTrack[trackInCategoryKey] = tracksByCategoriesIdsForUpdateTrack[trackInCategoryKey]
                                .filter((track) => (track.id !== action.track.id));
                        }
                    }
                });

            return Object.assign({}, state, {
                tracksByCategoriesIds: tracksByCategoriesIdsForUpdateTrack,
                tracks: state.tracks.map((track) => {
                    if (track.id === action.track.id) {
                        return action.track;
                    }
                    return track;
                }),
            });

        case TracksActionType.REMOVE_TRACK_CATEGORY_FROM_TRACKS:
            const tracksWithoutCategory = state.tracks
                .map((track) => {
                    const newTrack = {...track};
                    newTrack.trackToCategories = track.trackToCategories.filter((trackToCategories) => {
                        return trackToCategories.categoryId !== action.categoryId;
                    });
                    return newTrack;
                });

            return Object.assign({}, state, {
                tracks: tracksWithoutCategory,
            });

        case TracksActionType.SET_IS_COMPRESSING_IMAGE:
            return Object.assign({}, state, {
                isCompressingImage: action.isCompressing,
            });

        case WebsocketAction.FINISH_COMPRESS_IMAGE.name:
            if(state.stateId === action.data.stateId) {
                return Object.assign({}, state, {
                    isCompressingImage: false
                });
            } else  return state;

        case TracksActionType.UPDATE_RELATION_TRACK_TO_CATEGORY: {
            const tracksUpdatedRelation = state.tracks
              .map((track) => {
                  const newTrack = {...track};
                  if (track.id === action.newRelation.fromEntityId) {
                      newTrack.trackToCategories = track.trackToCategories.filter((trackToCategories) => {
                          return trackToCategories.trackToCategoryId !== action.newRelation.oldUUID;
                      });
                      newTrack.trackToCategories.push({
                          trackToCategoryId: action.newRelation.uuid,
                          trackId: track.id,
                          categoryId: action.newRelation.toEntityId,
                          order: action.newRelation.orderIndex,
                      });
                  }

                  return newTrack;
              });

            return Object.assign({}, state, {
                tracks: tracksUpdatedRelation,
            });
        }

        default: return state;
    }
}
