import React, { Component } from 'react'
import debounce from 'throttle-debounce'

interface Props {
    isActive: boolean,
    onChange: (isActive: boolean) => void
    style?: any,
    value: number | string
}

interface State {
    isActive: boolean
}

export class Badge extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isActive: this.props.isActive
        }
    }

    componentWillUpdate (nextProps: Props, nextState: State, nextContext: any) {
        if(this.props.isActive !== nextProps.isActive) {
            this.setState({isActive: nextProps.isActive})
        }
    }

    onChange = debounce.debounce(200, false,() => this.props.onChange(this.state.isActive))

    handleChange = () => {
        this.setState({ isActive: !this.state.isActive }, () => this.onChange());
    }

    render () {
        return (
            <div className={'reels-badge' + (this.state.isActive ? "-active" : "")}
                 onClick={this.handleChange}
                 style={this.props.style}
            >
                <div className={"reels-badge-text" + (this.state.isActive ? "-active" : "")}>
                    {this.props.value}
                </div>
            </div>
        )
    }
}
