import React, {useEffect, useState} from 'react';
import {Clip} from "../../models/Template";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

import {parseStringFile} from "../../utils/FilesUtils";
import {TextArea} from '../general/TextArea';
import {createBeatClipsObject, getClipsStr, getClipsStrFromJson, getCrossEffectsFromJson, addCrossEffectsToClipsBeat} from '../../utils/TemplatesClipsUtils';

interface Props {
  clips: Clip[],
  updateClips: (clips: Clip[]) => void,
  style: any,
  isValidClips: boolean,
  setIsValidClips : (isValid: boolean) => void
}

export const TemplateClipsView = ({clips, updateClips, style, isValidClips, setIsValidClips}: Props) => {
  const [clipsString, setClipsString] = useState(getClipsStrFromJson(clips));
  const [clipsCrossEffectsString, setCrossEffectsString] = useState(getCrossEffectsFromJson(clips));

  const renderHeader = () => {
    let isValid = true;
    const totalDuration = clips.map(clip => {
        if(isValid && isNaN(clip.duration) || (clip.transition && clip.transition.duration !== undefined && isNaN(clip.transition.duration))){
          isValid = false;
        }
        return clip.duration;
    })
      .reduce((prev, curr) => {
      return prev + curr;
    }, 0)
    setIsValidClips(isValid);

    return <div className={'reels-second-text'} style={{display: "flex", gap: "10px"}}>
      <div>Timing</div>
      <div style={{display: 'flex'}}>
        <div>{clips.length}fr</div>
        <div style={{paddingLeft: '15px'}}>{totalDuration.toFixed(2)}s</div>
      </div>
    </div>
  }

  const renderControls = (onParse: (str: string) => void, title: string) => {
    return <div style={{alignSelf: 'flex-start', display: 'flex', width: '100%',
      justifyContent: 'space-between', alignItems: "center",
    }}>
      <input onChange={(event) => {
        if (event.target && event.target.files && event.target.files.length > 0) {
          const targetFiles: any = event.target.files;
          const files = Object.keys(targetFiles)
              .map((keyFile) => {
                return targetFiles[keyFile]
              });
          parseStringFile(files[0])
              .then((res) => onParse(res))
              .catch((err) => {throw new Error(err)})
        }
      }} type="file" id={title} accept=".txt,.rtf" hidden/>
      <label className={'loading-timing-btn'} htmlFor={title}>{title}</label>
    </div>
  }
  return <div style={style}>
    {renderHeader()}
    <div style={{display: 'flex', gap: '10px'}}>
      <div style={{display: 'flex', flexDirection: 'column', gap: '5px',marginTop: '3px'}}>
        {renderControls(
          (res) => {
            const clipsStr = getClipsStr(res);
            setClipsString(clipsStr.join('\n'));
            const clipsJson = createBeatClipsObject(clipsStr);
            // @ts-ignore
            updateClips(addCrossEffectsToClipsBeat(getClipsStr(clipsCrossEffectsString), clipsJson));
          }, 'load clips'
        )}
        <TextArea style={{width: '100px'}}
                  value={clipsString}
                  maxRows={10}
                  key={'clips'}
                  handleChange={(value: string) => {
                    const clipsStr = getClipsStr(value);
                    const clipsJson = createBeatClipsObject(clipsStr);
                    // @ts-ignore
                    setClipsString(value);
                    updateClips(addCrossEffectsToClipsBeat(getClipsStr(clipsCrossEffectsString), clipsJson));
                  }}
        />
      </div>

      <div style={{display: 'flex', flexDirection: 'column', gap: '5px',marginTop: '3px'}}>
        {renderControls(
          (res) => {
            const crossEffectsStr = getClipsStr(res);
            const clipsCrossEffectsString = crossEffectsStr.join('\n');
            setCrossEffectsString(clipsCrossEffectsString);
            const clipsStr  = getClipsStr(clipsString);
            const clips = createBeatClipsObject(clipsStr);
            // @ts-ignore
            updateClips(addCrossEffectsToClipsBeat(getClipsStr(clipsCrossEffectsString), clips));
          }, 'load effects'
        )}
        <TextArea style={{width: '100px'}}
                  key={'effects'}
                  value={clipsCrossEffectsString}
                  maxRows={10}
                  handleChange={(value: string) => {
                    const crossEffects = getClipsStr(value);
                    setCrossEffectsString(value);
                    const clipsStr = getClipsStr(clipsString);
                    const clips = createBeatClipsObject(clipsStr);
                    // @ts-ignore
                    updateClips(addCrossEffectsToClipsBeat(crossEffects, clips));
                  }}
        />
      </div>
      <JsonView
        style={{backgroundColor: '#f5f2f2', padding: '5px', borderRadius: '10px', maxHeight: '286px', overflowY: 'auto'}}
        src={clips}
        collapsed={true}
        theme={'winter-is-coming'}/>
    </div>
  </div>
};
