import React from 'react';
import {Checkbox} from '../../general/Checkbox';

interface Props {
  isFree: boolean,
  setIsFree: (isFree: boolean) => void,
  isNew: boolean,
  setIsNew: (isNew: boolean) => void,
  setIsPopular: (isPremium: boolean) => void,
  isPopular: boolean
}

export const CheckboxTrackDetails = ({
  isFree, setIsFree, isNew, setIsNew, setIsPopular, isPopular}: Props) => {
  const checkBoxStyle = {marginRight: '12px'};
  return <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px', gap: '3px'}}>
    <div className={'reels-second-text'}>
      Add to Section
    </div>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <Checkbox
        key={'paid-checkbox'}
        switch={(isFree) => {setIsFree(!isFree)}}
        isActive={!isFree}
        style={checkBoxStyle}
        text={'PAID'}
      />
      <Checkbox
        key={'new-checkbox'}
        isActive={isNew}
        style={checkBoxStyle}
        switch={setIsNew}
        text={'NEW'}
      />
      <Checkbox
        key={'popular-checkbox'}
        style={checkBoxStyle}
        switch={setIsPopular}
        isActive={isPopular}
        text={'MOST POPULAR'}
      />
    </div>
  </div>
};
