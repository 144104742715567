import React, {forwardRef, HTMLAttributes, useState} from 'react';
import {ReactComponent as DotsHorizontal} from '../../../resources/imgs/dots-horizontal-icon.svg';
import {ReactComponent as DotsDrag} from '../../../resources/imgs/drag-dots-icon.svg';

import {TrackCategory} from '../../../models/TrackCategory';
import {ModalType} from '../../../models/Modals';
import DropdownWrapper from '../../general/DropdownWrapper';
import ComponentSettings from '../../general/ComponentSettings';
import {ComponentType} from '../../../models/Dnd';

export type TrackCategoryOwnProps = HTMLAttributes<HTMLDivElement> & {
    category: TrackCategory,
    tracksCount: number,
    arrayIndex: number,
    selectedTrackCategory: TrackCategory
    selectTrackCategory: (trackCategory: TrackCategory) => void
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
};

type Props = TrackCategoryOwnProps

export const TrackCategoryView = forwardRef<HTMLDivElement, Props>(({category, showModal, selectTrackCategory,
    selectedTrackCategory,arrayIndex,style, tracksCount,  ...props}, ref) => {
    const [isMouseOver, setMouseOver] = useState(false);

    const renderHeader = () => {
        return <div className={'track-category-item'}
                    style={{backgroundColor: (selectedTrackCategory && selectedTrackCategory.id === category.id ? '#d3ffb9' : 'white'),
                        color: (category.isActive ? 'black' : '#7f8488')}
                    }
                    onMouseLeave={() => setMouseOver(false)}
                    onMouseEnter={() => setMouseOver(true)}
                    onClick={() => {
                        selectTrackCategory(category);
                    }}
        >
            <div
              ref={ref}
              {...props}
              style={{opacity: (isMouseOver ? 1 : 0)}}
            >
                <DotsDrag/>
            </div>

            <div>
                {`${category.name} (${tracksCount})`}
            </div>

            <div style={{marginLeft: 'auto', paddingRight: '10px'}}>
                <DropdownWrapper style={{right: '-100%'}}>
                    <DotsHorizontal
                      style={{opacity: (isMouseOver ? 1 : 0)}}
                    />
                    <ComponentSettings componentType={ComponentType.TRACK_CATEGORY} componentTypeIn={null} item={category}/>
                </DropdownWrapper>
            </div>
        </div>
    };
    return (<div className={'reels-items-container-none-height'} style={style}>
                    {renderHeader()}
                </div>);
});
