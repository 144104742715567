import React from 'react';
import {ModalType} from '../../../models/Modals';
import {bindActionCreators} from 'redux';
import {showModal} from '../../../actions/general';
import {selectTrackCategory, updateTrackCategoriesOrderIndexes} from '../../../actions/categories';
import {connect} from 'react-redux';
import {TrackCategory} from '../../../models/TrackCategory';
import {ReactComponent as PlusIcon} from "../../../resources/imgs/plus-icon.svg";
import {ReactComponent as HeadphoneIcon} from "../../../resources/imgs/headphone-icon.svg";
import {ReactComponent as DownloadIcon} from "../../../resources/imgs/download-icon.svg";

import {Track} from '../../../models/Track';
import {IoMdClose as CloseIcon} from 'react-icons/io';
import MDSpinner from 'react-md-spinner';
import {spinnerColor} from '../../../utils/StyleUtils';
import {moveTrackInCategory, setEditTrack} from '../../../actions/tracks';
import {TracksList} from './TracksList';
import {OrderIndexes} from '../../../models/OrderIndexes';

interface StateProps {
  isLoadingTracks: boolean,
  tracksByCategoriesIds: any,
  selectedTrackCategory: TrackCategory
  tracks: Track[],
  trackForEdit?: Track | null
}

interface DispatchProps {
  showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
  selectTrackCategory: (trackCategory?: TrackCategory | null) => void,
  setEditTrack: (track?: Track) => void,
  moveTrackInCategory: (
    orderIndexes: OrderIndexes,
    track: Track,
    fromIndex: number,
    toIndex: number,
    toEntityId: string,
  ) => void
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps


const Tracks = ({tracksByCategoriesIds, isLoadingTracks, tracks, setEditTrack, trackForEdit,
  showModal, selectTrackCategory, selectedTrackCategory, moveTrackInCategory}: Props) => {

  const renderTrackCategoryBadge = () => {
    console.log(selectedTrackCategory)
    return selectedTrackCategory ?
        <div className={'tag-badge'}>
          {`${selectedTrackCategory.name} (${tracksByCategoriesIds[selectedTrackCategory.id].length})`}
          <div className={'close-tag'}>
          <CloseIcon
            onClick={(e) => {
              e.stopPropagation();
              selectTrackCategory(null);
            }}
            style={{paddingLeft: '5px', width: '20px'}}
          />
        </div>
    </div> : <div className={'all-tracks-text'}>{`All (${tracks.length})`}</div>
  }

  if (isLoadingTracks) {
    return (
      <div className='spinner-container' style={{marginTop: 'auto', marginBottom: 'auto', width: '100%'}}>
        <MDSpinner size={100} singleColor={spinnerColor}/>
      </div>
    );
  }

  return (
    <div className={'tracks-container'}>
      <div className={'reels-header-tracks'}>
        {renderTrackCategoryBadge()}
        <div className={'reels-header-track-add'} onClick={() => {
          showModal(ModalType.TRACK_ADD, true, {});
        }}>
          <PlusIcon style={{width: '20px', height: '20px'}}/>
          Add
        </div>
      </div>

      <div className={'reels-track-tabel'}>
        <div className={'header-tabel'}>
          <div className={'id-column header-right-border'}>#</div>
          <div className={'cover-column header-right-border'}>Cover</div>
          <div className={'artist-column header-right-border'}>Artist</div>
          <div className={'song-column header-right-border'}>Song</div>
          <div className={'duration-column header-right-border'}>Duration</div>
          <div className={'source-column header-right-border'}>Source</div>
          <div className={'play-count-column header-right-border'}><HeadphoneIcon/></div>
          <div className={'download-count-column header-right-border'}><DownloadIcon/></div>
        </div>
        <div style={{overflow: 'auto'}}>
            <TracksList
              isLoadingTracks={isLoadingTracks}
              showModal={showModal}
              entityContainerId={selectedTrackCategory ? selectedTrackCategory.id : null}
              tracks={selectedTrackCategory ? tracksByCategoriesIds[selectedTrackCategory.id] : tracks}
              setEditTrack={setEditTrack}
              trackForEdit={trackForEdit}
              moveTrackInEntity={moveTrackInCategory}
            />
        </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoadingTracks: state.tracks.isLoadingTracks,
  tracks: state.tracks.tracks,
  tracksByCategoriesIds: state.tracks.tracksByCategoriesIds,
  selectedTrackCategory: state.categories.selectedTrackCategory,
  trackForEdit: state.appState.trackForEdit
});

const mapDispatchToProps = (dispatch: any) => ({
  showModal: bindActionCreators(showModal, dispatch),
  selectTrackCategory: bindActionCreators(selectTrackCategory, dispatch),
  setEditTrack: bindActionCreators(setEditTrack, dispatch),
  moveTrackInCategory: bindActionCreators(moveTrackInCategory, dispatch)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Tracks);
