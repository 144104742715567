import React, {forwardRef, HTMLAttributes, useState} from 'react';
import {ReactComponent as DotsHorizontal} from '../../../resources/imgs/dots-horizontal-icon.svg';
import {ReactComponent as DotsDrag} from '../../../resources/imgs/drag-dots-icon.svg';
import {ReactComponent as PremiumIcon} from '../../../resources/imgs/premium-icon.svg';

import momentZ from 'moment-timezone';

import {ModalType} from '../../../models/Modals';
import DropdownWrapper from '../../general/DropdownWrapper';
import ComponentSettings from '../../general/ComponentSettings';
import {ComponentType} from '../../../models/Dnd';
import {ReelsTrackSourceType, Track} from '../../../models/Track';
import ImageWithFallback from '../../general/ImageWithFallback';

export type TrackOwnProps = HTMLAttributes<HTMLDivElement> & {
    track: Track,
    arrayIndex: number,
    setEditTrack: (track?: Track) => void,
    trackForEdit?: Track | null,
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
    isBlockDragonDrop: boolean
};

type Props = TrackOwnProps

export const TrackView = forwardRef<HTMLDivElement, Props>(({track, isBlockDragonDrop, showModal, setEditTrack, trackForEdit
    ,arrayIndex,style,  ...props}, ref) => {
    const [isMouseOver, setMouseOver] = useState(false);

    const getDuration = () => {
      const duration = momentZ.duration(track.duration * 1000);
      let seconds = duration.seconds();
      let minutes = duration.minutes();
      return (minutes < 10 ? `0${minutes}` : String(minutes)) + ':' + (seconds < 10 ? `0${seconds}` : String(seconds));
    }
    const renderCover = () => {
        return <div className={'track-cover-container'}>
            <ImageWithFallback
                 className={'track-cover'}
                 src={track.coverImageUrl}
                 fallback={require('../../../resources/imgs/def-cover.png')}
            />
            <PremiumIcon
                className={'premium-icon'}
                style={{opacity: track.isFree ? 0 : 1}}
            />
        </div>
    }

    const renderSource = () => {
        switch (track.sourceType) {
            case ReelsTrackSourceType.ONELIGHT: {
                return <div className={'source-badge-onelight'}>
                    Onelight
                </div>
            }
            case ReelsTrackSourceType.THE_SOUL: {
                return <div className={'source-badge-soul'}>
                    TheSoul
                </div>
            }
        }

    }

    return <div className={'reels-items-container-none-height'}
                style={style}>
        <div className={'track-item'}
                style={{opacity: track.isActive ? 1 : 0.5}}
                onMouseLeave={() => setMouseOver(false)}
                onMouseEnter={() => setMouseOver(true)}
                onClick={() => {
                    setEditTrack(track);
                }}
    >

        <div className={'id-column item-right-border'} style={{position: 'relative'}}>
            <div style={{display: 'flex', position: 'absolute', opacity: (isMouseOver ? 1 : 0), zIndex: (isMouseOver ? 2 : 1)}}>
                <div
                  ref={ref}
                  {...props}>
                    <DotsDrag/>
                </div>
                <div>
                    <DropdownWrapper style={{right: '-100%'}}>
                        <DotsHorizontal/>
                        <ComponentSettings componentType={ComponentType.TRACK} componentTypeIn={null} item={track}/>
                    </DropdownWrapper>
                </div>
            </div>
            <div style={{display: 'flex', position: 'absolute', opacity: (isMouseOver ? 0 : 1), zIndex: (isMouseOver ? 1 : 2)}}>
                {arrayIndex}
            </div>
        </div>
        <div className={'cover-column item-right-border'}>{renderCover()}</div>
        <div className={'artist-column item-right-border'}>{track.artistName}</div>
        <div className={'song-column item-right-border'}>{track.trackName}</div>
        <div className={'duration-column item-right-border'}>{getDuration()}</div>
        <div className={'source-column item-right-border'}>{renderSource()}</div>
        <div className={'play-count-column item-right-border'}>0</div>
        <div className={'download-count-column'}>0</div>
    </div>
    </div>
});
