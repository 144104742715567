import {createRequest} from '../utils/HttpRequestUtills';
import {GeneralActionType} from "./general";
import {Category} from "../models/Category";
import categories from '../reducers/categories';
import {reservedCategoriesIds} from '../models/Common';
import {TrackCategory} from '../models/TrackCategory';

export enum CategoriesActionType {
    SAVE_CATEGORIES = 'SAVE_CATEGORIES',
    IS_LOADING_CATEGORIES = 'IS_LOADING_CATEGORIES',
    SELECT_CATEGORY = 'SELECT_CATEGORY',
    ADD_CATEGORY = 'ADD_CATEGORY',
    DELETE_CATEGORY = 'DELETE_CATEGORY',
    UPDATE_CATEGORY ='UPDATE_CATEGORY',
    UPDATE_ORDERS_INDEXES_CATEGORIES = 'UPDATE_ORDERS_INDEXES_CATEGORIES',
    SET_EDIT_CATEGORY = 'SET_EDIT_CATEGORY',
    SET_DELETE_CATEGORY = 'SET_DELETE_CATEGORY',
    SET_IS_FULL_VIEW_CATEGORY = 'SET_IS_FULL_VIEW_CATEGORY',
    //Track category
    SAVE_TRACK_CATEGORIES = 'SAVE_TRACK_CATEGORIES',
    IS_LOADING_TRACK_CATEGORIES = 'IS_LOADING_TRACK_CATEGORIES',
    SELECT_TRACK_CATEGORY = 'SELECT_TRACK_CATEGORY',
    ADD_TRACK_CATEGORY = 'ADD_TRACK_CATEGORY',
    DELETE_TRACK_CATEGORY = 'DELETE_TRACK_CATEGORY',
    UPDATE_TRACK_CATEGORY ='UPDATE_TRACK_CATEGORY',
    UPDATE_ORDERS_INDEXES_TRACK_CATEGORIES = 'UPDATE_ORDERS_INDEXES_TRACK_CATEGORIES',
    SET_EDIT_TRACK_CATEGORY = 'SET_TRACK_EDIT_CATEGORY',
    SET_DELETE_TRACK_CATEGORY = 'SET_TRACK_DELETE_CATEGORY',
}

export const deleteCategory = (categoryId: string) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({type: CategoriesActionType.DELETE_CATEGORY, categoryId});

    const onload = () => {};

    const xhr = createRequest(dispatch, 'DELETE', '/reels/category/' + categoryId, onload);

    xhr.send();
};

export const addCategory = (category: Category) => (dispatch: any, getState: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: CategoriesActionType.ADD_CATEGORY,
        category
    });
    const onload = () => {
        const orderIndexes  = getState().categories.categories.map((categoryFromState: Category) => {
            let orderIndex = categoryFromState.order + 1;
            if(categoryFromState.isFeatured) {
                orderIndex = 0;
            }
            if(categoryFromState.id === category.id) {
                orderIndex = 1;
            }
            if(reservedCategoriesIds.indexOf(categoryFromState.id) !== -1) {
                orderIndex = categoryFromState.order;
            }
            return {
                entityId: categoryFromState.id,
                orderIndex,
            }
        })
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({
            type: CategoriesActionType.UPDATE_ORDERS_INDEXES_CATEGORIES,
            orderIndexes,
        });

        const onload = () => {};
        const xhr = createRequest(dispatch, 'PATCH', '/reels/categories/order-indexes', onload);

        xhr.send(JSON.stringify({orderIndexes}));
    };
    const xhr = createRequest(dispatch, 'POST', '/reels/category', onload);

    xhr.send(JSON.stringify(category));
};

export const updateCategory = (category: Category) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({type: CategoriesActionType.UPDATE_CATEGORY, category});

    const onload = () => {};

    const xhr = createRequest(dispatch, 'PATCH', '/reels/category', onload);

    xhr.send(JSON.stringify(category));
};

export const updateCategoriesOrderIndexes = (orderIndexes: any) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: CategoriesActionType.UPDATE_ORDERS_INDEXES_CATEGORIES,
        orderIndexes,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/categories/order-indexes', onload);

    xhr.send(JSON.stringify({orderIndexes}));
};

export const setEditCategory = (category?: Category | null) => (dispatch: any) => {
    dispatch({
        type: CategoriesActionType.SET_EDIT_CATEGORY,
        category,
    });
};

export const setDeleteCategory = (category?: Category | null) => (dispatch: any) => {
    dispatch({
        type: CategoriesActionType.SET_DELETE_CATEGORY,
        category,
    });
};

export const setIsFullViewCategory = (categoryId: string, isFullView: boolean) => (dispatch: any) => {
    dispatch({
        type: CategoriesActionType.SET_IS_FULL_VIEW_CATEGORY,
        isFullView,
        categoryId
    });
};

export const deleteTrackCategory = (categoryId: string) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({type: CategoriesActionType.DELETE_TRACK_CATEGORY, categoryId});

    const onload = () => {};

    const xhr = createRequest(dispatch, 'DELETE', '/reels/track-category/' + categoryId, onload);

    xhr.send();
};

export const addTrackCategory = (category: TrackCategory) => (dispatch: any, getState: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: CategoriesActionType.ADD_TRACK_CATEGORY,
        category
    });
    const onload = () => {
        const orderIndexes  = getState().categories.trackCategories.map((categoryFromState: TrackCategory) => {
            let orderIndex = categoryFromState.order + 1;
            if(categoryFromState.id === category.id) {
                orderIndex = 1;
            }
            return {
                entityId: categoryFromState.id,
                orderIndex,
            }
        })
        dispatch({
            type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
            isBlockPublish: false,
        });
        dispatch({
            type: CategoriesActionType.UPDATE_ORDERS_INDEXES_TRACK_CATEGORIES,
            orderIndexes,
        });

        const onload = () => {};
        const xhr = createRequest(dispatch, 'PATCH', '/reels/categories/order-indexes', onload);

        xhr.send(JSON.stringify({orderIndexes}));
    };
    const xhr = createRequest(dispatch, 'POST', '/reels/track-category', onload);

    xhr.send(JSON.stringify(category));
};

export const updateTrackCategory = (category: TrackCategory) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({type: CategoriesActionType.UPDATE_TRACK_CATEGORY, category});

    const onload = () => {};

    const xhr = createRequest(dispatch, 'PATCH', '/reels/track-category', onload);

    xhr.send(JSON.stringify(category));
};

export const updateTrackCategoriesOrderIndexes = (orderIndexes: any) => (dispatch: any) => {
    dispatch({
        type: GeneralActionType.SET_IS_BLOCK_PUBLISH,
        isBlockPublish: false,
    });
    dispatch({
        type: CategoriesActionType.UPDATE_ORDERS_INDEXES_TRACK_CATEGORIES,
        orderIndexes,
    });

    const onload = () => {};
    const xhr = createRequest(dispatch, 'PATCH', '/reels/track-categories/order-indexes', onload);

    xhr.send(JSON.stringify({orderIndexes}));
};

export const setEditTrackCategory = (category?: TrackCategory | null) => (dispatch: any) => {
    dispatch({
        type: CategoriesActionType.SET_EDIT_TRACK_CATEGORY,
        category,
    });
};

export const setDeleteTrackCategory = (category?: TrackCategory | null) => (dispatch: any) => {
    dispatch({
        type: CategoriesActionType.SET_DELETE_TRACK_CATEGORY,
        category,
    });
};

export const selectTrackCategory = (category?: TrackCategory | null) => (dispatch: any) => {
    dispatch({
        type: CategoriesActionType.SELECT_TRACK_CATEGORY,
        category,
    });
};

