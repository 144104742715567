import React, {useState} from 'react';
import MDSpinner from "react-md-spinner";
import {spinnerColor} from "../../utils/StyleUtils";
import uuid from "uuid";

interface Props {
  videoUrl?: string | null,
  onDrop: (file: any) => void,
  clear: () => void,
  style: any,
  heightVideo: string,
  widthVideo: string,
  isLoading: boolean
}

export const EditVideo = ({videoUrl, onDrop, clear, style, heightVideo, widthVideo, isLoading}: Props) => {
  const [urlPostfix, setUrlPostfix] = useState(uuid.v4());

  const isActive = false;

  if (isLoading) {
    return <div
        className={'reels-img-edit-dnd-container-active'}
        style={style}
    >
      <MDSpinner size={40} singleColor={spinnerColor}/>
    </div>;
  }

  if (!videoUrl) {
    return <div
      className={'reels-img-edit-dnd-container' + (isActive ? '-active' : '') }
      style={style}
    >
      <img style={{width: '32px', height: '32px'}}
        src={require('../../resources/imgs/upload_video.png')}
      />
      <input type="file" id="file" style={{opacity: '0', width: '100%', height: '100%', position: 'absolute'}}
        onChange={(event) => {
          if (event.target && event.target.files && event.target.files[0] && event.target.files[0].type.startsWith('video/')) {
            onDrop(event.target.files[0]);
            setUrlPostfix(uuid.v4());
          }
        }}/>
    </div>;
  }

  return <div className= "reels-img-edit-container" style={style}>
    <video className={'reels-img-edit'} width={widthVideo} height={heightVideo} controls>
      <source src={videoUrl + '?uuid=' + urlPostfix} type="video/mp4"/>
    </video>
    <img className={'reels-close-img'}
      src={require('../../resources/imgs/close.png')}
      onClick={clear}
    />
  </div>;
};
