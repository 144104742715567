import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {logout} from '../../actions/auth'
import {NavLink} from "react-router-dom";
import { ReactComponent as LibraryIcon} from '../../resources/imgs/navs/library-icon.svg';
import { ReactComponent as TagsIcon} from '../../resources/imgs/navs/tags-icon.svg';
import { ReactComponent as MusicIcon} from '../../resources/imgs/navs/music-icon.svg';
import { ReactComponent as NavbarIcon} from '../../resources/imgs/navs/navbar-icon.svg';
import { ReactComponent as LogoutIcon} from '../../resources/imgs/navs/logout-icon.svg';
import { ReactComponent as SettingsIcon} from '../../resources/imgs/navs/settings-icon.svg';
import {
    getAdmins, getFeed, getRemoteConfig,
    saveAdmin
} from "../../actions/general";
import {configs} from "../../resources/configs";
import {getAdminIamActions, isAuth, registerCookiesFromLocalStorage} from '../../utils/AuthUtill'
import {connectWSS, disconnectWSS} from '../../actions/websockets';
import {isMobile, MobileView} from "react-device-detect";
import {getStyle} from '../../utils/StyleUtils';
import {Nav} from './stylesJS/Styles';
import {compareString} from "../../utils/ArrayUtils";
import {WebsocketServiceType} from "../../models/Websockets";
import {getTemplatesExportCount, getTemplatesViewCount} from "../../actions/templates";

const menuVariants = {
    opened: {
        top: 50,
    },
    closed: {
        top: "-100vh"
    }
};

type State = {
    iamActions: string[],
    isActiveNavbar: boolean
}

type Props = {
    isReloadFeed?: boolean | undefined,
    getFeed?: any | undefined,
    getTemplatesExportCount?: any | undefined,
    getTemplatesViewCount?: any | undefined,
    getCloudFeed?: any | undefined,
    logout?: any | undefined,
    admins?: any[] | undefined,
    isLoadingAdmins?: boolean | undefined,
    getAdmins?: any | undefined,
    isLoadingRemoteConfig?: boolean | undefined,
    getRemoteConfig?: any | undefined,
    isConnectedWSS?: boolean | undefined,
    isConnectingWSS?: boolean | undefined
}

class Head extends Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {isActiveNavbar: false, iamActions: getAdminIamActions()}
    }

    isActive = (path: string) => {
        return (match: any, location: any) => {
            return location.pathname === path;
        }
    }

    componentDidMount() {
        if(this.props.isReloadFeed && this.state.iamActions.indexOf('reelsFeed:GetFeed') !== -1){
            registerCookiesFromLocalStorage()
            this.props.getFeed()
            this.props.getTemplatesViewCount()
            this.props.getTemplatesExportCount()
        }
        if(this.state.iamActions.length === 0) {
            this.props.logout()
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
        if(this.props.isReloadFeed && this.state.iamActions.indexOf('reelsFeed:GetFeed') !== -1){
          registerCookiesFromLocalStorage()
          this.props.getFeed()
          this.props.getTemplatesViewCount()
          this.props.getTemplatesExportCount()
        }
    }

    componentWillMount () {
        this.connectToServer(this.props)
        registerCookiesFromLocalStorage()
        if(!this.props.isLoadingAdmins && (!this.props.admins || this.props.admins.length === 0) && this.state.iamActions.indexOf('common:GetAdmins') !== -1){
            this.props.getAdmins();
        }

        if(!this.props.isLoadingRemoteConfig && this.state.iamActions.indexOf('common:GetAdmins') !== -1){
            this.props.getRemoteConfig();
        }
    }

    componentWillUpdate (nextProps: Props, nextState: State, nextContext: any) {
        this.connectToServer(nextProps);
    }

    connectToServer = (props: any) => {
        if (!props.isConnectedWSS && isAuth() && !props.isConnectingWSS && !props.isClosedWSS) {
            console.log('Connect TO server ' + props.isConnectedWSS);

            let serviceTypes = [];
            if(this.state.iamActions.length !== 0) {
                if(this.state.iamActions.indexOf('reelsFeed:AccessToReelsWebsockets') !== -1) {
                    serviceTypes.push(WebsocketServiceType.REELS_MAKER);
                }
            } else {
                serviceTypes = [WebsocketServiceType.REELS_MAKER];
            }
            props.connectWSS(serviceTypes)
        }
    }

    renderItems = (navs: any[]) => {
        const items = [];

        navs.sort(compareString('order')).forEach((nav) => {
            items.push(
                <NavLink
                    isActive={this.isActive(nav.isActive)}
                    to={nav.to}  className="head-item-link"
                    activeClassName={"head-item-link-active"}
                    key={nav.name}
                    style={{paddingTop: isMobile ? '30px' : undefined}}
                    onClick={() => {this.setState({isActiveNavbar: false})}}
                >
                    {nav.icon}
                    <div className={"head-item-text"}>
                        {nav.name}
                    </div>
                </NavLink>
            )})

        items.push(
            <div key={'logout'} className={"head-item-link head-item-logout"} onClick={this.props.logout} style={{
                marginLeft: isMobile ? '20px' : "auto",
                marginRight: isMobile ? '20px' : undefined,
                paddingTop: isMobile ? '56px' : undefined,
            }}>
                <LogoutIcon/>
                <div className={"head-item-text"}>
                    Logout
                </div>
            </div>
        )

        return items;
    }

    renderMobileItems = (navs: any[]) => {
        return <Nav
            initial={false}
            variants={menuVariants}
            animate={this.state.isActiveNavbar ? "opened" : "closed"}
        >
            {this.renderItems(navs)}
        </Nav>
    }

    render () {
        let navs: any[] = [];
        if (this.state.iamActions.length !== 0) {
            this.state.iamActions.forEach(action => {
                if(action.includes('webUI')) {
                    navs = navs.concat(navsTemplates[action] ? navsTemplates[action] : [])
                }
            })
        } else {
            navs = navsTemplates['default'];
        }


        const color = !configs.isLocal ? 'none' : this.props.isConnectedWSS ? 'green' : this.props.isConnectingWSS ? 'yellow' : 'red';

        return (
            <div className={getStyle('head-panel-container')}>
                <div className={getStyle('head-panel')}>
                    <div className="head-item-logo-container">
                        <div className="head-item-logo-text">BEAT - Reels Maker</div>
                        {configs.isDev ? <div className="head-item-logo-text" style={{color: 'red'}}>DEV</div> : null}
                    </div>
                        {
                            isMobile ? undefined : this.renderItems(navs)
                        }
                    <MobileView style={{alignSelf: 'center'}}>
                        <NavbarIcon
                            onClick={() => {
                                this.setState({isActiveNavbar: !this.state.isActiveNavbar})
                            }}
                        />
                    </MobileView>
                </div>
                {this.renderMobileItems(navs)}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    isReloadFeed: state.appState.isReloadFeed,
    isConnectedWSS: state.appState.isConnectedWSS[WebsocketServiceType.REELS_MAKER],
    isConnectingWSS: state.appState.isConnectingWSS[WebsocketServiceType.REELS_MAKER],
    admins: state.appState.admins,
    isLoadingAdmins: state.appState.isLoadingAdmins,
    isClosedWSS: state.appState.isClosedWSS[WebsocketServiceType.REELS_MAKER],
    isLoadingRemoteConfig: state.appState.isLoadingRemoteConfig,
})

const mapDispatchToProps = (dispatch: any) => ({
    logout: bindActionCreators(logout, dispatch),
    connectWSS: bindActionCreators(connectWSS, dispatch),
    disconnectWSS: bindActionCreators(disconnectWSS, dispatch),
    getAdmins: bindActionCreators(getAdmins, dispatch),
    saveAdmin: bindActionCreators(saveAdmin, dispatch),
    getFeed: bindActionCreators(getFeed, dispatch),
    getTemplatesExportCount: bindActionCreators(getTemplatesExportCount, dispatch),
    getTemplatesViewCount: bindActionCreators(getTemplatesViewCount, dispatch),
    getRemoteConfig: bindActionCreators(getRemoteConfig, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Head)

const navsTemplates: any = {
    default: [
        {isActive: '/', to: '/', icon: <LibraryIcon/>, name: 'Library', order: 0},
    ],
    'webUI:AccessReelsFeed': [
        {isActive: '/', to: '/', icon: <LibraryIcon/>, name: 'Library', order: 0},
    ],
    'webUI:AccessReelsTags': [
        {isActive: '/tags', to: '/tags', icon: <TagsIcon/>, name: 'Tags', order: 1},
    ],
    'webUI:AccessReelsMusic': [
        {isActive: '/music', to: '/music', icon: <MusicIcon/>, name: 'Music', order: 2},
    ],
    'webUI:AccessReelsConfig': [
        {isActive: '/settings', to: '/settings', icon: <SettingsIcon/>, name: 'Settings', order: 3},
    ],
}
