import {GeneralActionType} from '../actions/general';
import {getItem, saveItem} from '../utils/LocalStoargeUtill';
import {ModalType} from "../models/Modals";
import {
  WEBSOCKET_CLOSED, WEBSOCKET_CONNECTING,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_ERROR,
  WEBSOCKET_OPEN, WebsocketAction,
  WebsocketServiceType
} from "../models/Websockets";
import {arrayToObject} from "../utils/ArrayUtils";
import {LibraryFilterType, StatusPublishFeed} from "../models/Common";
import {CategoriesActionType} from "../actions/categories";
import {TemplatesActionType} from "../actions/templates";
import {TagsActionTypes} from "../actions/tags";
import {CollectionsActionType} from '../actions/collections';
import {TracksActionType} from '../actions/tracks';

const getInintialState = () => ({
  isLogged: false,
  admin: undefined,
  modals: (initModalsState()),
  error: null,
  feed: null,
  version: {},
  nextVersion: {},
  remoteConfig: null,
  isReloadFeed: true,
  isPublishing: false,
  admins: [],
  isLoadingAdmins: false,
  isLoadingOpenapi: false,
  isLoadingRemoteConfig: false,
  categoryForDelete: null,
  categoryForEdit: null,
  trackCategoryForDelete: null,
  trackCategoryForEdit: null,
  collectionForDelete: null,
  collectionForEdit: null,
  templateForDelete: null,
  templateForEdit: null,
  trackForDelete: null,
  trackForEdit: null,
  tagForDelete: null,
  tagForEdit: null,
  openapi: '',
  isBlockPublish: true,
  isUpdatingContent: false,
  isConnectedWSS: arrayToObject(Object.values(WebsocketServiceType), false),
  isConnectingWSS: arrayToObject(Object.values(WebsocketServiceType), false),
  isClosedWSS: arrayToObject(Object.values(WebsocketServiceType), false),
  selectedFilterType: LibraryFilterType.BY_CATEGORY,
  isCompressingVideo: false,
});

export default function appState(state = getInintialState(), action: any) {
  switch (action.type) {
    case GeneralActionType.SHOW_MODAL:
      return Object.assign({}, state, {
        modals: Object.assign({}, state.modals, {
          [action.modalType]: {isShow: action.isShow, meta: action.meta},
        }),
      });

    case GeneralActionType.SET_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        modals: Object.assign({}, state.modals, {
          [ModalType.ERROR]: {isShow: Boolean(action.error), meta: {}},
        }),
      });

    case GeneralActionType.SAVE_ADMINS: {
      const existAdmin = getItem('admin', undefined);
      if (!existAdmin) {
        return state;
      }

      return Object.assign({}, state, {
        admins: action.admins,
        isLoadingAdmins: false,
      });
    }

    case GeneralActionType.SET_IS_LOADING_ADMINS:
      return Object.assign({}, state, {
        isLoadingAdmins: action.isLoading,
      });

    case GeneralActionType.SAVE_FEED: {
      return Object.assign({}, state, {
        feed: action.feed,
        version: action.feed.version,
        nextVersion: action.feed.nextVersion,
        isBlockPublish: false,
      });
    }

    case GeneralActionType.SELECT_FILTER_TYPE: {
      return Object.assign({}, state, {
        selectedFilterType: action.filterType
      });
    }

    case CategoriesActionType.SET_EDIT_CATEGORY:
      return Object.assign({}, state, {
        categoryForEdit: action.category,
        modals: Object.assign({}, state.modals, {
          [ModalType.CATEGORY_EDIT]: {isShow: Boolean(action.category), meta: {}},
        }),
      });

    case CategoriesActionType.SET_DELETE_CATEGORY:
      return Object.assign({}, state, {
        categoryForDelete: action.category,
        modals: Object.assign({}, state.modals, {
          [ModalType.CATEGORY_DELETE]: {isShow: Boolean(action.category), meta: {}},
        }),
      });

    case CategoriesActionType.SET_EDIT_TRACK_CATEGORY:
      return Object.assign({}, state, {
        trackCategoryForEdit: action.category,
        modals: Object.assign({}, state.modals, {
          [ModalType.TRACK_CATEGORY_EDIT]: {isShow: Boolean(action.category), meta: {}},
        }),
      });

    case CategoriesActionType.SET_DELETE_TRACK_CATEGORY:
      return Object.assign({}, state, {
        trackCategoryForDelete: action.category,
        modals: Object.assign({}, state.modals, {
          [ModalType.TRACK_CATEGORY_DELETE]: {isShow: Boolean(action.category), meta: {}},
        }),
      });

    case CollectionsActionType.SET_EDIT_COLLECTION:
      return Object.assign({}, state, {
        collectionForEdit: action.collection,
        modals: Object.assign({}, state.modals, {
          [ModalType.COLLECTION_EDIT]: {isShow: Boolean(action.collection), meta: {}},
        }),
      });

    case CollectionsActionType.SET_DELETE_COLLECTION:
      return Object.assign({}, state, {
        collectionForDelete: action.collection,
        modals: Object.assign({}, state.modals, {
          [ModalType.COLLECTION_DELETE]: {isShow: Boolean(action.collection), meta: {}},
        }),
      });

    case TemplatesActionType.SET_EDIT_TEMPLATE:
      return Object.assign({}, state, {
        templateForEdit: action.template,
        modals: Object.assign({}, state.modals, {
          [ModalType.TEMPLATE_EDIT]: {isShow: Boolean(action.template), meta: {}},
        }),
      });

    case TemplatesActionType.SET_DELETE_TEMPLATE:
      return Object.assign({}, state, {
        templateForDelete: action.template,
        modals: Object.assign({}, state.modals, {
          [ModalType.TEMPLATE_DELETE]: {isShow: Boolean(action.template), meta: {}},
        }),
      });

    case TracksActionType.SET_EDIT_TRACK:
      return Object.assign({}, state, {
        trackForEdit: action.track,
        modals: Object.assign({}, state.modals, {
          [ModalType.TRACK_EDIT]: {isShow: Boolean(action.track), meta: {}},
        }),
      });

    case TracksActionType.SET_DELETE_TRACK:
      return Object.assign({}, state, {
        trackForDelete: action.track,
        modals: Object.assign({}, state.modals, {
          [ModalType.TRACK_DELETE]: {isShow: Boolean(action.track), meta: {}},
        }),
      });

    case TagsActionTypes.SET_EDIT_TAG:
      return Object.assign({}, state, {
        tagForEdit: action.tag,
        modals: Object.assign({}, state.modals, {
          [ModalType.TAG_EDIT]: {isShow: Boolean(action.tag), meta: {}},
        }),
      });

    case TagsActionTypes.SET_DELETE_TAG:
      return Object.assign({}, state, {
        tagForDelete: action.tag,
        modals: Object.assign({}, state.modals, {
          [ModalType.TAG_DELETE]: {isShow: Boolean(action.tag), meta: {}},
        }),
      });

    case GeneralActionType.SET_IS_LOADING_REMOTE_CONFIG:
      return Object.assign({}, state, {
        isLoadingRemoteConfig: action.isLoading,
      });

    case GeneralActionType.SAVE_REMOTE_CONFIG:
      return Object.assign({}, state, {
        remoteConfig: action.remoteConfig,
        isLoadingRemoteConfig: false,
      });

    case GeneralActionType.SET_IS_BLOCK_PUBLISH:
      return Object.assign({}, state, {
        isBlockPublish: action.isBlockPublish,
      });

    case WebsocketAction.FINISH_COMPRESS_VIDEO.name:
      return Object.assign({}, state, {
        isCompressingVideo: false
      });


    case WEBSOCKET_DISCONNECT:
      return Object.assign({}, state, {
        isClosedWSS: arrayToObject(Object.values(WebsocketServiceType), action.isLogout),
      });

    case WEBSOCKET_OPEN:
      return Object.assign({}, state, {
        isConnectedWSS: {...state.isConnectedWSS, ...{[action.serviceType]: true}},
        isConnectingWSS: {...state.isConnectingWSS, ...{[action.serviceType]: false}},
      });

    case WEBSOCKET_CLOSED:
      return Object.assign({}, state, {
        isConnectedWSS: {...state.isConnectedWSS, ...{[action.serviceType]: false}},
        isConnectingWSS: {...state.isConnectingWSS, ...{[action.serviceType]: false}},
      });

    case WEBSOCKET_CONNECTING:
      return Object.assign({}, state, {
        isConnectingWSS: {...state.isConnectingWSS, ...arrayToObject(action.serviceTypes, true)},
      });

    case WEBSOCKET_ERROR:
      return Object.assign({}, state, {
        modals: (initModalsState()),
        isConnectedWSS: arrayToObject(Object.values(WebsocketServiceType), false),
        isConnectingWSS: arrayToObject(Object.values(WebsocketServiceType), false),
      });

    case WebsocketAction.STATUS_REELS_FEED_PUBLISH.name:
      return Object.assign({}, state, {
        isPublishing: action.data.message.status === StatusPublishFeed.PUBLISHING,
        isBlockPublish: action.data.message.status === StatusPublishFeed.PUBLISHING,
      });

    case WebsocketAction.REELS_FEED_PUBLISH.name:
      return Object.assign({}, state, {
        version: action.data.message.version,
        nextVersion: action.data.message.nextVersion,
        isPublishing: false,
        isBlockPublish: true
      });

    case GeneralActionType.SET_IS_COMPRESSING_VIDEO:
      return Object.assign({}, state, {
        isCompressingVideo: action.isCompressing,
      });

    default:
      return state;
  }
}

const initModalsState = () => {
  const modalState: any = {};
  Object.keys(ModalType).forEach((keyModal) => {
    modalState[keyModal] = {isShow: false, meta: {}};
  });

  return modalState;
};
