import React from 'react';
import TagsSection from "./TagsSection";

export const Tags = () => {
  return (
    <div style={{height: '100%', overflow: 'overlay', marginBottom: '100px'}}>
      <div className={'settings-container'}>
        <TagsSection/>
      </div>
    </div>
  );
};
