import {TrackToTrackCategory} from './TrackToTrackCategory';

export enum ReelsTrackSourceType {
    THE_SOUL = 'THE_SOUL',
    ONELIGHT = 'ONELIGHT'
}

export const convertReelsTrackSourceTypeToReadValue = (sourceTypeItem: ReelsTrackSourceType) => {
    switch (sourceTypeItem) {
        case ReelsTrackSourceType.THE_SOUL: return 'TheSoul';
        case ReelsTrackSourceType.ONELIGHT: return 'Onelight';
    }
}

export interface Track {
    id: string;
    artistName: string;
    trackName: string;
    coverImageUrl: string;
    mp3Url: string;
    isDeleted: boolean;
    isFree: boolean;
    isNew: boolean;
    isPopular: boolean;
    isActive: boolean;
    sourceType: ReelsTrackSourceType;
    trackToCategories: TrackToTrackCategory[];
    version: number;
    duration: number;
    createdAt: Date;
    updatedAt: Date;
    relationId?: string;
    orderIndex?: number;
}
