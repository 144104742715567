import React, {useCallback, useEffect, useState} from 'react';
import MDSpinner from 'react-md-spinner';
import {Category} from '../../models/Category';
import {spinnerColor} from '../../utils/StyleUtils';
import {LibraryFilterType} from '../../models/Common';
import {ModalType} from '../../models/Modals';
import {IsFullViewCategory} from '../../reducers/categories';

import {closestCenter, DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import SortableCategoryView from './SortableCategoryView';

type CategoriesList = {
    categories: Category[],
    isLoadingCategories: boolean,
    templatesByCategoriesIds: any,
    updateCategoriesOrderIndexes: any,
    selectedFilterType: LibraryFilterType,
    showModal: (modalType: ModalType, isShow: boolean, meta: any) => void,
    isFullViewCategories: IsFullViewCategory,
    setIsFullViewCategory: (categoryId: string, isFullView: boolean) => void
}

export const CategoriesList = ({categories, isLoadingCategories, selectedFilterType, showModal, isFullViewCategories,
    setIsFullViewCategory, templatesByCategoriesIds, updateCategoriesOrderIndexes}: CategoriesList) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const [localCategories, setCategories] = useState(categories);

    useEffect(() => {
        setCategories(categories);
    }, [categories]);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            setCategories((localCategories) => {
                const oldCategory = findCategory(active.id, localCategories);
                const newCategory = findCategory(over!.id, localCategories);
                const newLocalCategories = arrayMove(localCategories, oldCategory.index, newCategory.index);

                updateCategoriesOrderIndexes(
                  newLocalCategories.map((category, index) => {
                      return {
                          entityId: category.id,
                          orderIndex: category.isFeatured ? 0 : index + 1,
                      };
                  }),
                );

                return arrayMove(localCategories, oldCategory.index, newCategory.index);
            });
        }
    }, []);

    if (isLoadingCategories) {
        return (
            <div className='spinner-container' style={{marginTop: '212px'}}>
                <MDSpinner size={100} singleColor={spinnerColor}/>
            </div>
        );
    }

    const findCategory = (id: string | number, categories: Category[] ) => {
        const category = categories.filter((c) => {
            return c.id === id;
        })[0];
        return {
            category,
            index: categories.indexOf(category),
        };
    };

    return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
            <SortableContext items={localCategories} strategy={verticalListSortingStrategy}>
                    {localCategories.map((category, index) => (
                      <SortableCategoryView
                        key={category.id}
                        style={{marginTop: (index > 0 ? '15px' : undefined)}}
                        category={category}
                        arrayIndex={index}
                        showModal={showModal}
                        templates={templatesByCategoriesIds[category.id] ? templatesByCategoriesIds[category.id] : []}
                        selectedFilterType={selectedFilterType}
                        setIsFullViewCategory={setIsFullViewCategory}
                        isFullView={isFullViewCategories[category.id]}
                      />
                    ))}
            </SortableContext>
        </DndContext>
    );
};
