import {isMobile} from 'react-device-detect';

export const getStyle = (styleName: string) => {
  if (isMobile) {
    return styleName + '-mob';
  }

  return styleName;
};

export const spinnerColor = '#81E727';
